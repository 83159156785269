import { useEffect, useState } from "react"
import { EditUserDto, RecommendationsClient, RecommendationsDto } from "../../utilities/backend/client"
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const RecommendationsAdmin = () => {
    const recommendationsClient = new RecommendationsClient()
    const [recommendations, setRecommendations] = useState<RecommendationsDto[]>()
    const [selectedRecommendation, setSelectedRecommendation] = useState<RecommendationsDto | null>(null)

    const [notificationShow, setNotificationShow] = useState<boolean>(false)
    const [notificationText, setNotificationText] = useState<string>()
    const [dialogText, setDialogText] = useState<string>()
    const [isReject, setIsReject] = useState<boolean>()
    const [loading, setLoading] = useState<boolean>(true)

    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const handleClickOpenDialog = (recommendation: RecommendationsDto, text: string, isReject: boolean) => {
        setSelectedRecommendation(recommendation)
        setDialogText(text)
        setIsReject(isReject)
        setOpen(true)
        
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sortRecommendations = (recommendationsToSort: RecommendationsDto[]) => {
        const sorted = recommendationsToSort.slice().sort((a, b) => {
          const statusOrder: { [key: string]: number } = {
            pending: 1,
            approved: 2,
            reject: 3,
          };
    
          const statusA = statusOrder[a.status || ''];
          const statusB = statusOrder[b.status || ''];
    
          if (statusA !== statusB) {
            return statusA - statusB;
          }

          return (b.id ?? 0) - (a.id ?? 0);
        });
    
        setRecommendations(sorted);
      };

    useEffect(() =>{
        setLoading(true)
        recommendationsClient.recommendations_GetAllRecommendations()
        .then((response) => {
            sortRecommendations(response)
            setLoading(false)
        })
        .catch((error) => {
            console.log(error);
            setLoading(false)
        });
    }, [notificationText])

    const handleNotificationClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setNotificationShow(false);
      };

    const onAcceptClick = (recommendation: RecommendationsDto) => {
        recommendation.status = "approved"
        recommendationsClient.recommendations_UpdateRecommendation(recommendation.id!, recommendation)
        .then(async () => {
            setNotificationText("Recommendation approved")
            setNotificationShow(true);
        })
        .catch((error) => {
            console.log(error);
        });
        handleClose()
    }

    const onDeclineClick = (recommendation: RecommendationsDto) => {
        recommendation.status = "reject"
        recommendationsClient.recommendations_UpdateRecommendation(recommendation.id!, recommendation)
        .then(async () => {
            setNotificationText("Recommendation rejected")
            setNotificationShow(true);
        })
        .catch((error) => {
            console.log(error);
        });
        handleClose()
    }


    return(
        <>
        {loading ? 
            <Box sx={{margin: "0 auto"}}>
                <CircularProgress />
            </Box>
        :<>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>User Email</TableCell>
                            <TableCell>User Full Name</TableCell>
                            <TableCell>Investment Name</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Date Created</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {recommendations ? recommendations.map((recommendation) => (
                        <TableRow key={recommendation.id}>
                            <TableCell>{recommendation.id}</TableCell>
                            <TableCell>{recommendation.userEmail}</TableCell>
                            <TableCell>{recommendation.userFullName}</TableCell>
                            <TableCell>{recommendation.campaignName}</TableCell>
                            <TableCell>{recommendation.amount}</TableCell>
                            <TableCell>{recommendation.dateCreated ? new Date(recommendation.dateCreated).toLocaleDateString("en-US") : ''}</TableCell>
                            <TableCell>{recommendation.status}</TableCell>
                            <TableCell>
                                { recommendation.status === "pending" ?
                                    <><IconButton color="success" onClick={() => handleClickOpenDialog(recommendation, "Do you want to accept recommendation?", false)}>
                                        <CheckIcon />
                                    </IconButton><IconButton color="error" onClick={() => handleClickOpenDialog(recommendation, "Do you want to reject recommendation?", true)}>
                                            <CloseIcon />
                                        </IconButton></>
                                    : 
                                    <></>
                                }
                            </TableCell>
                        </TableRow>
                    )) : <TableRow></TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {dialogText}
                </DialogTitle>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        No
                    </Button>
                    <Button onClick={() => {isReject ? onDeclineClick(selectedRecommendation!) : onAcceptClick(selectedRecommendation!)}} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            </>}

            <Snackbar open={notificationShow} autoHideDuration={6000} onClose={handleNotificationClose}>
                <Alert onClose={handleNotificationClose} severity="success" sx={{ width: '100%' }}>
                    {notificationText}
                </Alert>
            </Snackbar>
        </>
    )
}

export default RecommendationsAdmin