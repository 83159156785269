import { Box, Button, Grid, InputAdornment, Paper, Stack, TextField, Typography } from "@mui/material";
import ContentTitle from "../../../components/ContentTitle";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import Avatar from "../../../resources/avatar";

const Support = () => {
    const steps = [1,2,3,4,5,6,7,8]

    return (
        <Box sx={{padding: "4%", display: "flex", flexDirection: "column", justifyContent: "center", margin: "auto"}}>
            <Stack justifyContent="center" alignItems="center" sx={{ margin: "auto"}} width={800} spacing={4}>
                <ContentTitle title="Hi! How can we help you?" description="Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis." />
                <TextField sx={{width: "60%"}} variant="outlined" placeholder="Search the FAQ’s" InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }} />
                <Stack justifyContent="center" alignItems="center" direction="row" sx={{justifyContent: "center", margin: "auto"}} spacing={2}>
                    <Stack justifyContent="center" alignItems="center" spacing={2}>
                        <Avatar />
                        <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 700, color: "#060A25" }}>General</Typography>
                    </Stack>
                    <Stack justifyContent="center" alignItems="center" spacing={2}>
                        <Avatar />
                        <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 700, color: "#060A25" }}>Support</Typography>
                    </Stack>
                    <Stack justifyContent="center" alignItems="center" spacing={2}>
                        <Avatar />
                        <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 700, color: "#060A25" }}>Investment</Typography>
                    </Stack>
                    <Stack justifyContent="center" alignItems="center" spacing={2} >
                        <Avatar />
                        <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 700, color: "#060A25" }}>Payment</Typography>
                    </Stack>
                    <Stack justifyContent="center" alignItems="center" spacing={2}>
                        <Avatar />
                        <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 700, color: "#060A25" }}>Other</Typography>
                    </Stack>
                </Stack>
                <Stack justifyContent="center" alignItems="center" sx={{justifyContent: "center", margin: "auto", width: "100%"}} spacing={1}>
                    {steps.map((object, i) =>
                        <Paper variant="outlined" elevation={0} sx={{borderRadius: "8px", width: "90%", height: "50px"}}>
                             <Grid container sx={{margin: "1%"}}>
                                <Grid item xs={11} sx={{justifyContent: "left", margin: "auto"}}>
                                    <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 20, fontWeight: 700, color: "#474747" }}>Question text goes here</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{width: "100%",justifyContent: "right", margin: "auto"}}>
                                    <AddIcon/>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </Stack>
                <Box sx={{padding: "10px", paddingBottom: "40px", display: "flex", flexDirection: "column", justifyContent: "center", margin: "auto", width: "100%", backgroundColor: "#D9D9D9", borderRadius: "8px", border: "0px solid #D9D9D9"}}>
                    <Stack justifyContent="center" alignItems="center" spacing={2}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', maxWidth: '1011px', margin: '0 auto'}}>
                            <Typography  sx={{ fontFamily: 'Ubuntu', fontSize: 32, fontWeight: 700, color: "#060A25", lineHeigh: '38.4px' }}>Have more question?</Typography>
                            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 400, color: "#060A25", lineHeigh: '20.68px', mt: '15px' }}>Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</Typography>
                        </div>
                        <Button variant="contained" sx={{backgroundColor: "#000000", width:"40%"}}>Contact Us</Button>
                    </Stack>
                </Box>
            </Stack>
        </Box>
        
    );
};

export default Support;