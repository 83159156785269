import { Button } from "@mui/material";

interface PrimaryButtonProps {
    text: string;
    click: () => void
}

const PrimaryButton = (props: PrimaryButtonProps) => {
    return (
        <Button 
            onClick={props.click}
            sx={{
            backgroundColor: '#2575FC', 
            width: '356px',
            height: '51px',
            fontSize: '16px',
            fontWeight: '500',
            fontFamily: 'Ubuntu',
            color: 'white',
            padding: '16px 24px',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: 'white',
                color: '#2575FC',
                border: '2px solid #2575FC'
             },
        }}>{props.text}</Button>
    )
}
export default PrimaryButton;