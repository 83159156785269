import { useEffect, useState } from "react";
import {
  AuthClient,
  UserRegistrationDto,
} from "../../utilities/backend/client";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { DevTool } from "@hookform/devtools";
import ContentTitle from "../../components/ContentTitle";
import CloseIcon from "@mui/icons-material/Close";
import PrivacyPolicy from "../app/PrivacyPolicy";
import TermsAndConditions from "../app/TermsAndConditions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Register = () => {
  const authClient = new AuthClient();
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationErrorShow, setNotificationErrorShow] =
    useState<boolean>(false);
  const [notificationErrorText, setNotificationErrorText] = useState<string>();
  const [isRedirected, setIsRedirected] = useState<boolean>(false);

  useEffect(() => {}, [isRedirected]);

  const form = useForm<UserRegistrationDto>({
    defaultValues: {
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
      email: "",
      phoneNumber: "",
    },
    mode: "onBlur",
  });

  const { register, handleSubmit, formState, control, setValue, setError } =
    form;
  const { errors } = formState;
  const navigateToLogin = () => {
    navigate("/login");
  };

  const handlePhoneNumberChange = (event: any) => {
    let phoneNumber = event.target.value.replace(/\D/g, "").slice(0, 10);

    setValue("phoneNumber", phoneNumber);

    const validationError = validatePhoneNumber(phoneNumber);
    if (validationError !== true) {
      setError("phoneNumber", {
        type: "manual",
        message: validationError,
      });
    } else {
      form.clearErrors("phoneNumber");
    }
  };

  const handleTermsOpen = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setTermsModalOpen(true);
    return false;
  };

  const handlePrivacyOpen = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setPrivacyModalOpen(true);
    return false;
  };

  const handleModalClose = () => {
    setTermsModalOpen(false);
    setPrivacyModalOpen(false);
  };

  const validateEmail = (value: string | null | undefined) => {
    if (!value) return "Email is required";
    if (value === "ken@arizae.com")
      return "Invalid email. Please, use different email";

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      return "Invalid email format. Template: someone@mail.com";
    }

    return true;
  };

  const validatePhoneNumber = (value: string | null | undefined) => {
    if (!value) return "Phone number is required";
    if (
      typeof value !== "string" ||
      !value.match(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
    )
      return "Invalid phone number.";
    return true;
  };

  const validateUserName = (value: string | null | undefined) => {
    if (!value) return "User name is required";

    if (/[^a-zA-Z0-9_]/.test(value)) {
      return "User name should contain only letters, numbers, or underscores.";
    }
    return true;
  };

  const onSubmit = (data: UserRegistrationDto) => {
    setLoading(true);
    authClient
      .auth_RegisterUser(data)
      .then(async () => {
        setIsRedirected(true);
        localStorage.setItem("email", data.email!);
        localStorage.setItem("password", data.password!);
        setLoading(false);
      })
      .catch((error) => {
        const errorka = JSON.parse(error.response);
        setNotificationErrorShow(true);
        setNotificationErrorText(errorka.errors[0].description);
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "auto",
        paddingBottom: "30px",
        padding: "0 18px",
        paddingTop: "70px",
      }}
    >
      {loading ? (
        <Box sx={{ margin: "0 auto" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!isRedirected ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Stack spacing={2} width={400}>
                <ContentTitle
                  title="Sign Up"
                  description="Please complete the following information to create your account"
                />
                {/* <Button variant='outlined' sx={{color: "#060A25", borderColor: "#656565", width: "100%", padding: "auto"}} startIcon={<GoogleIcon></GoogleIcon>}> Sign Up with Google </Button> */}
                <Divider variant="middle" flexItem>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#060A25",
                    }}
                  >
                    Create account with email
                  </Typography>
                </Divider>
                {/* <Typography sx={{width: "100%", fontFamily: 'Ubuntu', fontSize: 16, fontWeight: 400, color: "#474747"}} align='left'>User Name</Typography> */}
                <TextField
                  label="First Name"
                  type="text"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                ></TextField>
                <TextField
                  label="Last Name"
                  type="text"
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                ></TextField>
                <TextField
                  label="User Name"
                  type="text"
                  error={!!errors.userName}
                  helperText={errors.userName?.message}
                  {...register("userName", { validate: validateUserName })}
                ></TextField>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  Email Address
                </Typography>
                <TextField
                  sx={{ width: "100%" }}
                  label="Email"
                  type="text"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  {...register("email", { validate: validateEmail })}
                ></TextField>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  Phone Number
                </Typography>
                <TextField
                  sx={{ width: "100%" }}
                  label="Phone Number"
                  type="text"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                  {...register("phoneNumber", {
                    validate: validatePhoneNumber,
                  })}
                  onChange={handlePhoneNumberChange}
                />
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  Password
                </Typography>
                <TextField
                  sx={{ width: "100%" }}
                  label="Password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  {...register("password", {
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{14,}$/,
                      message:
                        "Password must contain one digit, one lowercase letter, one uppercase letter, one special character, no space, and it must be 14+ characters long.",
                    },
                  })}
                ></TextField>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      onClick={() => {
                        setTermsAccepted(!termsAccepted);
                      }}
                    />
                  }
                  label={
                    <>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 16,
                          fontWeight: 400,
                          color: "#000000",
                        }}
                      >
                        I accept the&nbsp;
                        <a onClick={handleTermsOpen} href="#">
                          Terms and Conditions
                        </a>
                        &nbsp;and&nbsp;
                        <a onClick={handlePrivacyOpen} href="#">
                          privacy policy
                        </a>
                      </Typography>
                    </>
                  }
                />
                <Button
                  disabled={!termsAccepted && Object.keys(errors).length > 0}
                  variant="contained"
                  type="submit"
                  color="primary"
                  sx={{ marginBottom: "1rem !important" }}
                >
                  Register
                </Button>
                {/* <Button variant="contained" onClick={navigateToLogin}>Go To Login</Button> */}
              </Stack>
            </form>
          ) : (
            <Stack alignItems={"center"} justifyContent={"center"}>
              <ContentTitle
                title={"Getting Started"}
                description={
                  "An initial $10+ tax-deductible donation is required for compliance reasons to access CataCap.  These funds will be added to your CataCap Account to kickstart your investing."
                }
              />
              <div style={{ marginTop: "30px" }}>
                <script
                  src="https://donorbox.org/widget.js"
                  paypal-Express="true"
                ></script>
                <iframe
                  src="https://donorbox.org/embed/catacap-signup?default_interval=o"
                  name="donorbox"
                  allow-payment-request="allowpaymentrequest"
                  data-seamless="seamless"
                  frame-border="0"
                  scrolling="no"
                  height="900px"
                  width="440px"
                  data-style="max-width: 500px; min-width: 250px; max-height:none!important"
                  allow="payment"
                ></iframe>
              </div>
            </Stack>
          )}

          <Snackbar
            open={notificationErrorShow}
            autoHideDuration={6000}
            onClose={() => setNotificationErrorShow(false)}
          >
            <Alert
              onClose={() => setNotificationErrorShow(false)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {notificationErrorText}
            </Alert>
          </Snackbar>
          <BootstrapDialog
            open={termsModalOpen}
            onClose={handleModalClose}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Terms and Conditions
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleModalClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <TermsAndConditions />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleModalClose}>
                Ok
              </Button>
            </DialogActions>
          </BootstrapDialog>

          <BootstrapDialog
            open={privacyModalOpen}
            onClose={handleModalClose}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Privacy Policy
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleModalClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <PrivacyPolicy />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleModalClose}>
                Ok
              </Button>
            </DialogActions>
          </BootstrapDialog>

          <DevTool control={control} />
        </>
      )}
    </Box>
  );
};

export default Register;
