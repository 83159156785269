import { useState, useEffect } from 'react';
import {RootState} from "../../redux/store";
import {useSelector} from "react-redux";

const useRole = () => {
    const [role, setRole] = useState<string | null>('');
    const selectorRole = useSelector((state: RootState) => state.role);
    useEffect(() => {
        setRole(selectorRole.role);
    }, [role]);

    return role;
};

export default useRole;