import fetchIntercept from 'fetch-intercept';
import {store} from '../redux/store';

export const addAuthInterceptor = () => {
    fetchIntercept.register({
        request: function (url, config) {
            const token = store.getState().token;
            if(token != null){
                config.headers['Authorization'] = 'Bearer ' + token.token;
            }
            else {
                config.headers['Authorization'] = '';
            }
            return [url, config];
        },
    });
};