import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  Stack,
  Switch,
  SwitchProps,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ApiException,
  CreateGroupDto,
  GroupClient,
  GroupDto,
} from "../../../utilities/backend/client";
import { useSelector } from "react-redux";
import { RootState } from "../../../utilities/redux/store";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useHandleApiError from "../../../utilities/backend/Auth/useHandleApiError";
import { SVG } from "../../../icons";
import {
  defaultImage,
  getUrlBlobContainerImage,
} from "../../../resources/imageConstants";

const CopyButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: 2,
}));

const CopyLinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  margin: "0 auto 31px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "0 auto 48px",
  },
}));

const Stack1 = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Stack2 = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ButtonDelete = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "10px 15px",
  },
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const GroupAddEdit = () => {
  const [displayFileSizeError, setDisplayFileSizeError] = useState<boolean>();
  const { state } = useLocation();
  const group = state?.group;
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const token: string =
    useSelector((state: RootState) => state.token.token) || "";
  const groupClient = new GroupClient();
  const [isApprouveRequired, setIsApprouveRequired] = useState<boolean>(
    group?.isApprouveRequired || false
  );
  const [isDeactivated, setIsDeactivated] = useState<boolean>(
    group?.isDeactivated || false
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [notificationCopy, setNotificationCopy] = useState<boolean>(false);

  const [image, setImage] = useState<any>();

  const [apiError, setApiError] = useState<ApiException | null>(null);
  useHandleApiError(apiError);

  const [open, setOpen] = useState(false);

  const handleImageSelection = () => {
    fileInputRef.current?.click();
  };

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  const handleNotificationCopyClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationCopy(false);
  };

  const handleCopyButtonClick = async () => {
    const groupToUrl = `https://app.catacap.org/group/${
      group?.identifier || group?.id
    }`;

    try {
      await navigator.clipboard.writeText(groupToUrl);
      setNotificationCopy(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const uploadImageChange = async (e: any) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size >= 204659) setDisplayFileSizeError(true);
      else {
        setDisplayFileSizeError(false);
        setImage(await toBase64(e.target.files[0]));
      }
    }
  };

  const linkCopyGroup = () => {
    return `https://app.catacap.org/group/${group?.identifier || group?.id}`;
  };

  const linkGroup = () => {
    if (group) {
      return group?.identifier
        ? `/group/${group.identifier}`
        : `/group/${group.id}`;
    }

    return "/community";
  };

  const form = useForm<any>({
    defaultValues: {
      name: group?.name || "",
      website: group?.website || "",
      description: group?.description || "",
      identifier: group?.identifier || "",
    },
  });

  const { register, handleSubmit, setError, formState } = form;
  const { errors } = formState;

  const onSubmit = async (data: any) => {
    if (!displayFileSizeError) {
      try {
        setLoading(true);
        let imgBase64 = image;

        if (group) {
          let requestUpdateGroup: GroupDto = {
            id: group?.id || null,
            name: data.name,
            website: data.website,
            description: data.description,
            identifier: data.identifier,
            isApprouveRequired: isApprouveRequired,
            isDeactivated: isDeactivated,
            token: token,
            isFollowPending: state.group.isFollowPending,
            isFollowing: state.group.isFollowing,
            isOwner: state.group.isOwner,
            privateCampaigns: state.group.privateCampaigns,
            campaigns: state.group.campaigns,
            groupAccountBalance: state.group?.groupAccountBalance,
          };

          requestUpdateGroup.pictureFileName =
            typeof imgBase64 === "string" ? imgBase64 : "";

          groupClient
            .group_UpdateGroup(group.id, requestUpdateGroup)
            .then(() => {
              setLoading(false);
              navigate("/community");
            })
            .catch((error) => {
              setLoading(false);
              setApiError(error);
              if (error.response === "Identifier already exists.") {
                setError("identifier", {
                  type: "manual",
                  message: error.response,
                });
              }
            });
        } else {
          let requestCreateGroup: CreateGroupDto = {
            name: data.name,
            website: data.website,
            description: data.description,
            identifier: data.identifier,
            isApprouveRequired: isApprouveRequired,
            isDeactivated: isDeactivated,
            token: token,
          };

          requestCreateGroup.pictureFileName =
            typeof imgBase64 === "string" ? imgBase64 : "";

          groupClient
            .group_CreateGroup(requestCreateGroup)
            .then(() => {
              setLoading(false);
              navigate("/community");
            })
            .catch((error) => {
              setLoading(false);
              setApiError(error);
              if (error.response === "Identifier already exists.") {
                setError("identifier", {
                  type: "manual",
                  message: error.response,
                });
              }
            });
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    } else {
      setNotificationShow(true);
    }
  };

  const onDeclineClick = (group: GroupDto) => {
    groupClient
      .group_DeleteGroup(group.id!)
      .then(() => {
        setLoading(false);
        navigate("/community");
      })
      .catch((error) => {
        setLoading(false);
        setApiError(error);
      });
    handleClose();
  };

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack1
      sx={{ justifyContent: "center", margin: "52px auto 72px" }}
      width={800}
      spacing={4}
    >
      {loading ? (
        <Box sx={{ margin: "0 auto" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            padding={"0 18px"}
          >
            <Link
              to={linkGroup()}
              style={{ textDecoration: "none", alignContent: "flex-start" }}
            >
              <Stack direction="row">
                <ArrowBackIcon sx={{ color: "000000" }}></ArrowBackIcon>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                >
                  Back
                </Typography>
              </Stack>
            </Link>
          </Stack>
          <Stack
            spacing={0}
            sx={{ marginTop: "52px !important" }}
            padding={"0 18px"}
          >
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 32,
                fontWeight: 700,
                color: "#060A25",
              }}
            >
              My Investment Group
            </Typography>
            <Stack
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
              }}
            >
              <Divider
                sx={{
                  backgroundColor: "#45B08A",
                  height: "2px",
                  width: "135px",
                }}
              ></Divider>
              <Divider
                sx={{
                  backgroundColor: "#E8E8E8",
                  height: "2px",
                  width: "100%",
                }}
              ></Divider>
            </Stack>
          </Stack>
          <Stack
            spacing={4}
            sx={{ marginTop: "48px !important" }}
            padding={"0 18px"}
          >
            <Stack2
              justifyContent="space-between"
              spacing={7}
              direction={"row"}
            >
              <Stack
                spacing={2}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                width={"100%"}
              >
                <Avatar
                  src={
                    image ||
                    (group?.pictureFileName
                      ? getUrlBlobContainerImage(group?.pictureFileName)
                      : defaultImage)
                  }
                  sx={{ width: 135, height: 135 }}
                  variant="rounded"
                />
                <FormControl>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleImageSelection}
                    sx={{ padding: "16px 24px", borderColor: "#2575FC" }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: "120%",
                        textTransform: "capitalize",
                        color: "#2575FC",
                      }}
                    >
                      Change Picture
                    </Typography>
                    <input
                      hidden
                      onChange={uploadImageChange}
                      accept="image/*"
                      type="file"
                      ref={fileInputRef}
                    />
                  </Button>
                </FormControl>
              </Stack>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {"Do you want to delete group?"}
                </DialogTitle>
                <DialogActions>
                  <Button autoFocus onClick={handleClose}>
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      onDeclineClick(group);
                    }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack2>
            {displayFileSizeError && (
              <Typography sx={{ color: "red" }}>
                Please, try different image. The size of the image should be
                less than 200KB
              </Typography>
            )}
            <Grid
              container
              spacing={2}
              alignItems={"center"}
              justifyContent={"flex-start"}
              width={"100%"}
              gap={2.5}
            >
              <Grid
                item
                width={"100%"}
                sx={{ padding: "0 !important", flex: "47% 1" }}
              >
                <Stack
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  width={"100%"}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 18,
                        fontWeight: 400,
                        color: "#656565",
                        lineHeigh: "20.68px",
                        marginBottom: "8px",
                      }}
                    >
                      Group Name
                    </Typography>
                    <TextField
                      fullWidth
                      type="text"
                      placeholder="Clean Oceans Impact Investors Fund "
                      error={!!errors.name}
                      helperText={errors.name?.message?.toString()}
                      {...register("name")}
                    ></TextField>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                width={"100%"}
                sx={{ padding: "0 !important", flex: "47% 1" }}
              >
                <Stack
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  width={"100%"}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 18,
                        fontWeight: 400,
                        color: "#656565",
                        lineHeigh: "20.68px",
                        marginBottom: "8px",
                      }}
                    >
                      Group Website
                    </Typography>
                    <TextField
                      fullWidth
                      type="text"
                      placeholder="NA"
                      error={!!errors.name}
                      helperText={errors.name?.message?.toString()}
                      {...register("website")}
                    ></TextField>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                width={"100%"}
                sx={{ padding: "0 !important" }}
              >
                <Stack
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  width={"100%"}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 18,
                        fontWeight: 400,
                        color: "#656565",
                        lineHeigh: "20.68px",
                        marginBottom: "8px",
                      }}
                    >
                      Group description
                    </Typography>
                    <TextField
                      fullWidth
                      type="text"
                      multiline
                      rows={5}
                      maxRows={5}
                      placeholder="Type here"
                      error={!!errors.name}
                      helperText={errors.name?.message?.toString()}
                      {...register("description")}
                    ></TextField>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                width={"100%"}
                sx={{ padding: "0 !important" }}
              >
                <Stack
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  width={"100%"}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 18,
                        fontWeight: 400,
                        color: "#656565",
                        lineHeigh: "20.68px",
                        marginBottom: "8px",
                      }}
                    >
                      Group Identifier
                    </Typography>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          {...register("identifier", {
                            validate: (value) =>
                              /^[a-zA-Z0-9]+$/.test(value)
                                ? true
                                : "Group Name for URL should be a string of letters and numbers only, without spaces or special characters",
                            required: "Group Name for URL is required",
                          })}
                          error={!!errors.identifier}
                          helperText={errors.identifier?.message?.toString()}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Stack>
              </Grid>
              {group?.Identifier ||
                (group?.id && (
                  <Grid
                    item
                    xs={12}
                    width={"100%"}
                    sx={{ padding: "0 !important" }}
                  >
                    <Stack
                      spacing={1}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      width={"100%"}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: "Ubuntu",
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#656565",
                            lineHeigh: "20.68px",
                            marginBottom: "8px",
                          }}
                        >
                          Your Group Share URL is:
                        </Typography>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs={10}>
                            <Typography
                              sx={{
                                fontFamily: "Ubuntu",
                                fontSize: 16,
                                fontWeight: 400,
                                lineHeigh: "20.68px",
                                marginBottom: "8px",
                              }}
                            >
                              {linkCopyGroup()}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <CopyLinkContainer>
                              <Tooltip
                                placement="top"
                                title="Clicking this button will copy the group identifier share link"
                              >
                                <CopyButton onClick={handleCopyButtonClick}>
                                  <SVG.Copy />
                                </CopyButton>
                              </Tooltip>
                            </CopyLinkContainer>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Stack>
                  </Grid>
                ))}
              <Grid
                item
                xs={12}
                width={"100%"}
                sx={{ padding: "0 !important" }}
              >
                <Stack
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  width={"100%"}
                >
                  <FormControl sx={{ width: "100%" }}></FormControl>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                width={"100%"}
                sx={{ padding: "0 !important" }}
              >
                <Stack
                  spacing={2}
                  flexDirection="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 18,
                      fontWeight: 400,
                      color: "#656565",
                      lineHeigh: "20.68px",
                      marginBottom: "8px",
                    }}
                  >
                    Deactivate Group
                  </Typography>
                  <FormControlLabel
                    sx={{ margin: "0 !important" }}
                    control={
                      <IOSSwitch
                        checked={isDeactivated}
                        onChange={(e) => setIsDeactivated(e.target.checked)}
                      />
                    }
                    label=""
                  />
                </Stack>
              </Grid>
            </Grid>
            <Stack spacing={0} sx={{ marginTop: "48px" }}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 32,
                  fontWeight: 700,
                  color: "#060A25",
                }}
              >
                Community
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  overflow: "hidden",
                }}
              >
                <Divider
                  sx={{
                    backgroundColor: "#45B08A",
                    height: "2px",
                    width: "135px",
                  }}
                ></Divider>
                <Divider
                  sx={{
                    backgroundColor: "#E8E8E8",
                    height: "2px",
                    width: "100%",
                  }}
                ></Divider>
              </Stack>
            </Stack>
            <Stack
              spacing={2}
              flexDirection="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
              >
                Require your approval before others can follow you.
              </Typography>
              <FormControlLabel
                sx={{ margin: "0 !important" }}
                control={
                  <IOSSwitch
                    checked={isApprouveRequired}
                    onChange={(e) => setIsApprouveRequired(e.target.checked)}
                  />
                }
                label=""
              />
            </Stack>
          </Stack>
          <Stack
            spacing={2}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginTop: "40px", p: "0 18px" }}
          >
            <Button
              sx={{
                width: "356px",
                padding: "16px",
                background: "#2575FC",
                lineHeight: "120%",
                textTransform: "capitalize",
                fontWeight: 500,
                fontSize: "16px",
              }}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              type="submit"
              color="primary"
            >
              {group ? "Save Changes" : "Create"}
            </Button>
            {group && (
              <ButtonDelete
                variant="contained"
                color="error"
                sx={{
                  textTransform: "none",
                  padding: "13px 50px",
                }}
                onClick={handleClickOpenDialog}
              >
                Delete
              </ButtonDelete>
            )}
          </Stack>
        </>
      )}
      <Snackbar
        open={notificationShow}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Add Group image
        </Alert>
      </Snackbar>
      <Snackbar
        open={notificationCopy}
        autoHideDuration={6000}
        onClose={handleNotificationCopyClose}
      >
        <Alert
          onClose={handleNotificationCopyClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          The link has been copied!
        </Alert>
      </Snackbar>
    </Stack1>
  );
};

export default GroupAddEdit;
