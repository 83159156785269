import { useEffect, useState } from "react"
import { AccountBalanceHistoryClient } from "../../utilities/backend/client"
import { Box, CircularProgress, Paper,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"


const AccountHistoryAdmin = () => {
    const accountHistoryClient = new AccountBalanceHistoryClient()
    const [accountHistories, setAccountHistories] = useState<any[]>()

    const [loading, setLoading] = useState<boolean>(true)
    
    useEffect(() =>{
        setLoading(true)
        accountHistoryClient.accountBalanceHistory_GetAll()
        .then(async (response) => {
            var data = JSON.parse(await response.data.text())
            setAccountHistories(data)
            setLoading(false)
        })
        .catch((error) => {
            console.log(error);
            setLoading(false)
        });
    }, [])

    return(
        <>
        {loading ? 
            <Box sx={{margin: "0 auto"}}>
                <CircularProgress />
            </Box>
        :<>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User Name</TableCell>
                            <TableCell>Investment Name</TableCell>
                            <TableCell>Payment Type</TableCell>
                            <TableCell>Old Value</TableCell>
                            <TableCell>New Value</TableCell>
                            <TableCell>Change Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {accountHistories ? accountHistories.map((accountHistory) => (
                        <TableRow key={accountHistory.id}>
                            <TableCell sx={{maxWidth: '200px'}}>{accountHistory.userName}</TableCell>
                            <TableCell>{accountHistory.investmentName}</TableCell>
                            <TableCell>{accountHistory.paymentType}</TableCell>
                            <TableCell>{accountHistory.oldValue}</TableCell>
                            <TableCell>{accountHistory.newValue}</TableCell>
                            <TableCell>{accountHistory.changeDate ? new Date(accountHistory.changeDate).toLocaleDateString("en-US") : ''}</TableCell>
                        </TableRow>
                    )) : <TableRow></TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>            
        </>}
        </>
    )
}

export default AccountHistoryAdmin