import {
  Alert,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import {
  ApprovedByClient,
  CategoryCreationDto,
  CategoryDto,
} from "../../utilities/backend/client";
import PrimaryButton from "../PrimaryButton";

interface CategoryProps {}

const ApprovedByAdmin = (props: CategoryProps) => {
  const approvedByClient = new ApprovedByClient();

  const [action, setAction] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<CategoryDto>();
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [selectValue, setSelectValue] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>();
  const [mandatory, setMandatory] = useState<boolean>(false);
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>("");

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;

    const newArr = [...categoryList];
    const item = newArr.find((k) => k.name === value);
    setSelectedCategory(item);
    setCategoryList(newArr);
    setInputValue(value);
    setSelectValue(value);
  };

  const inputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const onCategoryUpdate = () => {
    if (selectedCategory) {
      const categoryToUpdate: CategoryCreationDto = { ...selectedCategory };
      if (inputValue) {
        categoryToUpdate.name = inputValue;
        if (selectedCategory.id) {
          approvedByClient
            .approvedBy_Update(selectedCategory.id, categoryToUpdate)
            .then(async (response) => {
              approvedByClient
                .approvedBy_GetAll()
                .then(async (response) => {
                  var data = JSON.parse(await response.data.text());
                  setInputValue("");
                  setSelectedCategory(undefined);
                  setCategoryList(data);
                  setSelectValue("");
                  setNotificationText(
                    "The sourced by is successfully updated!"
                  );
                  setNotificationShow(true);
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
  };

  useEffect(() => {
    approvedByClient
      .approvedBy_GetAll()
      .then(async (response) => {
        setCategoryList(JSON.parse(await response.data.text()));
      })
      .catch((error) => {
        console.log(error);
      });
    setInputValue("");
  }, [action]);

  const onCategoryCreate = () => {
    if (inputValue) {
      const categoryToUpdate: CategoryCreationDto = {
        name: inputValue,
        mandatory: mandatory,
      };
      approvedByClient
        .approvedBy_Create(categoryToUpdate)
        .then(async (response) => {
          setInputValue("");
          setMandatory(false);
          setNotificationText("The sourced by is successfully created!");
          setNotificationShow(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onCategoryDelete = () => {
    if (selectedCategory && selectedCategory.name) {
      const categoryToUpdate: CategoryDto = { ...selectedCategory };
      approvedByClient
        .approvedBy_Remove(categoryToUpdate)
        .then(async (response) => {
          approvedByClient
            .approvedBy_GetAll()
            .then(async (response) => {
              var data = JSON.parse(await response.data.text());
              setInputValue("");
              setSelectedCategory(undefined);
              setCategoryList(data);
              setSelectValue("");
              setNotificationText("The sourced by is successfully deleted!");
              setNotificationShow(true);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <PrimaryButton
        click={() => {
          setAction("update");
          setSelectValue("");
        }}
        text="Update"
      />
      <PrimaryButton click={() => setAction("create")} text="Create" />
      <PrimaryButton
        click={() => {
          setAction("delete");
          setSelectValue("");
        }}
        text="Delete"
      />
      {action === "update" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "1011px",
              gap: "5px",
              flexDirection: "column",
            }}
          >
            <Typography>Select Sourced by to update</Typography>
            <FormControl sx={{ m: 1, width: 500 }}>
              <InputLabel id="demo-multiple-name-label">
                Sourced By to update
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={selectValue}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Name" />}
              >
                {categoryList.map((i) => (
                  <MenuItem key={i.id} value={i.name}>
                    <ListItemText primary={i.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {selectedCategory && selectedCategory.name !== "" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  flexDirection: "column",
                }}
              >
                <Typography>Sourced By new name</Typography>
                <TextField
                  sx={{ width: 500 }}
                  value={inputValue}
                  onChange={inputChange}
                />
              </div>
              <PrimaryButton
                click={onCategoryUpdate}
                text="Update Sourced By"
              />
            </>
          )}
        </div>
      )}
      {action === "create" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Typography>Input new Sourced By name</Typography>
            <TextField
              sx={{ width: 500 }}
              value={inputValue}
              onChange={inputChange}
            />
          </div>

          <PrimaryButton click={onCategoryCreate} text="Create Sourced By" />
        </div>
      )}
      {action === "delete" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "1011px",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "1011px",
              gap: "5px",
              flexDirection: "column",
            }}
          >
            <Typography>Select category you want to delete</Typography>
            <FormControl sx={{ m: 1, width: 500 }}>
              <InputLabel id="demo-multiple-name-label">
                Sourced By to update
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={selectValue}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Name" />}
              >
                {categoryList.map((i) => (
                  <MenuItem key={i.id} value={i.name}>
                    <ListItemText primary={i.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <PrimaryButton click={onCategoryDelete} text="Delete Sourced By" />
        </div>
      )}
      <Snackbar
        open={notificationShow && notificationText.length !== 0}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {notificationText}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ApprovedByAdmin;
