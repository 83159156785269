import type { PropsWithChildren } from "react";
import { useEffect } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../utilities/redux/store";

export default function OnlyIfNotAuthenticated({
  children,
}: PropsWithChildren) {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state: RootState) => state.token);
  useEffect(() => {
    if (token && token.token) {
      if (location.pathname === "/login") navigate("/home");
    }

    if (!(token && token.token) && location.pathname === "/home") {
      navigate("/login");
    }
  }, []);

  return !token.token ? <>{children}</> : null;
}
