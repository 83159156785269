import type { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utilities/redux/store";
import { Navigate, useLocation } from "react-router-dom";

const redirectUrlPaths = ["/settings"];

export default function OnlyIfAuthenticated({ children }: PropsWithChildren) {
  const token = useSelector((state: RootState) => state.token);
  const location = useLocation();

  const protectedPaths = [
    "/support",
    "/portfolio",
    "/settings",
    "/community",
    "/donorbox-invest",
    "/addEditGroup",
    "/group/",
    "/groupInvestments",
  ];
  const isAuthenticatedRoute = protectedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  const redirectUrl = redirectUrlPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  if (redirectUrl && !(token && token.token)) {
    localStorage.setItem("redirectUrl", location.pathname);
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticatedRoute && !(token && token.token)) {
    return <Navigate to="/login" replace />;
  }

  return token.token ? <>{children}</> : null;
}
