import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ApiException,
  DeleteRequestDto,
  FollowingRequestClient,
  FollowingRequestDto,
  TokenDto,
} from "../utilities/backend/client";
import { useSelector } from "react-redux";
import { RootState } from "../utilities/redux/store";
import RequestData from "./RequestData";
import useHandleApiError from "../utilities/backend/Auth/useHandleApiError";
import {
  getUrlBlobContainerImage,
  defaultImage,
} from "../resources/imageConstants";

const UsersRequests = () => {
  const requestClient = new FollowingRequestClient();
  const token: TokenDto = {
    token: useSelector((state: RootState) => state.token.token) || "",
  };
  const [requestList, setRequestList] = useState<FollowingRequestDto[]>();
  const [requestChanger, setRequestChanger] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setApiError] = useState<ApiException | null>(null);
  useHandleApiError(apiError);

  useEffect(() => {
    requestClient
      .followingRequest_GetRequestByUser(token)
      .then((response) => {
        setRequestList(response);
        setLoading(false);
      })
      .catch((error) => {
        setApiError(error);
      });
  }, [requestChanger]);

  function acceptRequest(request: FollowingRequestDto) {
    request.status = "accepted";
    requestClient
      .followingRequest_Update_Request(request.id!, request)
      .then(() => {
        setRequestChanger(!requestChanger);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function declineRequest(request: FollowingRequestDto) {
    let data: DeleteRequestDto = {
      followedGroupId: null,
      followedUserId: request.userToFollowId,
      requestOwnerToken: token.token,
    };

    requestClient
      .followingRequest_Delete_Request(data)
      .then(() => {
        setRequestChanger(!requestChanger);
      })
      .catch((error) => {
        setApiError(error);
      });
  }

  return (
    <>
      <Stack
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
      >
        {loading ? (
          <Box sx={{ margin: "0 auto" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {requestList && requestList.length > 0 ? (
              requestList?.map((request) => (
                <RequestData
                  acceptFunc={() => acceptRequest(request)}
                  fullName={request ? request.requestOwnerName! : ""}
                  picture={
                    request?.requestOwnerPicture!
                      ? getUrlBlobContainerImage(request?.requestOwnerPicture!)
                      : defaultImage
                  }
                  declineFunc={() => declineRequest(request)}
                  key={request.id}
                ></RequestData>
              ))
            ) : (
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 18,
                  fontWeight: 400,
                  color: "#656565",
                  lineHeigh: "20.68px",
                }}
              >
                No Requests yet
              </Typography>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default UsersRequests;
