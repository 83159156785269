import { Typography } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface ContentTitleProps {
  title: string;
  description: string;
  hyperlink?: ReactNode;
  margin?: string;
  maxWidth?: number;
  style?: CSSProperties | undefined;
  titleFontSize?: number;
  descriptionFontSize?: number;
}

const ContentTitle = ({
  title,
  description,
  hyperlink,
  margin,
  maxWidth = 1011,
  style,
  titleFontSize = 32,
  descriptionFontSize = 18,
}: ContentTitleProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        maxWidth,
        margin: "0 auto",
        padding: "0 18px",
        marginTop: margin,
        ...style,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Ubuntu",
          fontSize: titleFontSize,
          fontWeight: 700,
          color: "#060A25",
          lineHeigh: "38.4px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontFamily: "Ubuntu",
          fontSize: descriptionFontSize,
          fontWeight: 400,
          color: "#474747",
          lineHeigh: "20.68px",
          mt: "15px",
        }}
      >
        {description}
      </Typography>
      <Typography
        sx={{
          fontFamily: "Ubuntu",
          fontSize: descriptionFontSize,
          fontWeight: 400,
          color: "#474747",
          lineHeigh: "20.68px",
          mt: "15px",
        }}
      >
        {hyperlink}
      </Typography>
    </div>
  );
};

export default ContentTitle;
