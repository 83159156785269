import { Stack, Typography } from "@mui/material";

const AboutUs = () => {
  return (
    <Stack
      sx={{
        justifyContent: "center",
        margin: "auto",
        paddingTop: "25px",
      }}
      width={800}
      spacing={4}
    >
      <Typography
        sx={{
          fontFamily: "Ubuntu",
          fontSize: 18,
          fontWeight: 700,
          lineHeigh: "20.68px",
          mt: "30px !important",
        }}
        style={{
          textAlign: "left",
          fontFamily: "Roboto, sans-serif",
          fontSize: "32px",
          color: "#6ec1e4",
        }}
      >
        About CataCap
      </Typography>

      <Typography
        sx={{
          fontFamily: "Ubuntu",
          fontSize: 22,
          fontWeight: 400,
          color: "#000000",
          mt: "15px !important",
        }}
      >
        CataCap is an initiative of&nbsp;
        <a
          href="https://www.catacap.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Impactree Foundation
        </a>
        , sitting within its donor advised fund (DAF) at ImpactAssets (IA) and
        other DAF sponsors. Our lifelong commitment to making change
        irresistible and harnessing the transformational potential of the
        private sector for social change brought us together and underpins our
        efforts on this project. CataCap and IF are unrelated to IA or other DAF
        sponsors, other than in their use of DAFs for holding donated assets for
        the purpose of impact investing and grantmaking. Special thanks to&nbsp;
        <a
          href="https://www.rpck.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          RPCK LLP
        </a>
        &nbsp;and Chintan Panchal for supporting CataCap during its startup
        design phase with legal and compliance advice.
      </Typography>
      <Typography
        sx={{
          fontFamily: "Ubuntu",
          fontSize: 22,
          fontWeight: 400,
          color: "#000000",
          mt: "16px !important",
        }}
      >
        Please&nbsp;
        <a
          href="mailto:support@impactree.org"
          style={{ fontWeight: 700, textDecoration: "underline" }}
        >
          contact us
        </a>
        &nbsp;with any questions, input, or partnership inquiries
      </Typography>
      <Typography
        sx={{
          fontFamily: "Ubuntu",
          fontSize: 18,
          fontWeight: 700,
          lineHeigh: "20.68px",
          marginTop: 40,
          mt: "75px !important",
        }}
        style={{
          textAlign: "left",
          fontFamily: "Roboto, sans-serif",
          fontSize: "32px",
          color: "#6ec1e4",
        }}
      >
        Why Use a DAF To Invest in Impact?
      </Typography>
      <Typography
        sx={{
          fontFamily: "Ubuntu",
          fontSize: 22,
          fontWeight: 400,
          color: "#000000",
          mt: "15px !important",
        }}
      >
        If you are investing first motivated by impact, in things you are
        passionate about to change the world for the better and help people
        and/or planet, your philanthropic pocket can be very aligned with the
        value proposition to you (including financially). You can get a full tax
        deduction this year through utilizing a DAF. And in this way, you don’t
        need to be an Accredited Investor, much less Qualified Investor (as
        required for some funds). And it’s very streamlined with no K1s to you,
        no filing extensions triggered by late K1s and no further tax
        implications of any kind over the years. And, you can pool together with
        others to make investment minimums that otherwise would completely
        exclude many investors.
      </Typography>
      <Typography
        sx={{
          fontFamily: "Ubuntu",
          fontSize: 18,
          fontWeight: 700,
          lineHeigh: "20.68px",
          mt: "75px !important",
        }}
        style={{
          textAlign: "left",
          fontFamily: "Roboto, sans-serif",
          fontSize: "32px",
          color: "#6ec1e4",
        }}
      >
        Why Use This Impact Investing App?
      </Typography>
      <Typography
        sx={{
          fontFamily: "Ubuntu",
          fontSize: 22,
          fontWeight: 400,
          color: "#000000",
          mt: "15px !important",
        }}
      >
        Even if a DAF sponsor allows such impact investing (most do not) at
        $25,000 or more per investment, the App allows you to deploy into funds
        and deals at a “micro” level with amounts as little as $250 each.
        Essentially, the App allows you to diversify into a customized portfolio
        through a donor account that has a $250 minimum to establish (vs
        $25,000) and costs roughly the same expense ratio as a $1M DAF doing
        $100K deals and fund investments.
      </Typography>
    </Stack>
  );
};

export default AboutUs;
