import { SetStateAction, useEffect, useState } from "react";
import { AuthClient, User, UsersClient } from "../../utilities/backend/client";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { removeToken, setRole, setToken } from "../../utilities/redux/actions";
import { RootState } from "../../utilities/redux/store";
import { useNavigate } from "react-router-dom";

interface UsersAdminProps {}

const Search = styled(TextField)(({ theme }) => ({
  width: 410,
  paddingBottom: "48px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    paddingBottom: "40px",
  },
}));

const ExportButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "120%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const UsersAdmin = (props: UsersAdminProps) => {
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>();
  const [notificationErrorShow, setNotificationErrorShow] =
    useState<boolean>(false);
  const [notificationErrorText, setNotificationErrorText] = useState<string>();
  const usersClient = new UsersClient();
  const authClient = new AuthClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>();
  const [accountBalance, setAccountBalance] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [filterByGroupAccountBalance, setFilterByGroupAccountBalance] =
    useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<User[]>();
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const token: string =
    useSelector((state: RootState) => state.token.token) || "";

  const getUsers = () => {
    setLoading(true);
    usersClient
      .users_GetUsers()
      .then(async (response) => {
        setUsers(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    filterUsers();
  }, [users, filterByGroupAccountBalance, search]);

  const updateAccountBalance = (email: string, newBalance: number) => {
    try {
      usersClient
        .users_UpdateAccountBalance(email, newBalance)
        .then(async () => {
          setNotificationText(
            "Account balance for the user has been successfully updated!"
          );
          setNotificationShow(true);
          console.log("Account Ballance has been updated succesfully!");
          getUsers();
        })
        .catch((error) => {
          setNotificationErrorShow(true);
          setNotificationErrorText(error.response);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserStatus = (email: string, status: boolean) => {
    try {
      usersClient
        .users_UpdateActiveStatus(email, status)
        .then(async () => {
          setNotificationText(
            "Status for the user has been successfully updated!"
          );
          setNotificationShow(true);
          console.log("Status has been updated succesfully!");
          getUsers();
        })
        .catch((error) => {
          setNotificationErrorShow(true);
          setNotificationErrorText(error.response);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const plusClick = (email: string | undefined | null) => {
    if (email) updateAccountBalance(email, Number(accountBalance));
  };

  const minusClick = (email: string | undefined | null) => {
    if (email) updateAccountBalance(email, -Number(accountBalance));
  };

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  const handleNotificationErrorClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationErrorShow(false);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    email: string
  ) => {
    updateUserStatus(email, event.target.checked);
  };

  const filterUsers = () => {
    let filtered = users;
    if (filterByGroupAccountBalance) {
      filtered = filtered?.filter((user) => user.groupAccountBalance !== null);
    }
    if (search) {
      const searchTerm = search.toLowerCase();
      filtered = filtered?.filter(
        (user) =>
          user.email?.toLowerCase().includes(searchTerm) ||
          user.firstName?.toLowerCase().includes(searchTerm) ||
          user.lastName?.toLowerCase().includes(searchTerm)
      );
    }

    filtered?.sort((a, b) => {
      const dateA = new Date(a.dateCreated!).getTime();
      const dateB = new Date(b.dateCreated!).getTime();
      if (sortDirection === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    setFilteredUsers(filtered);
  };

  const handleSearchChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearch(event.target.value);
  };

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const loginUserToAdmin = (
    email: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const userLoginFromAdmin = {
      userToken: token,
      email: email,
    };

    authClient
      .auth_AuthenticateAdminToUser(userLoginFromAdmin)
      .then(async (response) => {
        dispatch(removeToken());
        const newToken = await response?.data.text();
        if (newToken !== undefined) {
          dispatch(setRole(JSON.parse(atob(newToken.split(".")[1])).role));
          dispatch(setToken(JSON.parse(newToken).token));
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    filterUsers();
  }, [users, filterByGroupAccountBalance, search, sortDirection]);

  const handleExportAll = () => {
    usersClient
      .exportUsers()
      .then(() => {
        console.log("Export successful");
      })
      .catch((error) => {
        console.error("Error exporting users", error);
      });
  };

  return (
    <>
      {loading ? (
        <Box sx={{ margin: "0 auto" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div
            style={{
              display: "grid",
              flexDirection: "column",
              alignItems: "right",
              gap: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#656565",
                }}
              >
                <span style={{ color: "#191919", fontWeight: "700" }}>
                  Filter by Group
                </span>
              </Typography>
              <Checkbox
                checked={filterByGroupAccountBalance}
                onChange={(e) =>
                  setFilterByGroupAccountBalance(!filterByGroupAccountBalance)
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Search
                placeholder="Full Name, Email"
                type="text"
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <ExportButton
                style={{ marginRight: "10px", alignSelf: "flex-end" }}
                sx={{
                  background: "#2575FC",
                }}
                variant="contained"
                color="primary"
                onClick={handleExportAll}
              >
                Export All
              </ExportButton>
            </div>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Full Name</TableCell>
                    <TableCell align="right" onClick={toggleSortDirection}>
                      {sortDirection === "asc" ? (
                        <span>&uarr;</span>
                      ) : (
                        <span>&darr;</span>
                      )}
                      Date Created
                    </TableCell>
                    <TableCell align="right">Phone</TableCell>
                    {filterByGroupAccountBalance && (
                      <>
                        <TableCell align="right">Group Name</TableCell>
                        <TableCell align="right">
                          Group Account Balance
                        </TableCell>
                      </>
                    )}
                    <TableCell align="right">Account Balance</TableCell>
                    <TableCell align="left">Account Balance Actions</TableCell>
                    <TableCell align="left">Username</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="center">Is Active</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers &&
                    filteredUsers.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">{row.email}</TableCell>
                        <TableCell align="right">
                          {row.firstName + " " + row.lastName}
                        </TableCell>
                        <TableCell align="right">
                          {row.dateCreated
                            ? new Date(row.dateCreated).toLocaleDateString(
                                "en-US"
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="right">{row.phoneNumber}</TableCell>
                        {filterByGroupAccountBalance && (
                          <>
                            <TableCell align="right">
                              {row.groupAccountBalance?.groupName}
                            </TableCell>
                            <TableCell align="right">
                              {row.groupAccountBalance?.balance}
                            </TableCell>
                          </>
                        )}
                        <TableCell align="right">
                          {row.accountBalance}
                        </TableCell>
                        <TableCell align="left">
                          <TextField
                            onChange={(e) => setAccountBalance(e.target.value)}
                            id="outlined-number"
                            label="Account Balance"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <IconButton
                            disabled={loading}
                            onClick={() => plusClick(row.email)}
                          >
                            <Add />
                          </IconButton>
                          <IconButton
                            disabled={loading}
                            onClick={() => minusClick(row.email)}
                          >
                            <Remove />
                          </IconButton>
                        </TableCell>
                        <TableCell align="left">{row.userName}</TableCell>
                        <TableCell align="left">
                          {row.isActive && (
                            <a
                              style={{
                                color: "#007bff",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                loginUserToAdmin(row.email!, e);
                              }}
                            >
                              Login
                            </a>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            checked={row.isActive!}
                            onChange={(e) => handleChange(e, row.email!)}
                            disabled={loading}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}

      <Snackbar
        open={notificationShow}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {notificationText}
        </Alert>
      </Snackbar>

      <Snackbar
        open={notificationErrorShow}
        autoHideDuration={6000}
        onClose={handleNotificationErrorClose}
      >
        <Alert
          onClose={handleNotificationErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {notificationErrorText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UsersAdmin;
