import { Box, Button, Stack, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
      'div': {
        width: 'initial !important',
        height: 'initial !important',
      }
  },
}));

const PdfViewer = ({ pdfBase64 } : { pdfBase64: string }) => {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const onDocumentLoadSuccess = ({ numPages } : { numPages: number }) => {
    setNumPages(numPages);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", '& > div': { width: '100%' }, canvas: { width: '100% !important', height: 'auto !important' } }}>
      <PDFContainer>
        <Document
          file={pdfBase64}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={currentPage}          
              renderTextLayer={false}
          />
        </Document>
      </PDFContainer>
      <Stack spacing={2} direction="row" justifyContent={"center"} alignItems={"center"} sx={{marginTop: "20px"}}>
        <Button onClick={handlePrevPage} disabled={currentPage === 1} variant='contained' color='primary' startIcon={<ArrowBackIcon />} sx={{width: "200px"}}>
          Previous Page
        </Button>
        <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 14, fontWeight: 400, color: "#060A25", lineHeigh: '20.68px' }}>
          Page {currentPage} of {numPages}
        </Typography>
        <Button onClick={handleNextPage} disabled={currentPage === numPages} variant='contained' color='primary' endIcon={<ArrowForwardIcon />} sx={{width: "200px"}}>
          Next Page
        </Button>
      </Stack>
    </Box>
  );
};

export default PdfViewer;