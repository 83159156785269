import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  Stack,
  Switch,
  SwitchProps,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { set, useForm } from "react-hook-form";
import {
  ApiException,
  EditUserDto,
  TokenDto,
  UsersClient,
} from "../../../utilities/backend/client";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../utilities/redux/store";
import { styled } from "@mui/material/styles";
import useHandleApiError from "../../../utilities/backend/Auth/useHandleApiError";
import {
  defaultImage,
  getUrlBlobContainerImage,
} from "../../../resources/imageConstants";
import { SVG } from "../../../icons";

const CopyButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: 2,
}));

const CopyLinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  margin: "0 auto 31px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "0 auto 48px",
  },
}));

const Button2 = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "10px 30px",
  },
}));

const Button1 = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "10px 15px",
  },
}));

const Stack1 = styled(Stack)(({ theme }) => ({
  width: "800px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AccountSettings = () => {
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [notificationCopy, setNotificationCopy] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  const userClient = new UsersClient();
  const token: TokenDto = {
    token: useSelector((state: RootState) => state.token.token) || "",
  };
  const [user, setUser] = useState<EditUserDto>();
  const [userPictureFile, setUserPictureFile] = useState<any>("");
  const [userPictureError, setUserPictureError] = useState<boolean>();
  const [isApprouveRequired, setIsApprouveRequired] = useState<boolean>();
  const [isUserHidden, setIsUserHidden] = useState<boolean>();
  const [emailFromUsersOn, setEmailFromUsersOn] = useState<boolean>();
  const [emailFromGroupsOn, setEmailFromGroupsOn] = useState<boolean>();
  const [optOutEmailNotifications, setOptOutEmailNotifications] =
    useState<boolean>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const [displayFileSizeError, setDisplayFileSizeError] = useState<boolean>();
  const [apiError, setApiError] = useState<ApiException | null>(null);
  useHandleApiError(apiError);

  useEffect(() => {
    setLoading(true);
    userClient
      .users_GetUser(token)
      .then((response) => {
        setUser(response);
        setIsApprouveRequired(response.isApprouveRequired);
        setIsUserHidden(response.isUserHidden);
        setEmailFromUsersOn(response.emailFromUsersOn);
        setEmailFromGroupsOn(response.emailFromGroupsOn);
        setOptOutEmailNotifications(response.optOutEmailNotifications);
        setTimeout(() => {
          setValue("firstName", response.firstName || "");
          setValue("lastName", response.lastName || "");
          setValue("userName", response.userName || "");
          setValue("email", response.email || "");
          setValue("phoneNumber", response.phoneNumber || "");
          setValue("address", response.address || "");
        }, 0);
        setLoading(false);
      })
      .catch((error) => {
        setApiError(error);
      });
  }, []);

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const uploadImageChange = async (e: any) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size >= 204659) setDisplayFileSizeError(true);
      else {
        setDisplayFileSizeError(false);
        setUserPictureFile(await toBase64(e.target.files[0]));
      }
    }
  };

  const validatePhoneNumber = (value: string | null | undefined) => {
    if (!value) return "Phone number is required";
    if (
      typeof value !== "string" ||
      !value.match(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
    )
      return "Invalid phone number.";
    return true;
  };

  const handlePhoneNumberChange = (event: any) => {
    let phoneNumber = event.target.value.replace(/\D/g, "").slice(0, 10);

    setValue("phoneNumber", phoneNumber);
  };

  const handleNotificationCopyClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationCopy(false);
  };

  const form = useForm<any>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      address: "",
    },
  });

  const { register, handleSubmit, formState, setValue } = form;
  const { errors } = formState;

  const handleImageSelection = () => {
    fileInputRef.current?.click();
  };

  const onSubmit = async (data: any) => {
    if (!displayFileSizeError) {
      setUserPictureError(false);
      try {
        let requestUserEdit: EditUserDto = {
          phoneNumber: data.phoneNumber,
          token: token.token!,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          address: data.address,
          accountBalance: user?.accountBalance,
          isApprouveRequired: isApprouveRequired,
          isUserHidden: isUserHidden,
          emailFromUsersOn: emailFromUsersOn,
          emailFromGroupsOn: emailFromGroupsOn,
          optOutEmailNotifications: optOutEmailNotifications,
          pictureFileName: user?.pictureFileName,
        };

        if (userPictureFile) {
          let imgBase64 = userPictureFile;

          requestUserEdit.pictureFile =
            typeof imgBase64 === "string" ? imgBase64 : "";
        }

        userClient
          .users_UpdateUser(requestUserEdit)
          .then(() => {
            setNotificationShow(true);
          })
          .catch((error) => {
            setApiError(error);
          });
      } catch (error) {
        console.error(error);
      }
    } else {
      setUserPictureError(true);
    }
  };

  const linkCopyUser = () => {
    return `https://app.catacap.org/user/${user?.userName}`;
  };

  const handleCopyButtonClick = async () => {
    const userToUrl = `https://app.catacap.org/user/${user?.userName}`;

    try {
      await navigator.clipboard.writeText(userToUrl);
      setNotificationCopy(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <>
      <Stack1
        sx={{
          justifyContent: "center",
          margin: "auto",
          paddingBottom: "30px",
          paddingTop: "70px",
        }}
        spacing={4}
      >
        {loading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", margin: "0 auto" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Stack spacing={0} sx={{ p: "0 18px" }}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 32,
                  fontWeight: 700,
                  color: "#060A25",
                }}
              >
                Personal Info
              </Typography>
              <Divider></Divider>
            </Stack>
            <Stack spacing={4}>
              <Stack
                spacing={2}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                padding="0 18px"
              >
                <Avatar
                  src={
                    user?.pictureFileName!
                      ? getUrlBlobContainerImage(user?.pictureFileName!)
                      : defaultImage
                  }
                  sx={{ width: 100, height: 100 }}
                  variant="rounded"
                />
                {userPictureError && (
                  <Typography>Please, add valid user picture.</Typography>
                )}
                <FormControl>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleImageSelection}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      Change Picture
                    </Typography>
                    <input
                      hidden
                      onChange={uploadImageChange}
                      accept="image/*"
                      type="file"
                      ref={fileInputRef}
                    />
                  </Button>
                </FormControl>
              </Stack>
              {displayFileSizeError && (
                <Typography sx={{ color: "red" }}>
                  Please, try different image. The size of the image should be
                  less than 200KB
                </Typography>
              )}
              <Grid
                container
                spacing={2}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
                padding="0 18px 0 0"
              >
                <Grid item xs={6} width={"100%"}>
                  <Stack
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        First Name
                      </Typography>
                      <TextField
                        fullWidth
                        type="text"
                        error={!!errors.name}
                        helperText={errors.name?.message?.toString()}
                        {...register("firstName", {
                          required: "First Name is required",
                        })}
                      ></TextField>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={6} width={"100%"}>
                  <Stack
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        Last Name
                      </Typography>
                      <TextField
                        fullWidth
                        type="text"
                        error={!!errors.name}
                        helperText={errors.name?.message?.toString()}
                        {...register("lastName", {
                          required: "Last Name is required",
                        })}
                      ></TextField>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} width={"100%"}>
                  <Stack spacing={1} alignItems={"center"}>
                    <FormControl sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        User Name
                      </Typography>
                      <TextField
                        sx={{ width: "100%" }}
                        type="text"
                        disabled={true}
                        error={!!errors.userName}
                        helperText={errors.userName?.message?.toString()}
                        {...register("userName")}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={6} width={"100%"}>
                  <Stack
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        Email Address
                      </Typography>
                      <TextField
                        fullWidth
                        type="email"
                        error={!!errors.name}
                        helperText={errors.name?.message?.toString()}
                        {...register("email", {
                          required: "Email is required",
                        })}
                      ></TextField>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={6} width={"100%"}>
                  <Stack
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        Phone Number
                      </Typography>
                      <TextField
                        sx={{ width: "100%" }}
                        type="text"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber?.message?.toString()}
                        {...register("phoneNumber", {
                          validate: validatePhoneNumber,
                        })}
                        onChange={handlePhoneNumberChange}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} width={"100%"}>
                  <Stack
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    width={"100%"}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#474747",
                          lineHeigh: "20.68px",
                        }}
                      >
                        Address
                      </Typography>
                      <TextField
                        fullWidth
                        type="text"
                        error={!!errors.name}
                        helperText={errors.name?.message?.toString()}
                        {...register("address")}
                      ></TextField>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} width={"100%"}>
                  <Stack
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    width={"100%"}
                  >
                    {!isUserHidden && (
                      <FormControl sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: "Ubuntu",
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#474747",
                            lineHeigh: "20.68px",
                          }}
                        >
                          Your Group Share URL is:
                        </Typography>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={9}>
                            <Typography
                              sx={{
                                fontFamily: "Ubuntu",
                                fontSize: 16,
                                fontWeight: 400,
                                lineHeigh: "20.68px",
                                marginBottom: "8px",
                              }}
                            >
                              {linkCopyUser()}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <CopyLinkContainer>
                              <Tooltip
                                placement="top"
                                title="Clicking this button will copy the user identifier share link"
                              >
                                <CopyButton onClick={handleCopyButtonClick}>
                                  <SVG.Copy />
                                </CopyButton>
                              </Tooltip>
                            </CopyLinkContainer>
                          </Grid>
                        </Grid>
                      </FormControl>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack spacing={0} sx={{ p: "0 18px" }}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 32,
                  fontWeight: 700,
                  color: "#060A25",
                }}
              >
                Community
              </Typography>
              <Divider></Divider>
            </Stack>
            <Stack sx={{ p: "0 18px" }}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#060A25",
                    }}
                  >
                    Require your approval before others can follow you.
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={isApprouveRequired}
                        onChange={(e) =>
                          setIsApprouveRequired(e.target.checked)
                        }
                      />
                    }
                    label=""
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack sx={{ p: "0 18px" }}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#060A25",
                    }}
                  >
                    Hide my profile
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={isUserHidden}
                        onChange={(e) => setIsUserHidden(e.target.checked)}
                      />
                    }
                    label=""
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack spacing={0} sx={{ p: "0 18px" }}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 32,
                  fontWeight: 700,
                  color: "#060A25",
                }}
              >
                Notification
              </Typography>
              <Divider></Divider>
            </Stack>
            <Stack sx={{ p: "0 18px" }}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#060A25",
                    }}
                  >
                    Send me notifications about investments by people I follow.
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={emailFromUsersOn}
                        onChange={(e) => {
                          console.log(e);
                          setEmailFromUsersOn(e.target.checked);
                        }}
                      />
                    }
                    label=""
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#060A25",
                    }}
                  >
                    Send me notifications about investments by people in Groups
                    I belong to.
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={emailFromGroupsOn}
                        onChange={(e) => setEmailFromGroupsOn(e.target.checked)}
                      />
                    }
                    label=""
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#060A25",
                    }}
                  >
                    Unsubscribe to CataCap notifications.
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={optOutEmailNotifications}
                        onChange={(e) =>
                          setOptOutEmailNotifications(e.target.checked)
                        }
                      />
                    }
                    label=""
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack
              spacing={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ marginTop: "40px", p: "0 18px" }}
            >
              <Button1
                variant="outlined"
                sx={{
                  color: "black",
                  backgroundColor: "white",
                  border: "1px solid #474747",
                  textTransform: "none",
                  padding: "13px 50px",
                }}
                color="secondary"
                onClick={() => {
                  navigate("/home");
                }}
              >
                Cancel
              </Button1>
              <Button2
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#2575FC",
                  border: "1px solid #2575FC",
                  textTransform: "none",
                  padding: "13px 150px",
                }}
                color="primary"
                onClick={handleSubmit(onSubmit)}
                type="submit"
              >
                Save Changes
              </Button2>
            </Stack>
          </>
        )}
      </Stack1>

      <Snackbar
        open={notificationShow}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Personal Info successfully updated!
        </Alert>
      </Snackbar>
      <Snackbar
        open={notificationCopy}
        autoHideDuration={6000}
        onClose={handleNotificationCopyClose}
      >
        <Alert
          onClose={handleNotificationCopyClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          The link has been copied!
        </Alert>
      </Snackbar>
    </>
  );
};

export default AccountSettings;
