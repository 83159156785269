import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ContentTitle from "../../../components/ContentTitle";
import DAFIcon from "../../../resources/dafIcon";
import DonateIcon from "../../../resources/donateIcon";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Wrapper = styled(Stack)(({ theme }) => ({
  paddingTop: "40px",
  margin: "auto",
  width: 1200,
  [theme.breakpoints.down("sm")]: {
    width: "initial",
    padding: "40px 17px 80px",
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Items = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  paddingBottom: "40px",
  paddingTop: "40px",
  gap: "16px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    paddingTop: 0,
  },
}));

const Item = styled("div")<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    width: "546px",
    height: "246px",
    borderRadius: "8px",
    border: isSelected ? "2px solid #2575FC" : "1px solid #E8E8E8",
    background: isSelected ? "#F5F7F9" : "#FFFFFF",
    marginTop: "0 !important",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 46px)",
      padding: "0 23px",
      textAlign: "center",
    },
  })
);

const ButtonWrap = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "0 !important",
  },
}));

const InvestmentButton = styled(Button)(({ theme }) => ({
  width: "546px",
  padding: "16px 24px",
  lineHeight: "120%",
  background: "#2575FC",
  textTransform: "capitalize",
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const FundInvestment = () => {
  const navigate = useNavigate();
  const [isDonate, setIsDonate] = useState(false);
  const [isDAFSelect, setIsDAFSelect] = useState(false);
  const [isDonateSelect, setIsDonateSelect] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const goToNextStep = () => {
    if (isDAFSelect) navigate("/daf-fund-invest");
    if (isDonateSelect) setIsDonate(true);
  };

  const handleModalOpen = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setModalOpen(true);
    return false;
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  function RenderBody() {
    return (
      <>
        {!isDonate ? (
          <>
            <Items spacing={2}>
              <Item
                isSelected={isDAFSelect}
                onClick={() => {
                  setIsDonateSelect(false);
                  setIsDAFSelect(true);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                  width={"100%"}
                  height={"100%"}
                >
                  <div style={{ marginBottom: "20px" }}>
                    <DAFIcon></DAFIcon>
                  </div>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 24,
                      fontWeight: 700,
                      color: "#191919",
                      lineHeigh: "38.4px",
                    }}
                  >
                    Via my DAF or Foundation
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#656565",
                      lineHeigh: "20.68px",
                      mt: "15px",
                    }}
                  >
                    Issue a grant to fund your investment
                  </Typography>
                </Box>
              </Item>
              <Item
                isSelected={isDonateSelect}
                onClick={() => {
                  setIsDAFSelect(false);
                  setIsDonateSelect(true);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                  width={"100%"}
                  height={"100%"}
                >
                  <div style={{ marginBottom: "20px" }}>
                    <DonateIcon></DonateIcon>
                  </div>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 24,
                      fontWeight: 700,
                      color: "#191919",
                      lineHeigh: "38.4px",
                    }}
                  >
                    Donate Now
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#656565",
                      lineHeigh: "20.68px",
                      mt: "15px",
                    }}
                  >
                    Make a tax-deductible donation via credit card or ACH bank
                    payment.
                  </Typography>
                </Box>
              </Item>
            </Items>
            <ButtonWrap alignItems={"center"} justifyContent={"center"}>
              <InvestmentButton
                variant="contained"
                color="primary"
                onClick={() => {
                  isDAFSelect
                    ? navigate("/daf-fund-invest")
                    : isDonateSelect
                    ? setIsDonate(true)
                    : console.log("empty");
                }}
              >
                Continue
              </InvestmentButton>
            </ButtonWrap>
            <Stack width={"100%"}>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontFamily: "Ubuntu",
                  fontSize: 10,
                  fontWeight: 400,
                  color: "#474747",
                  p: "10px 18px",
                }}
              >
                <span style={{ fontWeight: 700, textDecoration: "underline" }}>
                  Important Note:
                </span>
                &nbsp; Donations to CataCap donor accounts become part of the
                501c3 public charity Impactree Foundation’s CataCap donor
                advised fund at ImpactAssets (also a 501c3 public charity), and
                contributions do not involve a purchase or sale of securities
                and represent an irrevocable tax-deductible donation. CataCap
                and Impactree Foundation are unrelated to ImpactAssets, other
                than as a donor to it.
              </Typography>
            </Stack>
          </>
        ) : (
          <Stack alignItems={"center"} justifyContent={"center"}>
            <div style={{ alignItems: "center", justifyContent: "center" }}>
              <script
                src="https://donorbox.org/widget.js"
                data-paypal-express="true"
              ></script>
              <iframe
                src="https://donorbox.org/embed/impact-investing?language=en"
                name="donorbox"
                data-allowpaymentrequest="allowpaymentrequest"
                data-seamless="seamless"
                data-frameborder="0"
                scrolling="no"
                height="850px"
                width="430px"
              ></iframe>
            </div>
            <Stack justifyContent={"flex-start"} alignItems={"center"}>
              <Typography
                width={"100%"}
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 18,
                  color: "#474747",
                  lineHeight: "20.68px",
                }}
              >
                All credit card donations incur a 4% processing fee.
              </Typography>
              <Typography
                width={"100%"}
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 18,
                  color: "#474747",
                  lineHeight: "20.68px",
                }}
              >
                All ACH payments incur a fee of 2.55% fee up to $25 max. Fees
                are deducted from your CataCap Balance.
              </Typography>
              <Typography
                width={"100%"}
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 18,
                  color: "#474747",
                  lineHeight: "20.68px",
                }}
              >
                CataCap & Impact Assets Investment Management fees are 5% (1.25%
                per year for 4 years billed upfront).
              </Typography>
            </Stack>
          </Stack>
        )}
        <BootstrapDialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={modalOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Donation Terms & Conditions
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom>
              By making a contribution to a CataCap donor account, you
              acknowledge and accept the following terms and conditions.
            </Typography>
            <Typography
              gutterBottom
              sx={{
                mt: "15px",
              }}
            >
              You acknowledge and accept that donor accounts in CataCap are
              donations to Impactree Foundation (IF), a 501(c)(3) public
              charity. IF is the donor advisor of CataCap DAF at ImpactAssets
              (IA), also a 501(c)(3) public charity and a DAF sponsor.
              Contributions to CataCap accounts do not involve a purchase or
              sale of securities. Certain donor information may be transmitted
              between IA and IF.
            </Typography>

            <Typography
              gutterBottom
              sx={{
                mt: "15px",
              }}
            >
              You further understand all the following Terms & Conditions: A
              contribution to fund a donor account within CataCap represents an
              irrevocable donation to IF. Contributions are nonrefundable, and
              become subject to IF’s control and sole discretion, as are final
              determinations on all grant and investment recommendations. Final
              determinations on all grant and investment recommendations at
              CataCap DAF are subject to IA’s control and sole discretion. You
              are aware that there are a number of factors to consider when
              assessing the tax implications of gifts to charity of cash, as
              well as the transfer of appreciated securities or other assets
              (and that CataCap recommends that you consult with a tax
              specialist before making any donations).
            </Typography>

            <Typography
              gutterBottom
              sx={{
                mt: "15px",
              }}
            >
              <span style={{ fontWeight: 700, textDecoration: "underline" }}>
                Expenses:
              </span>
              &nbsp; Costs related to CataCap donor accounts are as follows, and
              may be subject to change on a going forward basis:
            </Typography>
            <Typography
              gutterBottom
              sx={{
                m: "15px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div style={{ flex: "0 0 auto", marginRight: "15px" }}>
                &#8226;
              </div>
              <div style={{ flex: "1" }}>
                The administration fee assessed from each donor contribution is
                1.25%, but fees for the first four years of administration (in
                aggregate, 5% of the donated amount) are assessed on a forward
                basis at the time of donation. This fee covers all CataCap donor
                account and CataCap DAF expenses on the donated amount during
                the term.
              </div>
            </Typography>
            <Typography
              gutterBottom
              sx={{
                m: "15px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div style={{ flex: "0 0 auto", marginRight: "15px" }}>
                &#8226;
              </div>
              <div style={{ flex: "1" }}>
                All credit card donations will be charged a transaction fee of
                ~4% by Donorbox.
              </div>
            </Typography>
            <Typography
              gutterBottom
              sx={{
                m: "15px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div style={{ flex: "0 0 auto", marginRight: "15px" }}>
                &#8226;
              </div>
              <div style={{ flex: "1" }}>
                All ACH payments incur a fee of 2.55% fee up to $25 max. Bank
                wire fees will be deducted at cost.
              </div>
            </Typography>
            <Typography
              gutterBottom
              sx={{
                m: "15px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div style={{ flex: "0 0 auto", marginRight: "15px" }}>
                &#8226;
              </div>
              <div style={{ flex: "1" }}>
                If at any time an equity investment is written up in value,
                equity upside is distributed or interest is credited back to a
                donor account, that amount will have an administrative fee
                assessed as if it were a new contribution (but principal and
                capital recovery will not incur further fees). Such events do
                not create a new tax deduction.
              </div>
            </Typography>
            <Typography
              gutterBottom
              sx={{
                m: "15px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div style={{ flex: "0 0 auto", marginRight: "15px" }}>
                &#8226;
              </div>
              <div style={{ flex: "1" }}>
                After four years from a given contribution’s date, an ongoing
                annual fee of 1.25% will be assessed on that contribution’s
                remaining balance in the donor account and debited from
                available Impact Liquidity Balances in the manner in practice
                and disclosed at that date. If an Impact Liquidity Balance is
                not sufficient to cover the annual fee, it will create a
                negative balance until such time as liquidity is available to
                satisfy the balance (see Terms and Conditions “Negative Impact
                Liquidity Balances” discussion).
              </div>
            </Typography>
            <Typography
              gutterBottom
              sx={{
                m: "15px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div style={{ flex: "0 0 auto", marginRight: "15px" }}>
                &#8226;
              </div>
              <div style={{ flex: "1" }}>
                Once deducted from a donor account, fees will not be refunded.
              </div>
            </Typography>
            <Typography
              gutterBottom
              sx={{
                fontStyle: "italic",
                fontFamily: "Ubuntu",
                fontWeight: 400,
                mt: "15px",
              }}
            >
              You will receive a tax deduction for the full value of your
              donation (including fees), but the value attributed to your donor
              account will be net of fees.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleModalClose}>
              Ok
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </>
    );
  }

  return (
    <Wrapper alignItems={"center"} justifyContent="center" spacing={4}>
      <ContentTitle
        title={
          isDonate
            ? "Credit Card or ACH Donation"
            : "How would you like to fund your CataCap donor account?"
        }
        description={
          isDonate
            ? "Complete funding your account with a credit card or ACH donation."
            : ""
        }
        hyperlink={
          isDonate && (
            <p>
              Review the &nbsp;
              <a onClick={handleModalOpen} href="#">
                donation terms and conditions
              </a>
            </p>
          )
        }
      />
      <RenderBody />
    </Wrapper>
  );
};

export default FundInvestment;
