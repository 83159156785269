export const Twitter = () => (
<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="twitter 1">
<g id="Layer 2">
<g id="Color Icon">
<g id="04.Twitter">
<path id="Icon" d="M27.0287 9.57333C26.2492 9.91381 25.4245 10.14 24.5803 10.2449C25.4702 9.71719 26.1355 8.88116 26.4498 7.89544C25.6169 8.38983 24.7055 8.73801 23.7551 8.92491C23.1727 8.30207 22.4163 7.8689 21.5844 7.68173C20.7525 7.49456 19.8835 7.56207 19.0905 7.87547C18.2974 8.18886 17.6171 8.73365 17.1379 9.43896C16.6586 10.1443 16.4028 10.9775 16.4035 11.8302C16.4005 12.1557 16.4337 12.4805 16.5024 12.7986C14.8118 12.7156 13.1578 12.2768 11.6483 11.511C10.1388 10.7452 8.8079 9.66958 7.74243 8.35439C7.19477 9.28998 7.02509 10.3994 7.26809 11.4559C7.51109 12.5125 8.14841 13.4363 9.0498 14.0386C8.37701 14.0205 7.71846 13.8407 7.1298 13.5144V13.5607C7.13169 14.5414 7.47114 15.4915 8.09108 16.2514C8.71103 17.0113 9.57367 17.5346 10.534 17.7333C10.1706 17.8288 9.79607 17.8755 9.42033 17.8723C9.14977 17.8773 8.87945 17.8533 8.61401 17.8007C8.88914 18.6441 9.41883 19.3816 10.1302 19.9116C10.8416 20.4417 11.6998 20.7383 12.5866 20.7607C11.0837 21.9354 9.23101 22.5735 7.32348 22.5733C6.9836 22.5762 6.64391 22.5565 6.30664 22.5144C8.25163 23.7684 10.5188 24.431 12.833 24.4218C20.654 24.4218 24.9298 17.9439 24.9298 12.3291C24.9298 12.1418 24.9298 11.9607 24.9151 11.7796C25.7478 11.1787 26.464 10.431 27.0287 9.57333Z" fill="#45B08A"/>
</g>
</g>
</g>
</g>
</svg>
)