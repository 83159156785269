import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  AddFollowingRequestDto,
  ApiException,
  CampaignClient,
  FollowingRequestClient,
} from "../../../../utilities/backend/client";
import { currency_format } from "../../../../utilities/helpers/currency";
import CampainsGrid from "../../../../components/CampainsGrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../../utilities/redux/store";
import useHandleApiError from "../../../../utilities/backend/Auth/useHandleApiError";

const Item = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  margin: "0 auto",
  mt: "80px",
  height: "100%",
  mb: "50px",
  width: "1280px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column",
    paddingTop: 0,
  },
}));

const HomeLoggedIn = () => {
  const token = useSelector((state: RootState) => state.token);

  const [numberOfInvestments, setNumberOfInvestments] = useState(0);
  const [totalInvestmentAmount, setTotalInvestmentAmount] = useState(0);
  const [accountBalance, setAccountBalance] = useState<number>(0);
  const requestClient = new FollowingRequestClient();
  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setApiError] = useState<ApiException | null>(null);
  useHandleApiError(apiError);

  const [show, setShow] = useState(true);

  const campaignClient = new CampaignClient();

  useEffect(() => {
    setLoading(true);
    followUserAfterSignUp();
    followGroupAfterSignUp();
    Promise.all([campaignClient.campaign_GetPortfolio()])
      .then((values) => {
        setAccountBalance(values[0]?.accountBalance!);

        const sum = values[0].recommendations
          ?.map((i) => i.amount)
          .reduce((prev, next) => (prev && next ? prev + next : 0));
        if (sum) setTotalInvestmentAmount(sum);
        const number = values[0].recommendations?.length;
        if (number) setNumberOfInvestments(number);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  }, []);

  function followUserAfterSignUp() {
    const followingRequestUserId = localStorage.getItem(
      "followingRequestUserId"
    );
    const userFollowIdParse = followingRequestUserId
      ? JSON.parse(followingRequestUserId)
      : null;

    if (userFollowIdParse) {
      let followingRequestDto: AddFollowingRequestDto = {
        requestOwnerToken: token.token!,
      };
      followingRequestDto.userToFollowId = userFollowIdParse;
      followingRequestDto.groupToFollowId = null;

      requestClient
        .followingRequest_Create_Request(followingRequestDto)
        .catch((error) => {
          setApiError(error);
        });

      localStorage.removeItem("followingRequestUserId");
    }
  }

  function followGroupAfterSignUp() {
    const followingRequestGroupId = localStorage.getItem(
      "followingRequestGroupId"
    );
    const groupFollowIdParse = followingRequestGroupId
      ? JSON.parse(followingRequestGroupId)
      : null;

    if (groupFollowIdParse) {
      let followingRequestDto: AddFollowingRequestDto = {
        requestOwnerToken: token.token!,
      };
      followingRequestDto.groupToFollowId = groupFollowIdParse;
      followingRequestDto.userToFollowId = null;

      requestClient
        .followingRequest_Create_Request(followingRequestDto)
        .catch((error) => {
          setApiError(error);
        });
      localStorage.removeItem("followingRequestGroupId");
    }
  }

  return (
    <Item
      sx={{
        justifyContent: "center",
        margin: "0 auto",
        mt: "80px",
        height: "100%",
      }}
      spacing={4}
    >
      {loading ? (
        <Box sx={{ margin: "0 auto" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack
            alignItems="flex-start"
            justifyContent="center"
            padding="0 18px"
          >
            <Stack sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 32,
                  fontWeight: 700,
                  color: "#060A25",
                }}
              >
                Home
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  overflow: "hidden",
                }}
              >
                <Divider
                  sx={{
                    backgroundColor: "#45B08A",
                    height: "2px",
                    width: "135px",
                  }}
                ></Divider>
                <Divider
                  sx={{
                    backgroundColor: "#E8E8E8",
                    height: "2px",
                    width: "100%",
                  }}
                ></Divider>
              </Stack>
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: "30px",
              }}
            >
              <Box>
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#656565",
                    lineHeigh: "20.68px",
                  }}
                >
                  Current Account Balance
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 26,
                    fontWeight: 700,
                    color: "#191919",
                    lineHeigh: "29.87px",
                  }}
                >
                  {currency_format(accountBalance)}
                </Typography>
              </Box>
              <Box sx={{ ml: "2rem" }}>
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#656565",
                    lineHeigh: "20.68px",
                  }}
                >
                  Total amount You've Invested
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 26,
                    fontWeight: 700,
                    color: "#191919",
                    lineHeigh: "29.87px",
                  }}
                >
                  {currency_format(totalInvestmentAmount)}
                </Typography>
              </Box>
              <Box sx={{ ml: "2rem" }}>
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#656565",
                    lineHeigh: "20.68px",
                  }}
                >
                  Number of Investments
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 26,
                    fontWeight: 700,
                    color: "#191919",
                    lineHeigh: "29.87px",
                  }}
                >
                  {numberOfInvestments}
                </Typography>
              </Box>
            </Box>
            {show && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: "30px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontWeight: 700,
                      fontSize: "26px",
                      lineHeight: "29.87px",
                    }}
                  >
                    Investments Your Network is Making
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: "30px",
                    width: "100%",
                  }}
                >
                  <CampainsGrid
                    isForGroup={false}
                    filterItem="network"
                    filterValue=""
                    setShow={setShow}
                  ></CampainsGrid>
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: "30px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 700,
                  fontSize: "26px",
                  lineHeight: "29.87px",
                }}
              >
                Trending Opportunities
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: "30px",
              }}
            >
              <CampainsGrid
                isForGroup={false}
                filterItem="trending"
                filterValue=""
              ></CampainsGrid>
            </Box>
          </Stack>
          <Stack width={"100%"}>
            <Typography
              sx={{
                fontStyle: "italic",
                fontFamily: "Ubuntu",
                fontSize: 10,
                fontWeight: 400,
                color: "#474747",
                p: "10px 18px",
              }}
            >
              <span style={{ fontWeight: 700, textDecoration: "underline" }}>
                Important Note:
              </span>
              &nbsp; Donations to CataCap donor accounts become part of the
              501c3 public charity Impactree Foundation’s (IF’s) CataCap donor
              advised fund (DAF) at ImpactAssets (IA, also a 501c3 public
              charity), and contributions do not involve a purchase or sale of
              securities and represent an irrevocable tax-deductible donation.
              Any information about investments within CataCap is not for
              attribution, publication or dissemination, and is not for personal
              use outside of CataCap donor accounts. CataCap and IF are
              unrelated to IA other than in their use of an IA DAF for holding
              donated assets for the purpose of impact investing and
              grantmaking, and such use should not be taken to indicate that IA
              is in any way involved with CataCap and IF other than as a DAF
              sponsoring organization.
            </Typography>
          </Stack>
        </>
      )}
    </Item>
  );
};

export default HomeLoggedIn;
