import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";

const Item = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginBottom: "24px",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const ItemContent = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginBottom: "24px",
  },
}));

const ItemButton = styled(Button)(({ theme }) => ({
  padding: "16px 20px",
  textTransform: "capitalize",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "120%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

interface UserToFollowProps {
  status: string;
  picture: string;
  fullName: string;
  requestFunc: any;
  isGroup: boolean;
  identifier: string;
  description: string;
}

const ItemToFollow = (props: UserToFollowProps) => {
  return (
    <Item container justifyContent="space-between" alignItems="center">
      <ItemContent item md={9} height={"100%"}>
        {/* <Link to={props.isGroup ? `/group/${props.id}` : `/user`} style={{textDecoration: 'none'}}>  */}
        {
          props.isGroup ? (
            <Link
              to={`/group/${props.identifier}`}
              style={{ textDecoration: "none" }}
            >
              <Stack
                alignItems="center"
                justifyContent="flex-start"
                height={"100%"}
                spacing={1}
                direction={"row"}
                gap={2}
              >
                <Avatar
                  variant="rounded"
                  src={props.picture || ""}
                  sx={{ width: 64, height: 64 }}
                ></Avatar>
                <Box sx={{ marginLeft: "0 !important" }}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontFamily: "Ubuntu",
                      fontSize: 18,
                      fontWeight: 700,
                      color: "#191919",
                      marginBottom: "8px",
                    }}
                  >
                    {props.fullName}
                  </Typography>
                </Box>
              </Stack>
            </Link>
          ) : (
            //<Link to={`/user/${props.id}`} style={{textDecoration: 'none'}}>
            <Stack
              alignItems="center"
              justifyContent="flex-start"
              height={"100%"}
              spacing={1}
              direction={"row"}
              gap={2}
            >
              <Avatar
                variant="rounded"
                src={props.picture || ""}
                sx={{ width: 64, height: 64 }}
              ></Avatar>
              <Box sx={{ marginLeft: "0 !important" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 18,
                    fontWeight: 700,
                    color: "#191919",
                    marginBottom: "8px",
                  }}
                >
                  {props.fullName}
                </Typography>
              </Box>
            </Stack>
          )
          //</Link>
        }
        {/* </Link> */}
      </ItemContent>
      <ItemButton
        sx={{
          background: props.status === "follow" ? "#2575FC" : "transparent",
        }}
        onClick={props.requestFunc}
        variant={props.status === "follow" ? "contained" : "outlined"}
        color="primary"
      >
        {props.status === "follow" ? "Follow" : "Unfollow"}
      </ItemButton>
    </Item>
  );
};

export default ItemToFollow;
