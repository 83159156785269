import React from "react";
import {
  Box,
  Select,
  MenuItem,
  Pagination as MuiPagination,
  SelectChangeEvent,
} from "@mui/material";

interface PaginationProps {
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
  itemsPerPageOptions: number[];
  onPageChange: (page: number) => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  itemsPerPage,
  totalPages,
  itemsPerPageOptions,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    onPageChange(value);
  };

  const handleItemsPerPageChange = (event: SelectChangeEvent<number>) => {
    const newItemsPerPage = Number(event.target.value);
    onItemsPerPageChange(newItemsPerPage);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
      <MuiPagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
      />
      <Select value={itemsPerPage} onChange={handleItemsPerPageChange}>
        {itemsPerPageOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default Pagination;
