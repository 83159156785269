import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import ContentTitle from "../../../components/ContentTitle";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  SendEmailClient,
  SendEmailDto,
  TokenDto,
} from "../../../utilities/backend/client";
import { RootState } from "../../../utilities/redux/store";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Stack1 = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const FormControl1 = styled(FormControl)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    marginLeft: "18px !important",
  },
}));

const variantCatacapEmailValues = [
  "Schwab Charitable",
  "Fidelity Giving Account",
  "SF Foundation",
  "Marin Community Foundation",
  "Silicon Valley Foundation",
  "The Boston Foundation",
  "National Philanthropic Trust",
  "Vanguard Charitable",
  "Other",
];

const DAFOrFundInvest = () => {
  const [variant, setVariant] = useState("");
  const [dafOrFoundationName, setDafOrFoundationName] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const token: TokenDto = {
    token: useSelector((state: RootState) => state.token.token) || "",
  };
  const sendEmailClient = new SendEmailClient();

  const handleChange = (event: SelectChangeEvent) => {
    setVariant(event.target.value as string);
    setEmailSent(false);
  };

  const handleModalOpen = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setModalOpen(true);
    return false;
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleClick = () => {
    if (variantCatacapEmailValues.includes(variant)) {
      const sendEmailDto: SendEmailDto = {
        userToken: token.token!,
        variant: variant,
        dafOrFoundationName: dafOrFoundationName,
      };
      sendEmailClient.sendEmail(sendEmailDto);
      setEmailSent(true);

      return;
    }

    const email = "tfreundlich@impactassets.org";
    const cc = "support@impactree.org";
    const subject = "Transfer Request from my IA DAF to CataCap IA DAF #439888";
    const body =
      "Please transfer $XXXXX from my Impact Assets DAF to the CataCap IA DAF account #439888.";
    const encodedSubject = encodeURIComponent(subject);

    const mailtoLink = `mailto:${email}?cc=${cc}&subject=${encodedSubject}&body=${body}`;

    window.open(mailtoLink, "_blank");
    setEmailSent(true);
  };

  return (
    <>
      {!emailSent ? (
        <Stack1
          sx={{
            justifyContent: "center",
            margin: "auto",
            paddingTop: "40px",
          }}
          width={600}
          spacing={4}
        >
          <ContentTitle
            title="Fund your CataCap Account"
            description="Please tell us where your DAF of Foundation is housed."
          />
          <FormControl1>
            <Select
              id="demo-simple-select"
              value={variant}
              onChange={handleChange}
            >
              <MenuItem value={"Impact Assets"}>Impact Assets</MenuItem>
              <MenuItem value={"Schwab Charitable"}>Schwab Charitable</MenuItem>
              <MenuItem value={"Fidelity Giving Account"}>
                Fidelity Giving Account
              </MenuItem>
              <MenuItem value={"SF Foundation"}>SF Foundation</MenuItem>
              <MenuItem value={"Marin Community Foundation"}>
                Marin Community Foundation
              </MenuItem>
              <MenuItem value={"Silicon Valley Foundation"}>
                Silicon Valley Foundation
              </MenuItem>
              <MenuItem value={"The Boston Foundation"}>
                The Boston Foundation
              </MenuItem>
              <MenuItem value={"National Philanthropic Trust"}>
                National Philanthropic Trust
              </MenuItem>
              <MenuItem value={"Vanguard Charitable"}>
                Vanguard Charitable
              </MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl1>
          {variant === "" ? (
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 18,
                  fontWeight: 400,
                  color: "#474747",
                  lineHeight: "20.68px",
                  mt: "15px",
                }}
                gutterBottom
              >
                Choose Source of Funding
              </Typography>
            </Stack>
          ) : variant === "Impact Assets" ? (
            <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 18,
                  fontWeight: 400,
                  color: "#474747",
                  lineHeight: "20.68px",
                  mt: "15px",
                }}
                gutterBottom
              >
                It’s simple to fund your account from your ImpactAssets DAF-
                just click the button below to send an email to ImpactAssets to
                complete your request.
              </Typography>
              <Button
                variant="contained"
                sx={{ width: "546px" }}
                color="primary"
                onClick={handleClick}
              >
                Send Mail
              </Button>
            </Stack>
          ) : (
            <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
              <Grid
                item
                xs={12}
                width={"100%"}
                sx={{ padding: "0 !important" }}
              >
                <Stack
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  width={"100%"}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 18,
                        fontWeight: 400,
                        color: "#656565",
                        lineHeight: "20.68px",
                        marginBottom: "8px",
                      }}
                    >
                      Name of your DAF or Foundation
                    </Typography>
                    <TextField
                      fullWidth
                      type="text"
                      placeholder="Type here"
                      value={dafOrFoundationName}
                      onChange={(e) => {
                        const maxLength = 50;
                        const value = e.target.value;
                        if (value.length <= maxLength) {
                          setDafOrFoundationName(value);
                        }
                      }}
                    />
                  </FormControl>
                </Stack>
              </Grid>
              <Stack
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
              >
                <Typography
                  width={"100%"}
                  align="left"
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 18,
                    fontWeight: 700,
                    color: "#474747",
                    lineHeight: "20.68px",
                    mt: "15px",
                  }}
                  gutterBottom
                >
                  It’s simple to fund your account and begin investing with
                  purpose.
                </Typography>
                <Stack
                  width={"100%"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  spacing={0}
                >
                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                    gutterBottom
                  >
                    1. Login to your DAF website and initiate a grant
                  </Typography>
                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                    gutterBottom
                  >
                    2. Make grant to Impactree Foundation (EIN # 86-2370923)
                  </Typography>
                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                    gutterBottom
                  >
                    3. Designate the funds to the CataCap Program, and provide
                    your full name and email (Please do not grant anonymously or
                    we will not be able to associate your grant funds to your
                    CataCap Account)
                  </Typography>
                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                    gutterBottom
                  >
                    4. Review the &nbsp;
                    <a onClick={handleModalOpen} href="#">
                      donation terms and conditions
                    </a>
                  </Typography>

                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                    gutterBottom
                  >
                    5. Submit Grant
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
              >
                <Button
                  disabled={!dafOrFoundationName}
                  variant="contained"
                  sx={{ width: "546px" }}
                  color="primary"
                  onClick={handleClick}
                >
                  I Did It
                </Button>
                <Typography
                  width={"100%"}
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 18,
                    fontWeight: 700,
                    color: "#474747",
                    lineHeight: "20.68px",
                    mt: "15px",
                  }}
                  gutterBottom
                >
                  You will be notified by email as soon as your grant is
                  received and your funds have been added to your CataCap
                  account.
                </Typography>
                <Stack
                  width={"100%"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  spacing={0}
                >
                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 18,
                      fontWeight: 700,
                      color: "#474747",
                    }}
                    gutterBottom
                  >
                    Impactree Foundation Information:
                  </Typography>
                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                    gutterBottom
                  >
                    EIN# 86-2370923
                  </Typography>
                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                    gutterBottom
                  >
                    Address: 3749 BUCHANAN ST UNIT 475207 , San Francisco, CA
                    94147
                  </Typography>
                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                    gutterBottom
                  >
                    Website:{" "}
                    <a
                      href="https://www.catacap.org/catacap/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      catacap.org
                    </a>
                  </Typography>
                  <Typography
                    width={"100%"}
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                    gutterBottom
                  >
                    Contact: Ken Kurtzig ,{" "}
                    <a
                      href="mailto:ken@catacap.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ken@catacap.org
                    </a>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack1>
      ) : (
        <Stack1
          sx={{
            justifyContent: "center",
            margin: "auto",
            paddingTop: "80px",
          }}
          width={600}
          spacing={4}
        >
          <Stack
            justifyContent={"flex-start"}
            alignItems={"center"}
            spacing={2}
          >
            <Typography
              width={"100%"}
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 18,
                fontWeight: 700,
                color: "#474747",
                lineHeight: "20.68px",
              }}
              gutterBottom
            >
              Thank you for adding funds to your CataCap Account. You will
              receive an email as soon as the funds have arrived from your DAF
              or Foundation and added to your CataCap Account
            </Typography>
          </Stack>
        </Stack1>
      )}

      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={modalOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Donation Terms & Conditions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            By making a contribution to a CataCap donor account, you acknowledge
            and accept the following terms and conditions.
          </Typography>
          <Typography
            gutterBottom
            sx={{
              mt: "15px",
            }}
          >
            You acknowledge and accept that donor accounts in CataCap are
            donations to Impactree Foundation (IF), a 501(c)(3) public charity.
            IF is the donor advisor of CataCap DAF at ImpactAssets (IA), also a
            501(c)(3) public charity and a DAF sponsor. Contributions to CataCap
            accounts do not involve a purchase or sale of securities. Certain
            donor information may be transmitted between IA and IF.
          </Typography>

          <Typography
            gutterBottom
            sx={{
              mt: "15px",
            }}
          >
            You further understand all the following Terms & Conditions: A
            contribution to fund a donor account within CataCap represents an
            irrevocable donation to IF. Contributions are nonrefundable, and
            become subject to IF’s control and sole discretion, as are final
            determinations on all grant and investment recommendations. Final
            determinations on all grant and investment recommendations at
            CataCap DAF are subject to IA’s control and sole discretion. You are
            aware that there are a number of factors to consider when assessing
            the tax implications of gifts to charity of cash, as well as the
            transfer of appreciated securities or other assets (and that CataCap
            recommends that you consult with a tax specialist before making any
            donations).
          </Typography>

          <Typography
            gutterBottom
            sx={{
              mt: "15px",
            }}
          >
            <span style={{ fontWeight: 700, textDecoration: "underline" }}>
              Expenses:
            </span>
            &nbsp; Costs related to CataCap donor accounts are as follows, and
            may be subject to change on a going forward basis:
          </Typography>
          <Typography
            gutterBottom
            sx={{
              m: "15px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
            <div style={{ flex: "1" }}>
              The administration fee assessed from each donor contribution is
              1.25%, but fees for the first four years of administration (in
              aggregate, 5% of the donated amount) are assessed on a forward
              basis at the time of donation. This fee covers all CataCap donor
              account and CataCap DAF expenses on the donated amount during the
              term.
            </div>
          </Typography>
          <Typography
            gutterBottom
            sx={{
              m: "15px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
            <div style={{ flex: "1" }}>
              All credit card donations will be charged a transaction fee of ~4%
              by Donorbox.
            </div>
          </Typography>
          <Typography
            gutterBottom
            sx={{
              m: "15px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
            <div style={{ flex: "1" }}>
              All ACH payments incur a fee of 2.55% fee up to $25 max. Bank wire
              fees will be deducted at cost.
            </div>
          </Typography>
          <Typography
            gutterBottom
            sx={{
              m: "15px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
            <div style={{ flex: "1" }}>
              If at any time an equity investment is written up in value, equity
              upside is distributed or interest is credited back to a donor
              account, that amount will have an administrative fee assessed as
              if it were a new contribution (but principal and capital recovery
              will not incur further fees). Such events do not create a new tax
              deduction.
            </div>
          </Typography>
          <Typography
            gutterBottom
            sx={{
              m: "15px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
            <div style={{ flex: "1" }}>
              After four years from a given contribution’s date, an ongoing
              annual fee of 1.25% will be assessed on that contribution’s
              remaining balance in the donor account and debited from available
              Impact Liquidity Balances in the manner in practice and disclosed
              at that date. If an Impact Liquidity Balance is not sufficient to
              cover the annual fee, it will create a negative balance until such
              time as liquidity is available to satisfy the balance (see Terms
              and Conditions “Negative Impact Liquidity Balances” discussion).
            </div>
          </Typography>
          <Typography
            gutterBottom
            sx={{
              m: "15px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
            <div style={{ flex: "1" }}>
              Once deducted from a donor account, fees will not be refunded.
            </div>
          </Typography>
          <Typography
            gutterBottom
            sx={{
              fontStyle: "italic",
              fontFamily: "Ubuntu",
              fontWeight: 400,
              mt: "15px",
            }}
          >
            You will receive a tax deduction for the full value of your donation
            (including fees), but the value attributed to your donor account
            will be net of fees.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose}>
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default DAFOrFundInvest;
