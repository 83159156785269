import { Community } from './Community';
import { Dialog } from './Dialog';
import { Home } from './Home';
import { Logout } from './Logout';
import { Money } from './Money';
import { Ticket } from './Ticket';
import { User } from './User';
import { Comm } from './Comm';
import { MyPortfolio } from './MyPortfolio'
import { Find } from './Find'
export const SVG = {
  Logout,
  Community,
  Dialog,
  Home,
  Money,
  Ticket,
  User,
  Comm,
  MyPortfolio,
  Find
}