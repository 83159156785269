import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import PrivacyPolicy from "../PrivacyPolicy";
import TermsAndConditions from "../TermsAndConditions";
import OnlyIfNotAuthenticated from "../../../state/currentUser/guards/OnlyIfNotAuthenticated";
import OnlyIfAuthenticated from "../../../state/currentUser/guards/OnlyIfAuthenticated";
import { useSelector } from "react-redux";
import { RootState } from "../../../utilities/redux/store";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const FooterContainer = styled(Stack)(({ theme }) => ({
  borderTop: "3px solid #D9DBE9",
  height: "80px",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  maxWidth: "1280px",
  margin: "40px auto 0",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    m: 0,
    alignItems: "start",
    height: "auto",
    margin: "0 18px",
    width: "calc(100% - 36px)",
    padding: "24px 0 36px",
  },
}));

const FooterNav = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  marginTop: "0 !important",
  p: {
    margin: "0 !important",
  },
  a: {
    margin: "0 !important",
  },
  gap: "15px",
  [theme.breakpoints.down("sm")]: {
    gap: 0,
    flexWrap: "wrap",
    p: {
      "&:first-child": {
        flex: "100% 0 0",
      },
    },
  },
}));

const FooterTextItem = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 16,
  fontWeight: 400,
  color: "#656565",
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    paddingBottom: "24px !important",
  },
}));

const FooterLinkTextItem = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 16,
  fontWeight: 400,
  color: "#2575FC",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "24px",
  },
}));

const AboutLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  marginTop: "0 !important",
}));

const Separator = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 16,
  fontWeight: 400,
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const Footer = () => {
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const token = useSelector((state: RootState) => state.token);
  const navigate = useNavigate();

  const handletModalOpen = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setPrivacyModalOpen(true);
    return false;
  };

  const handleModalOpen = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setTermsModalOpen(true);
    return false;
  };
  const handleModalClose = () => {
    setTermsModalOpen(false);
    setPrivacyModalOpen(false);
  };

  const handleAboutClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (token && token.token) {
      navigate("/aboutus");
    } else {
      window.location.href = "https://www.catacap.org/about-catacap/";
    }
  };

  return (
    <FooterContainer spacing={35}>
      <FooterTextItem gutterBottom>
        Copyright © {new Date().getFullYear()} Impact
      </FooterTextItem>
      <AboutLink onClick={handleAboutClick} to={"/"}>
        <FooterLinkTextItem gutterBottom>About Us</FooterLinkTextItem>
      </AboutLink>
      <FooterNav spacing={2}>
        <FooterTextItem gutterBottom>All Rights Reserved</FooterTextItem>
        <Link
          onClick={handletModalOpen}
          to={"/"}
          style={{ textDecoration: "none" }}
        >
          <FooterLinkTextItem gutterBottom>
            Terms And Conditions
          </FooterLinkTextItem>
        </Link>
        <Separator gutterBottom>|</Separator>
        <Link
          onClick={handleModalOpen}
          to={"/"}
          style={{ textDecoration: "none" }}
        >
          <FooterLinkTextItem gutterBottom>Privacy Policy</FooterLinkTextItem>
        </Link>
      </FooterNav>
      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={termsModalOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Privacy Policy
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <PrivacyPolicy />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose}>
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={privacyModalOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Terms and Conditions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TermsAndConditions />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose}>
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </FooterContainer>
  );
};

export default Footer;
