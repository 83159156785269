import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";

interface RemoveProps {
  deleteFunc: Function;
  picture: string;
  fullName: string;
}

const RemoveData = (props: RemoveProps) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid
        container
        width={"100%"}
        justifyContent="space-between"
        sx={{ marginBottom: "24px !important" }}
      >
        <Stack
          alignItems="center"
          justifyContent="flex-start"
          height={"100%"}
          spacing={1}
          direction={"row"}
        >
          <Avatar
            variant="rounded"
            src={props.picture || ""}
            sx={{ width: 64, height: 64 }}
          ></Avatar>
          <Typography
            sx={{
              width: "100%",
              fontFamily: "Ubuntu",
              fontSize: 18,
              fontWeight: 700,
              color: "#191919",
              marginLeft: "12px !important",
            }}
          >
            {props.fullName}
          </Typography>
        </Stack>
        <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Button
            variant="contained"
            color="error"
            sx={{
              padding: "16px 24px",
              lineHeight: "120%",
              textTransform: "capitalize",
              fontWeight: 500,
            }}
            onClick={() => handleClickOpenDialog()}
          >
            Remove
          </Button>
        </Box>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Do you want to remove member?
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button
            onClick={() => {
              props.deleteFunc();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveData;
