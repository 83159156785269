export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const SET_ROLE = 'SET_ROLE';

export interface SetTokenAction {
	type: typeof SET_TOKEN;
	payload: string;
}

export interface SetRoleAction {
	type: typeof SET_ROLE;
	payload: string;
}

export interface RemoveTokenAction {
	type: typeof REMOVE_TOKEN;
}

export type TokenActionTypes = SetTokenAction | RemoveTokenAction;
export type RoleActionTypes = SetRoleAction;

export const setToken = (token: string): SetTokenAction => ({
	type: SET_TOKEN,
	payload: token,
});

export const setRole = (role: string): SetRoleAction => ({
	type: SET_ROLE,
	payload: role,
});

export const removeToken = (): RemoveTokenAction => ({
	type: REMOVE_TOKEN,
});
