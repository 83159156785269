import { legacy_createStore, combineReducers, applyMiddleware, compose } from 'redux';
import tokenReducer from './reducers/token.reducer';
import roleReducer from './reducers/role.reducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
	key: 'root',
	storage,
}

const rootReducer = combineReducers({
	token: tokenReducer,
	role: roleReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RootState = ReturnType<typeof rootReducer>;

const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = legacy_createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware())
);

export const persistor = persistStore(store)