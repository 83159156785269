import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { UserLoginDto, AuthClient } from "../../utilities/backend/client";
import { DevTool } from "@hookform/devtools";
import { useDispatch } from "react-redux";
import { setToken, removeToken, setRole } from "../../utilities/redux/actions";
import ContentTitle from "../../components/ContentTitle";

const Login = () => {
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const authClient = new AuthClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateToRegister = () => {
    navigate("/register");
  };

  const form = useForm<UserLoginDto>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { register, handleSubmit, formState, control } = form;
  const { errors } = formState;

  const redirectUrl = localStorage.getItem("redirectUrl");

  const onSubmit = (data: UserLoginDto) => {
    setLoading(true);
    authClient
      .auth_Authenticate(data)
      .then(async (response) => {
        const token = await response?.data.text();
        if (token !== undefined) {
          dispatch(setRole(JSON.parse(atob(token.split(".")[1])).role));
          dispatch(setToken(JSON.parse(token).token));
        } else {
          dispatch(removeToken());
        }
        if (redirectUrl) {
          localStorage.removeItem("redirectUrl");

          navigate(redirectUrl);
        } else {
          navigate("/home");
        }
      })
      .catch((error) => {
        setValidationMessage(
          "Login failed due to incorrect data. Please, try again..."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "100px auto",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "50px auto",
          }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Stack
              spacing={2}
              width={400}
              alignItems="center"
              justifyContent="center"
              sx={{ ml: "5%", mr: "5%" }}
            >
              <ContentTitle
                title="Welcome Back"
                description="Login to continue"
              />
              <Divider variant="middle" flexItem>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 18,
                    fontWeight: 600,
                    color: "#060A25",
                  }}
                >
                  Login with email
                </Typography>
              </Divider>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Email Address or User Name
              </Typography>
              <TextField
                sx={{ width: "100%" }}
                type="text"
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register("email", { required: "Email is required" })}
              ></TextField>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Password
              </Typography>
              <TextField
                sx={{ width: "100%" }}
                type="password"
                error={!!errors.password}
                helperText={errors.password?.message}
                {...register("password", { required: "Password is required" })}
              ></TextField>
              <Box sx={{ width: "100%" }}>
                <Link to={"/forgotpassword"} style={{ textDecoration: "none" }}>
                  <Typography
                    align="right"
                    gutterBottom
                    sx={{ fontFamily: "Ubuntu", fontSize: 14, fontWeight: 400 }}
                  >
                    Forgot password?
                  </Typography>
                </Link>
              </Box>
              {validationMessage !== "" && (
                <Typography color="error">{validationMessage}</Typography>
              )}
              <Button
                variant="contained"
                type="submit"
                color="primary"
                sx={{ width: "100%" }}
              >
                Login
              </Button>
            </Stack>
          </form>
          <DevTool control={control} />
        </Box>
      )}
    </Box>
  );
};

export default Login;
