import {
  Alert,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import {
  AddFollowingRequestDto,
  ApiException,
  FollowDataRequestDto,
  FollowingDataDto,
  FollowingRequestClient,
} from "../utilities/backend/client";
import { useSelector } from "react-redux";
import { RootState } from "../utilities/redux/store";
import ItemToFollow from "./ItemToFollow";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import useHandleApiError from "../utilities/backend/Auth/useHandleApiError";
import {
  defaultImage,
  getUrlBlobContainerImage,
} from "../resources/imageConstants";
import Pagination from "./Pagination";

const Search = styled(TextField)(({ theme }) => ({
  width: 410,
  paddingBottom: "48px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    paddingBottom: "40px",
  },
}));

const SelectedOptionButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "120%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ItemsToFollowGrid = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const followParam = searchParams.get("follow");

  const requestClient = new FollowingRequestClient();
  const token: string =
    useSelector((state: RootState) => state.token.token) || "";
  const [itemsToFollowList, setItemsToFollowList] = useState<
    FollowingDataDto[]
  >([]);
  const [requestChanger, setRequestChanger] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>("");
  const [apiError, setApiError] = useState<ApiException | null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedOption, setSelectedOption] = useState<boolean>(true);

  useHandleApiError(apiError);

  const fetchData = async () => {
    setLoading(true);
    const request: FollowDataRequestDto = {
      userToken: token,
      isFollowRequest: true,
      page: currentPage,
      pageSize: itemsPerPage,
      selectedOption: selectedOption,
    };

    requestClient
      .followingRequest_GetDataToFollow(request)
      .then((response) => {
        setItemsToFollowList(response.followingDataDto);
        const totalItems = response.totalItems;
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        setTotalPages(totalPages);

        setLoading(false);
      })
      .catch((error) => {
        setApiError(error);
      });

    if (followParam) {
      setSearch(followParam);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePaginationOption = (value: boolean) => {
    setSelectedOption(value);
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (itemsPerPage: number) => {
    setCurrentPage(1);
    setItemsPerPage(itemsPerPage);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, requestChanger, followParam, selectedOption]);

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  const handleSearchChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearch(event.target.value);
  };

  function filterList(value: string) {
    if (value === "") {
      return itemsToFollowList;
    } else {
      return itemsToFollowList.filter((item) =>
        item.name?.toLowerCase().startsWith(value)
      );
    }
  }

  function ItemsToFollowList() {
    const filter = filterList(search.toLowerCase());
    const campaignsList =
      filter?.length >= 1 ? (
        filter.map((item) => (
          <ItemToFollow
            description={item.description!}
            isGroup={item.isGroup!}
            identifier={item.identifier! || item.followingId!}
            status={"follow"}
            requestFunc={() => {
              followItem(item);
            }}
            fullName={item ? item.name! : ""}
            picture={
              item?.pictureFileName
                ? getUrlBlobContainerImage(item?.pictureFileName)
                : defaultImage
            }
            key={item.followingId}
          />
        ))
      ) : (
        <Typography
          sx={{
            fontFamily: "Ubuntu",
            fontSize: 18,
            fontWeight: 400,
            color: "#656565",
            lineHeigh: "20.68px",
          }}
        >
          No Users and Groups to follow
        </Typography>
      );
    return <>{campaignsList}</>;
  }

  function followItem(item: FollowingDataDto) {
    let data: AddFollowingRequestDto = {
      requestOwnerToken: token,
    };
    console.log("item", item);

    if (item.isGroup) {
      data.groupToFollowId = Number(item.followingId);
      data.userToFollowId = null;
    } else {
      data.userToFollowId = item.followingId;
      data.groupToFollowId = null;
    }

    requestClient
      .followingRequest_Create_Request(data)
      .then(() => {
        setRequestChanger(!requestChanger);
        setLoading(true);
        setNotificationText("You start following " + item.name);
        setNotificationShow(true);
      })
      .catch((error) => {
        setApiError(error);
      });
  }

  return (
    <>
      <Stack width={"100%"} sx={{ marginTop: "0 !important" }}>
        <div style={{ display: "flex" }}>
          <SelectedOptionButton
            style={{ marginRight: "10px" }}
            sx={{
              background: selectedOption ? "#2575FC" : "transparent",
            }}
            onClick={() => handlePaginationOption(true)}
            variant={selectedOption ? "contained" : "outlined"}
            color="primary"
          >
            Groups
          </SelectedOptionButton>
          <SelectedOptionButton
            sx={{
              background: !selectedOption ? "#2575FC" : "transparent",
            }}
            onClick={() => handlePaginationOption(false)}
            variant={!selectedOption ? "contained" : "outlined"}
            color="primary"
          >
            Users
          </SelectedOptionButton>
        </div>
        <Search
          style={{ marginTop: "24px" }}
          placeholder="Name, email, phone"
          type="text"
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {loading ? (
          <Box sx={{ margin: "0 auto" }}>
            <CircularProgress />
          </Box>
        ) : (
          <ItemsToFollowList />
        )}
      </Stack>

      <Snackbar
        open={notificationShow && notificationText?.length !== 0}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {notificationText}
        </Alert>
      </Snackbar>
      {!loading && itemsToFollowList?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalPages={totalPages!}
          itemsPerPageOptions={[10, 20, 50]}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default ItemsToFollowGrid;
