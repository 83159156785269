import { useState } from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import CategoryAdmin from "../../../components/admin/CategoryAdmin";
import CampaignsAdmin from "../../../components/admin/CampaignsAdmin";
import { useNavigate } from "react-router-dom";
import UsersAdmin from "../../../components/admin/UsersAdmin";
import RecommendationsAdmin from "../../../components/admin/RecommendationsAdmin";
import ApprovedByAdmin from "../../../components/admin/ApprovedByAdmin";
import AccountHistoryAdmin from "../../../components/admin/AccountHistoryAdmin";

const AdminApp = () => {
  const navigate = useNavigate();
  const [itemToMaintain, setItemToMaintain] = useState<string>("");

  const onApprovedByClick = () => {
    setItemToMaintain("approvedby");
  };

  const onCategoryClick = () => {
    setItemToMaintain("category");
  };

  const onInvestmentClick = () => {
    setItemToMaintain("investment");
  };

  const onUsersClick = () => {
    setItemToMaintain("users");
  };

  const onRecommendationsClick = () => {
    setItemToMaintain("recommendation");
  };

  const onAccountHistoryClick = () => {
    setItemToMaintain("accounthistory");
  };

  // UNCOMMENT TO TEST DONORBOX GETTING DATA
  // const onTest = async () => {
  //     var headers = new Headers({
  //         'Authorization': `Basic ${btoa('ken@impactree.org:xXQe1-WiLeIR04PPgY9cYZiNMETlc38Fq7ecfUI0eRmn80pcwZjK-A')}`,
  //         'Access-Control-Allow-Origin': '*',
  //             'Access-Control-Allow-Methods': 'GET, POST, PUT',
  //             'Access-Control-Allow-Headers': '*',
  //             'Origin': '*',
  //     });

  //     const response = await fetch('https://donorbox.org/api/v1/campaigns', {
  //         headers: headers,
  //       });
  //    console.log(await response.json())
  // }

  return (
    <div
      style={{
        display: "flex",
        margin: "20px",
        gap: "50px",
        flexDirection: "row",
      }}
    >
      <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <PrimaryButton click={onApprovedByClick} text="Sourced By" />
        <PrimaryButton click={onCategoryClick} text="Category" />
        <PrimaryButton click={onInvestmentClick} text="Investment" />
        <PrimaryButton click={onUsersClick} text="Users" />
        <PrimaryButton click={onRecommendationsClick} text="Recommendations" />
        <PrimaryButton click={onAccountHistoryClick} text="Account History" />
        {/* <PrimaryButton click={onTest} text="test" /> */}
        {itemToMaintain && itemToMaintain === "investment" && (
          <PrimaryButton
            click={() => navigate("/a-investment")}
            text="Create the new Investment"
          />
        )}
      </div>

      {itemToMaintain && itemToMaintain === "approvedby" && <ApprovedByAdmin />}
      {itemToMaintain && itemToMaintain === "category" && <CategoryAdmin />}
      {itemToMaintain && itemToMaintain === "investment" && <CampaignsAdmin />}
      {itemToMaintain && itemToMaintain === "users" && <UsersAdmin />}
      {itemToMaintain && itemToMaintain === "recommendation" && (
        <RecommendationsAdmin />
      )}
      {itemToMaintain && itemToMaintain === "accounthistory" && (
        <AccountHistoryAdmin />
      )}
    </div>
  );
};

export default AdminApp;
