import { Stack, Typography } from "@mui/material";

const InvestForChange = () => {
    return (
        <Stack sx={{justifyContent: "center", margin: "auto", paddingBottom: "30px", paddingTop: "70px"}} width={800} spacing={4}>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 400, color: "#474747", lineHeigh: '20.68px' }}>Donations are sub-accounts in the CataCap donor advised fund (“DAF”) at ImpactAssets (“IA”), a 501(c)(3) public charity, and do not represent a purchase or sale of securities. Impactree Foundation (“IF”), also a 501(c)(3) public charity, is the donor advisor of the CataCap DAF. A contribution to fund a donor account within the CataCap DAF represents an irrevocable tax-deductible contribution to IA. Contributions are nonrefundable, and become subject to IA’s control and sole discretion, as are final determinations on all grant distributions and investments, which are communicated to IA by IF. IA undertakes review and approval of all recommendations, executes transactions, and holds and values investments. If investments generate returns, CataCap allocates them back to the relevant CataCap donor sub-accounts. 3.75% of donations are deducted to cover three years of administrative expenses of the CataCap DAF, as are any credit card fees or other transaction costs from third parties. Foundation Alpha testers round up in total to 10% of donations, with the additional amount serving as an R&D grant to CataCap DAF. There are a number of factors to consider when assessing the tax implications of gifts to charity of cash, capital gains and the transfer of appreciated securities. Please consult with your tax specialist before making any donations.</Typography>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 400, color: "#474747", lineHeigh: '20.68px' }}>Your investment recommendation is made to the CataCap donor advised fund (“DAF”) at ImpactAssets (“IA”), a 501(c)(3) public charity, and does not represent a purchase or sale of securities. Impactree Foundation (“IF”), also a 501(c)(3) public charity, is the donor advisor of the CataCap DAF. Investment recommendations are subject to IA’s control and sole discretion, as are final determinations on all investments, which are communicated to IA by IF. CataCap will aggregate recommendations until minimum thresholds have been met. IA undertakes review and approval of all recommendations, executes transactions, and holds and values investments. If this investment generates returns, CataCap allocates them back to the your CataCap donor sub-account. </Typography>
        </Stack>
    );
};

export default InvestForChange;