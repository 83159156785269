import OnlyIfAuthenticated from "../../state/currentUser/guards/OnlyIfAuthenticated";
import OnlyIfNotAuthenticated from "../../state/currentUser/guards/OnlyIfNotAuthenticated";
import CampainsGrid from "../../components/CampainsGrid";
import useRole from "../../utilities/backend/Auth/useRole";
import AdminApp from "./Admin/AdminApp";
import {
  ApiException,
  AuthClient,
  CategoryClient,
  UsersClient,
} from "../../utilities/backend/client";
import { useEffect, useState } from "react";
import ContentTitle from "../../components/ContentTitle";
import { Box, Button, CircularProgress, Stack, styled } from "@mui/material";
import HowImage from "../../resources/howImage";
import PersonCard from "../../components/PersonCard";
import HomeLoggedIn from "./User/loggedIn/HomeLoggedIn";
import { setRole, setToken, removeToken } from "../../utilities/redux/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const StackRes1 = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  width: "1280px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const StackRes2 = styled(Stack)(({ theme }) => ({
  spacing: "6",
  alignItems: "center",
  justifyContent: "center",
  paddingBottom: "160px",
  flexDirection: "row",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const StackRes3 = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "41px",
  marginTop: "0 !important",
  paddingBottom: "160px",
  flexDirection: "row",
  gap: "100px",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    gap: "100px",
  },
}));

const Home2 = () => {
  const role = useRole();
  const categoryClient = new CategoryClient();
  const authClient = new AuthClient();
  const [apiError, setApiError] = useState<ApiException | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const userClient = new UsersClient();
  const userEmail = localStorage.getItem("email");
  const userPassword = localStorage.getItem("password");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedCategoriesIndex, setSelectedCategoriesIndex] = useState("");
  const [categoryList, setCategoryList] = useState<any[]>([]);

  useEffect(() => {
    categoryClient
      .category_GetAll()
      .then(async (response) => {
        const categories = JSON.parse(await response.data.text());
        categories.map((i: any) => (i.selected = true));
        const selectedI = categories
          .filter((i: any) => i.selected)
          .map((i: any) => i.id);
        console.log(selectedI);
        setSelectedCategoriesIndex(selectedI.join(","));
        setCategoryList(categories);
      })
      .catch((error) => {
        console.log(error);
      });

    userClient
      .users_UpdateActiveStatus(userEmail!, true)
      .then(() => {
        login();
        setLoading(false);
      })
      .catch((error) => {
        setApiError(error);
      });
  }, []);

  const login = () => {
    authClient
      .auth_Authenticate({ email: userEmail!, password: userPassword! })
      .then(async (response) => {
        const token = await response?.data.text();
        if (token !== undefined) {
          dispatch(setRole(JSON.parse(atob(token.split(".")[1])).role));
          dispatch(setToken(JSON.parse(token).token));
        } else {
          dispatch(removeToken());
        }
        navigate("/home2");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const selectedI = categoryList.filter((i) => i.selected).map((i) => i.id);
    setSelectedCategoriesIndex(selectedI.join(","));
  }, [categoryList]);

  return (
    <>
      {loading ? (
        <Stack justifyContent={"center"} alignItems={"center"} mt={"100px"}>
          <Box sx={{ margin: "0 auto" }}>
            <CircularProgress />
          </Box>
        </Stack>
      ) : (
        <OnlyIfAuthenticated
          children={role === "Admin" ? <AdminApp /> : <HomeLoggedIn />}
        />
      )}
      {/* <OnlyIfNotAuthenticated
        children={
          <> */}
      {/* <PrimaryButton click={() => console.log('Add handlder')} text={'test'}></PrimaryButton> */}
      {/* <div style={{ height: "75vh" }}>
              <div
                style={{
                  backgroundImage: "url(/home.png)",
                  backgroundSize: "cover",
                  height: "100%",
                  marginTop: "-71px",
                  backgroundPosition: "center top",
                }}
              ></div>
            </div> */}

      {/* <img src='/home.png' width={"100%"} style={{height: 'auto', }}/>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            > */}
      {/* <StackRes1 spacing={2}>
                <ContentTitle
                  style={{ marginBottom: 60 }}
                  maxWidth={620}
                  title="How it works?"
                  description="Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."
                />
                <StackRes2>
                  <Stack
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <HowImage />
                    <ContentTitle
                      titleFontSize={24}
                      descriptionFontSize={16}
                      title="Heading"
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <HowImage />
                    <ContentTitle
                      titleFontSize={24}
                      descriptionFontSize={16}
                      title="Heading"
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <HowImage />
                    <ContentTitle
                      titleFontSize={24}
                      descriptionFontSize={16}
                      title="Heading"
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <HowImage />
                    <ContentTitle
                      titleFontSize={24}
                      descriptionFontSize={16}
                      title="Heading"
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                    /> */}
      {/* </Stack>
                </StackRes2>
                <ContentTitle
                  title="Explore Our Featured Investments"
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip"
                  margin="0px"
                />
                <br />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  {categoryList &&
                    categoryList.map(
                      (i) =>
                        i.mandatory && (
                          <Button
                            onClick={() => handleButtonClick(i)}
                            sx={{
                              backgroundColor: i.selected ? "#45B08A" : "white",
                              width: "100%",
                              height: "51px",
                              fontSize: "16px",
                              fontWeight: "500",
                              fontFamily: "Ubuntu",
                              color: i.selected ? "#fff" : "black",
                              padding: "16px 24px",
                              borderRadius: "8px",
                              border: "1px solid #E8E8E8",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: i.selected
                                  ? "#45B08A"
                                  : "white",
                                color: i.selected ? "#fff" : "black",
                                border: "1px solid #E8E8E8",
                                // border: '2px solid #2575FC'
                              },
                            }}
                            key={i.id}
                          >
                            {i.name}
                          </Button>
                        )
                    )}
                </div>
                {categoryList && categoryList.length > 0 && (
                  <Stack
                    sx={{
                      mt: "30px !important",
                      justifyContent: "left",
                      width: "100%",
                    }}
                  >
                    <CampainsGrid
                      isForGroup={false}
                      filterItem="category"
                      filterValue={selectedCategoriesIndex}
                    ></CampainsGrid>
                  </Stack>
                )}
                <ContentTitle
                  style={{ marginBottom: 60 }}
                  maxWidth={764}
                  title="Testimonials"
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
                  margin="100px"
                />
                <StackRes3>
                  <PersonCard
                    backgroundColor="rgba(245, 212, 209, 0.4)"
                    desc="HEAD OF PR"
                    imageSrc="/avatar1.png"
                    name="Frances Wagner"
                    text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                  />
                  <PersonCard
                    backgroundColor="rgba(184, 229, 225, 0.4)"
                    desc="Creative Director"
                    imageSrc="/avatar2.png"
                    name="Maurice Smith"
                    text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                  />
                  <PersonCard
                    backgroundColor="rgba(238, 240, 242, 0.4)"
                    desc="Art Director"
                    imageSrc="/avatar3.png"
                    name="Matilda Ellis"
                    text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                  />
                </StackRes3> */}
      {/* </StackRes1>
            </div>
          </>
        }
      /> */}
    </>
  );
};

export default Home2;
