import { FC } from "react";
import {
  Box,
  Modal,
  TextField,
  Typography,
  styled,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { SVG } from "../../../icons";
import { Campaign } from "../../../utilities/backend/client";

interface ShareModalProps {
  open: boolean;
  handleClose(): void;
  campaign: Campaign;
}

const ModalContent = styled(Box)(({ theme }) => ({
  boxShadow: "24px",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 836,
  height: 663,
  borderRadius: 14,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#2575FC",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 64px)",
    height: "initial",
    padding: "60px 16px 73px",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontFamily: "Ubuntu",
  fontSize: 32,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#fff",
  marginBottom: 80,
  maxWidth: 650,
  [theme.breakpoints.down("sm")]: {
    fontSize: 22,
    marginBottom: 48,
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "120%",
  color: "#fff",
  textAlign: "center",
  marginBottom: 30,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 16,
  },
}));

const SocialMedia = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: 40,
  [theme.breakpoints.down("sm")]: {
    gap: 11,
  },
}));

const SocialMediaItem = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 58,
  height: 58,
  borderRadius: 8,
  background: "#fff",
  cursor: "pointer",
  svg: {
    color: "#45B08A",
    width: 26,
    height: 26,
  },
  [theme.breakpoints.down("sm")]: {
    width: 55,
    height: 55,
  },
}));

const CopyLinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  width: 451,
  margin: "0 auto 31px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "0 auto 48px",
  },
}));

const CopyLinkField = styled(TextField)(({ theme }) => ({
  width: 451,
  div: {
    borderRadius: 8,
    borderWidth: "0 !important",
  },
  input: {
    background: "#fff",
    borderRadius: 8,
    border: "1px solid #E8E8E8 !important",
    padding: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CloseButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: 25,
  right: 25,
  svg: {
    color: "#fff",
  },
  [theme.breakpoints.down("sm")]: {
    top: 10,
    right: 0,
  },
}));

const CopyButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: 12,
  right: 2,
}));

const socialItems = [
  {
    id: "email",
    Icon: SVG.Email,
    width: 24,
    height: 24,
    url: "https://www.gmail.com",
  },
  {
    id: "facebook",
    Icon: SVG.Facebook,
    width: 32,
    height: 32,
    url: "https://www.facebook.com/sharer/sharer.php?u=",
  },
  {
    id: "twitter",
    Icon: SVG.Twitter,
    width: 34,
    height: 34,
    url: "https://twitter.com/intent/tweet",
  },
  {
    id: "linkedIn",
    Icon: SVG.Linkedin,
    width: 32,
    height: 32,
    url: "https://www.linkedin.com",
  },
  {
    id: "messenger",
    Icon: SVG.Messenger,
    width: 32,
    height: 32,
    url: "https://www.facebook.com/sharer/sharer.php?u=",
  },
];

export const ShareModalMain = ({ campaign }: { campaign: Campaign | null }) => {
  const currentUrl = window.location.href;

  const copyHandler = async () => {
    try {
      await navigator.clipboard.writeText(currentUrl);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleSendEmail = () => {
    const cc = "support@impactree.org";
    const subject =
      "I thought you would  be interested in this impact investment.";
    const encodedSubject = encodeURIComponent(subject);

    const text1 =
      "Hey, I thought you would be interested in this impact investment I just found that you can make with a tax deductible donation.";
    const campaignName = `About ${campaign?.name}`;
    const campaignDescription = campaign?.description?.replace(/<[^>]*>/g, "");
    const text2 = "Investing from your DAF or Philantropic Money";
    const text3 =
      "It's easier than ever to use your Philantropic money or DAF for good through direct impact investments.";

    const identifier = campaign?.property
      ? `${campaign?.property}`
      : `${campaign?.id}`;

    const campainToUrl = `https:/app.catacap.org/invest/${identifier}`;
    const body = `${text1}%0D%0A%0D%0A${campaignName}%0D%0A%0D%0A${campaignDescription}%0D%0A%0D%0A${campainToUrl}%0D%0A%0D%0A${text2}%0D%0A%0D%0A${text3}`;

    const mailtoLink = `mailto:?cc=${cc}&subject=${encodedSubject}&body=${body}`;
    window.open(mailtoLink, "_blank");
  };

  return (
    <>
      <CopyLinkContainer>
        <CopyLinkField value={currentUrl} />
        <CopyButton onClick={copyHandler}>
          <SVG.Copy />
        </CopyButton>
      </CopyLinkContainer>
      <SocialMedia>
        {socialItems.map(({ Icon, id, width, height, url }) => {
          const shareLink =
            id === "messenger" || id === "messenger" ? url + currentUrl : url;

          return (
            <a
              href={id === "email" ? undefined : shareLink}
              key={id}
              target="_blank"
              rel="noreferrer"
              onClick={(event) => {
                if (id === "email") {
                  event.preventDefault();
                  handleSendEmail();
                }
              }}
            >
              <SocialMediaItem
                sx={{
                  svg: {
                    width,
                    height,
                  },
                }}
              >
                <Icon />
              </SocialMediaItem>
            </a>
          );
        })}
      </SocialMedia>
    </>
  );
};

export const ShareModal: FC<ShareModalProps> = ({
  open,
  handleClose,
  campaign,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContent
        sx={{ backgroundImage: "url(/share-bg.png)", backgroundSize: "cover" }}
      >
        <CloseButton onClick={handleClose}>
          <Close />
        </CloseButton>
        <div>
          <Title>
            Help {campaign.name} get the funding they need to drive real change.
          </Title>
          <SubTitle>Spread the word!</SubTitle>
          <ShareModalMain campaign={campaign} />
        </div>
      </ModalContent>
    </Modal>
  );
};
