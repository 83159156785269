export const Linkedin = () => (
<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="linkedin 1">
<g id="Layer 2">
<g id="linkedin">
<g id="icon">
<path id="Vector" d="M12.1948 8.72727C12.1948 9.26668 12.0348 9.79397 11.7352 10.2425C11.4355 10.691 11.0095 11.0405 10.5112 11.2469C10.0128 11.4534 9.46448 11.5074 8.93544 11.4021C8.4064 11.2969 7.92045 11.0372 7.53904 10.6557C7.15762 10.2743 6.89787 9.78838 6.79264 9.25934C6.68741 8.7303 6.74142 8.18193 6.94784 7.68359C7.15426 7.18525 7.50382 6.7593 7.95232 6.45963C8.40081 6.15995 8.92811 6 9.46751 6C10.1908 6 10.8845 6.28734 11.396 6.7988C11.9074 7.31026 12.1948 8.00395 12.1948 8.72727Z" fill="#45B08A"/>
<path id="Vector_2" d="M11.5751 13.274V25.3231C11.5755 25.412 11.5585 25.5001 11.5248 25.5824C11.4911 25.6646 11.4415 25.7394 11.3788 25.8024C11.3161 25.8655 11.2416 25.9155 11.1595 25.9496C11.0774 25.9837 10.9894 26.0013 10.9005 26.0013H8.02598C7.93709 26.0015 7.84903 25.9842 7.76686 25.9503C7.68469 25.9164 7.61003 25.8666 7.54718 25.8037C7.48433 25.7409 7.43451 25.6662 7.40061 25.584C7.3667 25.5019 7.34937 25.4138 7.34961 25.3249V13.274C7.34961 13.0946 7.42087 12.9226 7.54771 12.7958C7.67456 12.6689 7.84659 12.5977 8.02598 12.5977H10.9005C11.0796 12.5981 11.2512 12.6696 11.3776 12.7964C11.5041 12.9232 11.5751 13.095 11.5751 13.274Z" fill="#45B08A"/>
<path id="Vector_3" d="M26.6042 19.5436V25.3764C26.6045 25.4581 26.5886 25.539 26.5574 25.6146C26.5262 25.6901 26.4804 25.7588 26.4227 25.8166C26.3649 25.8744 26.2962 25.9202 26.2207 25.9513C26.1451 25.9825 26.0642 25.9984 25.9824 25.9982H22.8915C22.8098 25.9984 22.7288 25.9825 22.6533 25.9513C22.5777 25.9202 22.5091 25.8744 22.4513 25.8166C22.3935 25.7588 22.3477 25.6901 22.3165 25.6146C22.2854 25.539 22.2695 25.4581 22.2697 25.3764V19.7236C22.2697 18.88 22.517 16.0291 20.0642 16.0291C18.1642 16.0291 17.777 17.98 17.7006 18.8564V25.3764C17.7006 25.5397 17.6364 25.6965 17.5217 25.8129C17.4071 25.9292 17.2512 25.9958 17.0879 25.9982H14.1024C14.0209 25.9982 13.9401 25.9821 13.8647 25.9508C13.7894 25.9195 13.721 25.8737 13.6634 25.8159C13.6058 25.7582 13.5601 25.6896 13.5291 25.6142C13.498 25.5387 13.4822 25.4579 13.4824 25.3764V13.2182C13.4822 13.1366 13.498 13.0558 13.5291 12.9803C13.5601 12.9049 13.6058 12.8364 13.6634 12.7786C13.721 12.7208 13.7894 12.675 13.8647 12.6437C13.9401 12.6124 14.0209 12.5963 14.1024 12.5963H17.0879C17.2528 12.5963 17.411 12.6619 17.5276 12.7785C17.6442 12.8951 17.7097 13.0533 17.7097 13.2182V14.2691C18.4152 13.2109 19.4606 12.3945 21.6915 12.3945C26.6333 12.3945 26.6042 17.0091 26.6042 19.5436Z" fill="#45B08A"/>
</g>
</g>
</g>
</g>
</svg>
)