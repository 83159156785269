import { RoleActionTypes, SET_ROLE } from '../actions';


interface RoleState {
	role: string | null;
}

const initialRoleState: RoleState = {
	role: null,
};

const roleReducer = (state = initialRoleState, action: RoleActionTypes): RoleState => {
	switch (action.type) {
		case SET_ROLE:
			return {
				...state,
				role: action.payload,
			};
		default:
			return state;
	}
};

export default roleReducer;