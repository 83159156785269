import { Alert, Box, Button, Grid, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentTitle from "../../components/ContentTitle";
import { SetStateAction, useState } from "react";
import { AuthClient, EmailReceiveDto, ResetCodeDto, ResetPasswordDto } from "../../utilities/backend/client";

const ForgotPassword = () => {
    const authClient = new AuthClient()
    const navigate = useNavigate();
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState('')
    const [validationMessage, setValidationMessage] = useState('')
    const [userCode, setUserCode] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [notificationShow, setNotificationShow] = useState<boolean>(false);
    const [passwordValidation, setPasswordValidation] = useState('')

    const handleNotificationClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setNotificationShow(false);
    };

    const handleEmailChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setEmail(event.target.value);
    };

    const handleUserCodeChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setUserCode(event.target.value);
    };

    const handleNewPasswordChange = (event: { target: { value: string }; }) => {
        if (!event.target.value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{14,}$/)) {
            setPasswordValidation('The new password must contain one digit, one lowercase letter, one uppercase letter, one special character, no space, and it must be 14+ characters long.')
        }
        else {
            setPasswordValidation('')
        }
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: { target: { value: string }; }) => {
        setConfirmPassword(event.target.value);
    };

    function sendEmail() {
        const isValid = email
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )

        if (!isValid) {
            setValidationMessage('Please, enter valid email address.')
            return
        }
            
        let data: EmailReceiveDto = { email: email }
        authClient.auth_SendCode(data).then(async () => {
            setStep(2);
        }).catch((error) => {
            setValidationMessage(`Cannot find the user with email ${email}`)
            console.log(error);
        });
    }

    function confirmCode() {
        let data: ResetCodeDto = { email: email, code: Number(userCode) }
        authClient.auth_CheckCode(data).then(async () => {
            setStep(3);
        }).catch((error) => {
            setValidationMessage(`Please, enter valid verification code.`);
            console.log(error);
        });
    }

    function resetPassword() {
        if (newPassword === confirmPassword) {
            let resetData: ResetPasswordDto = { email: email, password: newPassword }
            authClient.auth_ResetPassword(resetData).then(async () => {
                navigate("/login");
            }).catch((error) => {
                console.log(error);
            });
        }else{
            setNotificationShow(true);
        }
    }

    function renderStep(param: number) {
        switch (param) {
            case 1:
                return <Stack spacing={2} width={600} alignItems="center" justifyContent="center" sx={{ ml: '5%', mr: '5%' }}>
                    <ContentTitle title='Reset Password' description='Please enter your email address on file' />
                    <Typography sx={{ width: "100%", fontFamily: 'Ubuntu', fontSize: 16, fontWeight: 400, color: "#474747" }} align='left'>Email Address</Typography>
                    <TextField fullWidth={true} type="email" value={email} onChange={handleEmailChange}></TextField>
                    {validationMessage !== '' && <Typography>{validationMessage}</Typography>}
                    <Button sx={{ width: "100%" }} variant="contained" onClick={sendEmail}>Send OTP</Button>
                </Stack>
            case 2:
                return <Stack spacing={2} width={600} alignItems="center" justifyContent="center" sx={{ ml: '5%', mr: '5%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', maxWidth: '1011px', margin: '0 auto' }}>
                        <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 32, fontWeight: 700, color: "#060A25", lineHeigh: '38.4px' }}>Reset Password</Typography>
                        <Stack spacing={1} direction={"row"}>
                            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 400, color: "#474747", lineHeigh: '20.68px', mt: '15px' }}>Please enter the verification code we just sent to</Typography>
                            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 700, color: "#060A25", lineHeigh: '20.68px', mt: '15px' }}>{email}</Typography>
                        </Stack>
                    </div>
                    <Stack spacing={1} direction={"row"}>
                        <TextField fullWidth={true} value={userCode} onChange={handleUserCodeChange} inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }}></TextField>
                    </Stack>
                    {validationMessage !== '' && <Typography>{validationMessage}</Typography>}
                    <Button sx={{ width: "100%" }} variant="contained" onClick={confirmCode}>Verify</Button>
                    <Stack spacing={1} direction={"row"} width={"100%"} alignItems={"flex-end"} justifyContent={"right"} >
                        <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 14, fontWeight: 400, color: "#060A25", lineHeigh: '20.68px', paddingBottom: '5px' }}>Didn't get OTP?</Typography>
                        <Button size="small">Resend</Button>
                    </Stack>
                </Stack>
            case 3:
                return <Stack spacing={2} width={600} alignItems="center" justifyContent="center" sx={{ ml: '5%', mr: '5%' }}>
                    <ContentTitle title='Reset Password' description='Please enter and confirm your new password' />
                    <Typography sx={{ width: "100%", fontFamily: 'Ubuntu', fontSize: 16, fontWeight: 400, color: "#474747" }} align='left'>New Password</Typography>
                    <TextField fullWidth={true} type="password" value={newPassword} onChange={handleNewPasswordChange}></TextField>
                    <Typography sx={{ width: "100%", fontFamily: 'Ubuntu', fontSize: 16, fontWeight: 400, color: "#474747" }} align='left'>Confirm New Password</Typography>
                    <TextField fullWidth={true} type="password" value={confirmPassword} onChange={handleConfirmPasswordChange}></TextField>
                    {passwordValidation && <Typography>{passwordValidation}</Typography>}
                    {confirmPassword !== newPassword && <Typography>The new password and confirmation password are different.</Typography>}
                    <Button sx={{ width: "100%" }} variant="contained" onClick={resetPassword} disabled={confirmPassword !== newPassword || !newPassword.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{14,}$/)}>Save</Button>
                </Stack>
            default:
                return <Stack spacing={2} width={600} alignItems="center" justifyContent="center" sx={{ ml: '5%', mr: '5%' }}>
                    <ContentTitle title='Reset Password' description='Please enter your email address on file' />
                    <Typography sx={{ width: "100%", fontFamily: 'Ubuntu', fontSize: 16, fontWeight: 400, color: "#474747" }} align='left'>Email Address</Typography>
                    <TextField fullWidth={true} type="email" value={email} onChange={handleEmailChange}></TextField>
                    <Button sx={{ width: "100%" }} variant="contained" onClick={sendEmail}>Send OTP</Button>
                </Stack>
        }
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "auto" }}>
            <Grid container spacing={0}>
                <Grid alignItems="flex-start" xs={2} sx={{ justifyContent: "flex-start", margin: "auto" }}>
                    <Link to={"/login"} style={{ textDecoration: 'none', alignContent: "flex-start" }}>
                        <Stack direction="row">
                            <ArrowBackIcon sx={{ color: "000000" }}></ArrowBackIcon>
                            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 16, fontWeight: 400, color: "#474747" }}>Back</Typography>
                        </Stack>
                    </Link>
                </Grid>
                <Grid item xs={10} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "auto" }}>
                    {renderStep(step)}
                </Grid>
            </Grid>
            <Snackbar open={notificationShow} autoHideDuration={6000} onClose={handleNotificationClose}>
                <Alert onClose={handleNotificationClose} severity="error" sx={{ width: '100%' }}>
                    Passwords must be the same
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ForgotPassword;