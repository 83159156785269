import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {
  ClickAwayListener,
  Divider,
  Grow,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import useTokenCheck from "../utilities/backend/Auth";
import { Link, useNavigate } from "react-router-dom";
import { removeToken, setRole } from "../utilities/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiException,
  EditUserDto,
  NotificationsClient,
  TokenDto,
  UserNotificationDto,
  UsersClient,
} from "../utilities/backend/client";
import { RootState } from "../utilities/redux/store";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SVG } from "./svg";
import Logo from "../resources/catacap.svg";
import NotificationItem from "./NotificationItem";
import useRole from "../utilities/backend/Auth/useRole";
import { useState } from "react";
import useHandleApiError from "../utilities/backend/Auth/useHandleApiError";
import {
  defaultImage,
  getUrlBlobContainerImage,
} from "../resources/imageConstants";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  "&:focus": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: "37px",
  // height: '49px',
  width: "100%",
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  gap: 12,
  marginBottom: 32,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const settings = [
  "My portfolio",
  "Community",
  "Invest",
  "Home",
  "Invite Friends",
  "Add Money",
  "Raise Money",
  "My Account",
];

const InvestAppBar = () => {
  const role = useRole();
  const userClient = new UsersClient();
  const notificationClient = new NotificationsClient();
  const [user, setUser] = React.useState<EditUserDto>({} as EditUserDto);
  const [notifications, setNotifications] =
    React.useState<UserNotificationDto[]>();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openMobile, setOpenMobile] = React.useState(false);
  const [apiError, setApiError] = useState<ApiException | null>(null);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const anchorRefMobile = React.useRef<HTMLButtonElement>(null);
  const [openNotification, setOpenNotification] = React.useState(false);
  const anchorNotRef = React.useRef<HTMLButtonElement>(null);
  const anchorNotMobileRef = React.useRef<HTMLButtonElement>(null);
  const [openNotificationMobile, setOpenNotificationMobile] =
    React.useState(false);
  const token: TokenDto = {
    token: useSelector((state: RootState) => state.token.token) || "",
  };
  useHandleApiError(apiError);

  React.useEffect(() => {
    if (user.token === undefined && token.token !== "") {
      Promise.all([
        userClient.users_GetUser(token),
        notificationClient.notifications_GetAllNotificationByUser(token),
      ])
        .then((response) => {
          setUser(response[0]);
          setNotifications(response[1]);
        })
        .catch((error: ApiException) => {
          setApiError(error);
        });
    }
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleToggleMobile = () => {
    setOpenMobile((prevOpen) => !prevOpen);
  };

  const handleNotificationOpen = () => {
    setOpenNotification((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleCloseMobile = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRefMobile.current &&
      anchorRefMobile.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenMobile(false);
  };

  const handleCloseNotification = (event: Event | React.SyntheticEvent) => {
    if (
      anchorNotRef.current &&
      anchorNotRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenNotification(false);
  };

  const handleCloseNotificationMobile = (
    event: Event | React.SyntheticEvent
  ) => {
    if (
      anchorNotMobileRef.current &&
      anchorNotMobileRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenNotification(false);
  };

  const handleNotificationMobileOpen = () => {
    setOpenNotificationMobile((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  function handleListKeyDownMobile(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMobile(false);
    } else if (event.key === "Escape") {
      setOpenMobile(false);
    }
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const isTokenPresent = useTokenCheck();

  function login() {
    navigate("/login");
  }

  function logOut() {
    setUser({});
    dispatch(removeToken());
    dispatch(setRole(""));
    navigate("/login");
  }

  function portfolio() {
    navigate("/portfolio");
  }

  function register() {
    navigate("/register");
  }

  function howItWorksClick() {
    navigate("/how");
  }

  function raiseMoneyClick() {
    navigate("/invest-for-change");
  }

  function findInvestmentsClick() {
    navigate("/find");
  }

  function community() {
    navigate("/community");
  }

  function onNotificationClick(notification: UserNotificationDto) {
    notification.isRead = true;
    notificationClient
      .notifications_UpdateNotification(notification.id!, notification)
      .then(() => {
        setOpenNotification(false);
        notificationClient
          .notifications_GetAllNotificationByUser(token)
          .then((response) => {
            setNotifications(response);
          })
          .catch((error) => {
            console.log(error);
          });
        if (notification.urlToRedirect!.startsWith("/")) {
          navigate(notification.urlToRedirect!);
        } else if (notification.urlToRedirect!.startsWith("http")) {
          window.open(notification.urlToRedirect!, "_blank");
        }
      })
      .catch((error) => {
        setApiError(error);
      });
  }

  function MarkAllNotificationAsRead() {
    notificationClient
      .notifications_UpdateAllNotificationForUser(token)
      .then(() => {
        notificationClient
          .notifications_GetAllNotificationByUser(token)
          .then((response) => {
            setNotifications(response);
          })
          .catch((error) => {
            setApiError(error);
          });
      })
      .catch((error) => {
        setApiError(error);
      });
  }

  const handleClickSupport = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    window.location.href = `mailto:support@impactree.org`;
  };

  const linkTo = isTokenPresent
    ? "https://app.catacap.org/home"
    : "https://www.catacap.org";

  return (
    <AppBar
      position="static"
      sx={{
        borderRadius: "0px 0px 20px 20px",
        zIndex: 2,
        height: "95px",
        position: "relative",
        backgroundImage: "url(/header.png)",
        backgroundSize: "cover",
      }}
    >
      <Toolbar
        disableGutters
        sx={{ height: "100%", position: "relative", zIndex: 5 }}
      >
        <Link to={linkTo} target="_blank" style={{ marginLeft: "25px" }}>
          <img src={Logo as unknown as string} height={92} width={200} />
        </Link>

        {!isTokenPresent && (
          <>
            <Box
              sx={{
                flexDirection: "row",
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <Button
                key="Find Investments"
                onClick={findInvestmentsClick}
                sx={{
                  ml: "100px",
                  my: 2,
                  color: "white",
                  display: "block",
                  textTransform: "none",
                  fontFamily: "Ubuntu",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "21.6px",
                }}
              >
                Invest for Change
              </Button>
              <Link
                to={"https://www.catacap.org/champion-deals/"}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  key="How it Works"
                  // onClick={howItWorksClick}
                  sx={{
                    ml: "30px",
                    my: 2,
                    color: "white",
                    display: "block",
                    textTransform: "none",
                    fontFamily: "Ubuntu",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "21.6px",
                  }}
                >
                  Champion Deals
                </Button>
              </Link>

              <Link
                to={"https://www.catacap.org/about-catacap/"}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  key="Invest for Change"
                  onClick={raiseMoneyClick}
                  sx={{
                    ml: "30px",
                    my: 2,
                    color: "white",
                    display: "block",
                    textTransform: "none",
                    fontFamily: "Ubuntu",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "21.6px",
                  }}
                >
                  About CataCap
                </Button>
              </Link>
              {/* <Stack sx={{ display: { xs: 'none', md: 'flex' }, width: '322px' }}>
                <Search sx={{ border: '1px solid white' }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    sx={{ color: 'white', width: '100%' }}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search', color: 'white' }}
                  />
                </Search>
              </Stack> */}
            </Box>
          </>
        )}

        {isTokenPresent ? (
          role === "Admin" ? (
            <Box
              width={"80%"}
              justifyContent={"flex-end"}
              sx={{
                flexDirection: "row",
                display: { xs: "none", md: "flex" },
                marginRight: "40px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  p: "10px 24px",
                  my: 2,
                  textTransform: "none",
                  fontFamily: "Ubuntu",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "21.6px",
                  color: "#2575FC",
                  display: "block",
                  backgroundColor: "white",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "white",
                  },
                }}
                onClick={() => logOut()}
              >
                Log Out
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                alignItems: "center",
                width: "100%",
                flexDirection: "row",
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  flexDirection: "row",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    textTransform: "none",
                    fontFamily: "Ubuntu",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "21.6px",
                    ml: "150px",
                  }}
                  onClick={findInvestmentsClick}
                >
                  Find Investment
                </Button>
                <Button
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    textTransform: "none",
                    fontFamily: "Ubuntu",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "21.6px",
                    ml: "150px",
                  }}
                  onClick={community}
                >
                  Community
                </Button>
                <Button
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    textTransform: "none",
                    fontFamily: "Ubuntu",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "21.6px",
                    ml: "150px",
                  }}
                  onClick={portfolio}
                >
                  Portfolio
                </Button>
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  flexDirection: "row",
                  display: { xs: "none", md: "flex" },
                }}
              >
                <IconButton ref={anchorNotRef} onClick={handleNotificationOpen}>
                  <NotificationsIcon sx={{ color: "white" }} />
                </IconButton>
                <Popper
                  open={openNotification}
                  anchorEl={anchorNotRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{ zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        padding: "10px",
                        borderRadius: "16px",
                        boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.12)",
                        width: 245,
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <Box
                          sx={{
                            padding: "10px",
                            maxHeight: 400,
                            overflowY: "auto",
                            "&::-webkit-scrollbar": {
                              width: "6px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#888",
                              borderRadius: "6px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              backgroundColor: "#555",
                            },
                          }}
                        >
                          <ClickAwayListener
                            onClickAway={handleCloseNotification}
                          >
                            <Stack spacing={0}>
                              {notifications && notifications.length ? (
                                <>
                                  <Button
                                    sx={{ marginBottom: "10px" }}
                                    variant="contained"
                                    onClick={() => MarkAllNotificationAsRead()}
                                  >
                                    Mark all as read
                                  </Button>
                                  {notifications.map((notification) => (
                                    <div
                                      key={notification.id!}
                                      onClick={() =>
                                        onNotificationClick(notification)
                                      }
                                    >
                                      <NotificationItem
                                        notification={notification}
                                      />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <Typography
                                  align="center"
                                  sx={{
                                    width: "100%",
                                    fontFamily: "Ubuntu",
                                    fontSize: 16,
                                    fontWeight: 400,
                                    color: "#474747",
                                  }}
                                >
                                  No Notifications yet
                                </Typography>
                              )}
                            </Stack>
                          </ClickAwayListener>
                        </Box>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Divider
                  orientation="vertical"
                  sx={{ backgroundColor: "white" }}
                />
                <Box
                  height={30}
                  width={30}
                  sx={{ borderRadius: "8px", border: "solid 2px white" }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                    }}
                    src={
                      user.pictureFileName
                        ? getUrlBlobContainerImage(user?.pictureFileName)
                        : defaultImage
                    }
                    alt="img"
                  />
                </Box>
                <IconButton
                  sx={{ color: "white" }}
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{ zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        padding: "20px",
                        borderRadius: "16px",
                        boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.12)",
                        width: 245,
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <StyledMenuItem
                              onClick={() => {
                                setOpen(false);
                                navigate("/");
                              }}
                            >
                              <SVG.Home /> Home
                            </StyledMenuItem>
                            {/* <StyledMenuItem onClick={() => { navigate("/") }}><SVG.Community /> Invite Friends</StyledMenuItem> */}
                            <StyledMenuItem
                              onClick={() => {
                                setOpen(false);
                                navigate("/fund-investment");
                              }}
                            >
                              <SVG.Money /> Add Funds
                            </StyledMenuItem>
                            <Link
                              to={"https://www.catacap.org/raisemoney/"}
                              target="_blank"
                              style={{
                                textDecoration: "none",
                                color: "#000000de",
                              }}
                            >
                              <StyledMenuItem
                                onClick={() => {
                                  setOpen(false);
                                  navigate("/");
                                }}
                              >
                                <SVG.Ticket />
                                Raise Money
                              </StyledMenuItem>
                            </Link>
                            <StyledMenuItem
                              onClick={() => {
                                setOpen(false);
                                navigate("/settings");
                              }}
                            >
                              <SVG.User /> My Account
                            </StyledMenuItem>
                            <StyledMenuItem
                              onClick={(event) => {
                                setOpen(false);
                                handleClickSupport(event);
                              }}
                            >
                              <SVG.Dialog />
                              Support
                            </StyledMenuItem>
                            {/* <MenuItem>
                            <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
                              <Typography>
                                {user.userName}
                              </Typography>
                              <Typography color={"green"}>
                                {"$" + user.accountBalance}
                              </Typography>
                            </Stack>
                          </MenuItem> */}
                            <Divider
                              sx={{
                                width: "100%",
                                height: "1px",
                                background: "#E8E8E8",
                                marginBottom: "32px !important",
                              }}
                            />
                            <StyledMenuItem
                              sx={{ marginBottom: 0 }}
                              onClick={() => {
                                setOpen(false);
                                logOut();
                              }}
                            >
                              <SVG.Logout />
                              Log out
                            </StyledMenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            </Box>
          )
        ) : (
          <Box
            width={"30%"}
            justifyContent={"flex-end"}
            sx={{
              flexDirection: "row",
              display: { xs: "none", md: "flex" },
              marginRight: "40px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                p: "5px 24px",
                my: 2,
                color: "white",
                display: "block",
                textTransform: "none",
                fontFamily: "Ubuntu",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "21.6px",
                borderColor: "white",
                marginRight: "20px",
                "&.MuiButtonBase-root:hover": {
                  borderColor: "white",
                },
              }}
              onClick={login}
            >
              Login
            </Button>
            <Button
              variant="contained"
              sx={{
                p: "10px 24px",
                my: 2,
                textTransform: "none",
                fontFamily: "Ubuntu",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "21.6px",
                color: "#2575FC",
                display: "block",
                backgroundColor: "white",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "white",
                },
              }}
              onClick={register}
            >
              Sign Up
            </Button>
          </Box>
        )}

        {isTokenPresent ? (
          <Box
            sx={{ display: { xs: "flex", md: "none", width: "100%" }, mr: 1 }}
          >
            <Box
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                  width: "100%",
                  justifyContent: "end",
                },
              }}
            >
              <IconButton
                ref={anchorNotMobileRef}
                onClick={handleNotificationMobileOpen}
              >
                <NotificationsIcon sx={{ color: "white" }} />
              </IconButton>
              <Popper
                open={openNotificationMobile}
                anchorEl={anchorNotMobileRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{ zIndex: 1 }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      padding: "10px",
                      borderRadius: "16px",
                      boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.12)",
                      width: 245,
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <Box
                        sx={{
                          padding: "10px",
                          maxHeight: 400,
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#888",
                            borderRadius: "6px",
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#555",
                          },
                        }}
                      >
                        <ClickAwayListener
                          onClickAway={handleCloseNotificationMobile}
                        >
                          <Stack spacing={0}>
                            {notifications && notifications.length ? (
                              <>
                                <Button
                                  sx={{ marginBottom: "10px" }}
                                  variant="contained"
                                  onClick={() => MarkAllNotificationAsRead()}
                                >
                                  Mark all as read
                                </Button>
                                {notifications.map((notification) => (
                                  <div
                                    key={notification.id!}
                                    onClick={() =>
                                      onNotificationClick(notification)
                                    }
                                  >
                                    <NotificationItem
                                      notification={notification}
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <Typography
                                align="center"
                                sx={{
                                  width: "100%",
                                  fontFamily: "Ubuntu",
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: "#474747",
                                }}
                              >
                                No Notifications yet
                              </Typography>
                            )}
                          </Stack>
                        </ClickAwayListener>
                      </Box>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <Divider
                orientation="vertical"
                sx={{ mr: "15px", backgroundColor: "white" }}
              />
              <Box
                height={30}
                width={30}
                sx={{ borderRadius: "8px", border: "solid 2px white" }}
              >
                <img
                  style={{ width: "100%", height: "100%", borderRadius: "8px" }}
                  src={
                    user.pictureFileName
                      ? getUrlBlobContainerImage(user?.pictureFileName)
                      : defaultImage
                  }
                  alt="img"
                />
              </Box>
              <IconButton
                sx={{ color: "white" }}
                ref={anchorRefMobile}
                id="composition-button"
                aria-controls={openMobile ? "composition-menu" : undefined}
                aria-expanded={openMobile ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggleMobile}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
              <Popper
                open={openMobile}
                anchorEl={anchorRefMobile.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{ zIndex: 1 }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      padding: "20px",
                      borderRadius: "16px",
                      boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.12)",
                      width: 245,
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleCloseMobile}>
                        <MenuList
                          autoFocusItem={openMobile}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDownMobile}
                        >
                          <StyledMenuItem
                            onClick={() => {
                              setOpenMobile(false);
                              navigate("/find");
                            }}
                          >
                            <SVG.Find /> Find Investments
                          </StyledMenuItem>
                          <StyledMenuItem
                            onClick={() => {
                              setOpenMobile(false);
                              navigate("/community");
                            }}
                          >
                            <SVG.Comm /> Community
                          </StyledMenuItem>
                          <StyledMenuItem
                            onClick={() => {
                              setOpenMobile(false);
                              navigate("/portfolio");
                            }}
                          >
                            <SVG.MyPortfolio /> My Portfolio
                          </StyledMenuItem>
                          <StyledMenuItem
                            onClick={() => {
                              setOpenMobile(false);
                              navigate("/");
                            }}
                          >
                            <SVG.Home /> Home
                          </StyledMenuItem>
                          {/* <StyledMenuItem onClick={() => { navigate("/") }}><SVG.Community /> Invite Friends</StyledMenuItem> */}
                          <StyledMenuItem
                            onClick={() => {
                              setOpenMobile(false);
                              navigate("/fund-investment");
                            }}
                          >
                            <SVG.Money /> Add Funds
                          </StyledMenuItem>
                          <Link
                            to={"https://www.catacap.org/raisemoney/"}
                            target="_blank"
                            style={{
                              textDecoration: "none",
                              color: "#000000de",
                            }}
                          >
                            <StyledMenuItem
                              onClick={() => {
                                setOpenMobile(false);
                                navigate("/");
                              }}
                            >
                              <SVG.Ticket />
                              Raise Money
                            </StyledMenuItem>
                          </Link>
                          <StyledMenuItem
                            onClick={() => {
                              setOpenMobile(false);
                              navigate("/settings");
                            }}
                          >
                            <SVG.User /> My Account
                          </StyledMenuItem>
                          <StyledMenuItem
                            onClick={(event) => {
                              setOpen(false);
                              handleClickSupport(event);
                            }}
                          >
                            <SVG.Dialog />
                            Support
                          </StyledMenuItem>
                          {/* <MenuItem>
                            <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
                              <Typography>
                                {user.userName}
                              </Typography>
                              <Typography color={"green"}>
                                {"$" + user.accountBalance}
                              </Typography>
                            </Stack>
                          </MenuItem> */}
                          <Divider
                            sx={{
                              width: "100%",
                              height: "1px",
                              background: "#E8E8E8",
                              marginBottom: "32px !important",
                            }}
                          />
                          <StyledMenuItem
                            sx={{ marginBottom: 0 }}
                            onClick={() => {
                              setOpenMobile(false);
                              logOut();
                            }}
                          >
                            <SVG.Logout />
                            Log out
                          </StyledMenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              {/* <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >

                <MenuIcon />
              </IconButton> */}
            </Box>
            {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Menu
                sx={{ p: 0 }}
                id="menu-appbar"
                anchorEl={anchorElNav}
               
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}

              >
                {settings.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </IconButton> */}
          </Box>
        ) : (
          <Box
            width={"30%"}
            justifyContent={"flex-end"}
            sx={{
              flexDirection: "row",
              display: { xs: "contents", md: "none" },
              marginRight: "40px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                my: 2,
                p: "16px 0px",
                color: "white",
                display: "block",
                textTransform: "none",
                fontFamily: "Ubuntu",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "21.6px",
                borderColor: "white",
                marginRight: "20px",
                "&.MuiButtonBase-root:hover": {
                  borderColor: "white",
                },
              }}
              onClick={login}
            >
              Login
            </Button>
            <Button
              sx={{
                my: 2,
                textTransform: "none",
                fontFamily: "Ubuntu",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "21.6px",
                color: "#2575FC",
                display: "block",
                mr: "5px",
                backgroundColor: "white",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "white",
                },
              }}
              onClick={register}
            >
              Sign Up
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default InvestAppBar;
