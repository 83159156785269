import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ApiException,
  FollowingRequestClient,
  FollowingRequestDto,
  GroupRequestsDto,
} from "../utilities/backend/client";
import { useSelector } from "react-redux";
import { RootState } from "../utilities/redux/store";
import RequestData from "./RequestData";
import useHandleApiError from "../utilities/backend/Auth/useHandleApiError";
import RemoveData from "./RemoveData";
import {
  getUrlBlobContainerImage,
  defaultImage,
} from "../resources/imageConstants";

interface GroupsRequestsProps {
  groupId: number;
  isOwner: boolean;
}

const GroupsUsersAndRequests = (props: GroupsRequestsProps) => {
  const requestClient = new FollowingRequestClient();
  const token: string =
    useSelector((state: RootState) => state.token.token) || "";
  const [requestList, setRequestList] = useState<FollowingRequestDto[]>();
  const [usersList, setUsersList] = useState<FollowingRequestDto[]>();
  const [requestChanger, setRequestChanger] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setApiError] = useState<ApiException | null>(null);

  useHandleApiError(apiError);

  useEffect(() => {
    const data: GroupRequestsDto = { groupId: props.groupId, token: token };
    Promise.all([
      requestClient.followingRequest_GetRequestByGroup(data),
      requestClient.followingRequest_GetAcceptedRequestByGroup(data),
    ])
      .then((response) => {
        setRequestList(response[0]);
        setUsersList(response[1]);
        setLoading(false);
      })
      .catch((error) => {
        setApiError(error);
        setLoading(false);
      });
  }, [requestChanger]);

  function acceptRequest(request: FollowingRequestDto) {
    request.status = "accepted";
    requestClient
      .followingRequest_Update_Request(request.id!, request)
      .then(() => {
        setRequestChanger(!requestChanger);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function declineRequest(request: FollowingRequestDto) {
    requestClient
      .followingRequest_Delete_Groups_Request(request.id!)
      .then(() => {
        setRequestChanger(!requestChanger);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const deleteRequest = (request: FollowingRequestDto) => {
    requestClient
      .followingRequest_Delete_Groups_Request(request.id!)
      .then(() => {
        setRequestChanger(!requestChanger);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Stack
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        sx={{ overflow: "hidden" }}
      >
        <>
          {loading && (
            <Box sx={{ margin: "0 auto" }}>
              <CircularProgress />
            </Box>
          )}
          {!loading && usersList && usersList.length > 0 && (
            <Box sx={{ marginBottom: "48px !important", width: "100%" }}>
              <Stack sx={{ width: "100%", marginBottom: "24px !important" }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 32,
                    fontWeight: 700,
                    color: "#060A25",
                    p: "0 18px",
                  }}
                >
                  Members
                </Typography>
                <Stack
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Divider
                    sx={{
                      backgroundColor: "#45B08A",
                      height: "2px",
                      width: "135px",
                    }}
                  ></Divider>
                  <Divider
                    sx={{
                      backgroundColor: "#E8E8E8",
                      height: "2px",
                      width: "100%",
                    }}
                  ></Divider>
                </Stack>
              </Stack>
              {usersList?.map((request) => (
                <RemoveData
                  deleteFunc={() => deleteRequest(request)}
                  fullName={request ? request.requestOwnerName! : ""}
                  picture={
                    request.requestOwnerPicture!
                      ? getUrlBlobContainerImage(request.requestOwnerPicture!)
                      : defaultImage
                  }
                  key={request.id}
                ></RemoveData>
              ))}
            </Box>
          )}

          {!loading && requestList && requestList.length > 0 && (
            <Box sx={{ marginBottom: "48px !important", width: "100%" }}>
              <Stack sx={{ width: "100%", marginBottom: "24px !important" }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 32,
                    fontWeight: 700,
                    color: "#060A25",
                  }}
                >
                  Group Join Requests
                </Typography>
                <Stack
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Divider
                    sx={{
                      backgroundColor: "#45B08A",
                      height: "2px",
                      width: "135px",
                    }}
                  ></Divider>
                  <Divider
                    sx={{
                      backgroundColor: "#E8E8E8",
                      height: "2px",
                      width: "100%",
                    }}
                  ></Divider>
                </Stack>
              </Stack>
              {requestList?.map((request) => (
                <RequestData
                  acceptFunc={() => acceptRequest(request)}
                  fullName={request ? request.requestOwnerName! : ""}
                  picture={
                    request.requestOwnerPicture!
                      ? getUrlBlobContainerImage(request.requestOwnerPicture!)
                      : defaultImage
                  }
                  declineFunc={() => declineRequest(request)}
                  key={request.id}
                ></RequestData>
              ))}
            </Box>
          )}
        </>
      </Stack>
    </>
  );
};

export default GroupsUsersAndRequests;
