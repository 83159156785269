import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ApiException,
  GroupClient,
  GroupDto,
  TokenDto,
} from "../utilities/backend/client";
import { useSelector } from "react-redux";
import { RootState } from "../utilities/redux/store";
import GroupData from "./GroupData";
import { useNavigate } from "react-router-dom";
import useHandleApiError from "../utilities/backend/Auth/useHandleApiError";

const UsersGroups = () => {
  const groupClient = new GroupClient();
  const token: TokenDto = {
    token: useSelector((state: RootState) => state.token.token) || "",
  };
  const [groupsList, setGroupsList] = useState<GroupDto[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState<ApiException | null>(null);
  useHandleApiError(apiError);
  const navigate = useNavigate();

  useEffect(() => {
    groupClient
      .group_GetAllGroupsforUser(token)
      .then((response) => {
        setGroupsList(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setApiError(error);
      });
  }, []);

  return (
    <>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        spacing={4}
        margin={"25%"}
      >
        {isLoading ? (
          <Box sx={{ margin: "0 auto" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {groupsList && groupsList.length < 1 ? (
              <Box sx={{ maxWidth: "688px", textAlign: "center" }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 26,
                    fontWeight: 700,
                    color: "#474747",
                    marginBottom: "64px",
                  }}
                >
                  Investment groups are a great way to enable friends to follow
                  one another and share deals.
                </Typography>
                <Button
                  sx={{
                    width: "80%",
                    background: "#2575FC",
                    padding: "16px",
                    fontWeight: 500,
                    lineHeight: "120%",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/addEditGroup")}
                >
                  CREATE INVESTMENT GROUP
                </Button>
              </Box>
            ) : (
              <Stack
                spacing={2}
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
              >
                {groupsList?.map((item) => (
                  <GroupData group={item} key={item.id} />
                ))}
              </Stack>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default UsersGroups;
