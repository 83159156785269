import { Avatar, Stack, Typography } from "@mui/material";
import { UserNotificationDto } from "../utilities/backend/client";
import {
  defaultImage,
  getUrlBlobContainerImage,
} from "../resources/imageConstants";

interface NotificationItemProps {
  notification: UserNotificationDto;
}

const NotificationItem = (props: NotificationItemProps) => {
  return (
    <Stack
      paddingBottom={"10px"}
      paddingTop={"10px"}
      direction={"row"}
      spacing={2}
      justifyContent={"flex-start"}
      alignItems={"center"}
      width={"100%"}
      sx={{ background: props.notification.isRead ? "none" : "#c8e3fa" }}
    >
      <Avatar
        variant="rounded"
        src={
          props.notification.pictureFileName
            ? getUrlBlobContainerImage(props.notification.pictureFileName)
            : defaultImage
        }
        sx={{ width: 54, height: 54 }}
      ></Avatar>
      <Stack spacing={2} alignItems={"center"} justifyContent={"flex-start"}>
        <Typography
          sx={{
            width: "100%",
            fontFamily: "Ubuntu",
            fontSize: 16,
            fontWeight: 400,
            color: "#474747",
          }}
        >
          {props.notification.description}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NotificationItem;
