import { Button, Divider, Stack, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { CategoryClient } from "../../../utilities/backend/client";
import CampainsGrid from "../../../components/CampainsGrid";

const StackRes1 = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  margin: "0 auto",
  marginTop: "80px",
  height: "100%",
  mb: "50px",
  width: "1280px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Button1 = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

const FindInvestment = () => {
  // const campaignClient = new CampaignClient()
  const categoryClient = new CategoryClient();
  //const [campaigns, setCampaingns] = useState<Campaign[]>({} as Campaign[])
  //const [search, setSearch] = useState("")
  const [selectedCategoriesIndex, setSelectedCategoriesIndex] = useState("");
  const [categoryList, setCategoryList] = useState<any[]>([]);

  useEffect(() => {
    const selectedI = categoryList.filter((i) => i.selected).map((i) => i.id);
    setSelectedCategoriesIndex(selectedI.join(","));
  }, [categoryList]);

  useEffect(() => {
    categoryClient
      .category_GetAll()
      .then(async (response) => {
        const categories = JSON.parse(await response.data.text());
        categories.map((i: any) => (i.selected = true));
        const selectedI = categories
          .filter((i: any) => i.selected)
          .map((i: any) => i.id);
        setSelectedCategoriesIndex(selectedI.join(","));
        setCategoryList(categories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleButtonClick = (i: any) => {
    const newArr = [...categoryList];
    const item = newArr.find((k) => k.id === i.id);
    if (item) item.selected = !item.selected;
    setCategoryList(newArr);
  };

  // const handleSearchChange = (event: { target: { value: SetStateAction<string>; }; }) => {
  //     setSearch(event.target.value);
  // };

  return (
    <StackRes1 spacing={4}>
      <Stack alignItems="flex-start" justifyContent="center" padding="0 18px">
        <Stack sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 32,
              fontWeight: 700,
              color: "#060A25",
            }}
          >
            Find Investments for your Donor Account
          </Typography>
          <Stack
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
            }}
          >
            <Divider
              sx={{
                backgroundColor: "#45B08A",
                height: "2px",
                width: "135px",
              }}
            ></Divider>
            <Divider
              sx={{
                backgroundColor: "#E8E8E8",
                height: "2px",
                width: "100%",
              }}
            ></Divider>
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Button
            variant="outlined"
            sx={{
              width: "127px",
              height: "50px",
              my: "50px",
              color: "#2575FC",
              display: "block",
              textTransform: "none",
              fontFamily: "Ubuntu",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "21.6px",
              borderColor: "#2575FC",
              borderRadius: "10px",
              marginRight: "20px",
              "&.MuiButtonBase-root:hover": {
                borderColor: "#2575FC",
              },
            }}
          >
            By Themes
          </Button>
          <Stack sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            {categoryList &&
              categoryList.map(
                (i) =>
                  i.mandatory && (
                    <Button1
                      onClick={() => handleButtonClick(i)}
                      sx={{
                        backgroundColor: i.selected ? "#45B08A" : "#E8E8E8",
                        width: "150px",
                        height: "51px",
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "Ubuntu",
                        color: i.selected ? "#fff" : "#191919",
                        padding: "16px 24px",
                        borderRadius: "8px",
                        marginRight: "20px",
                        "&:hover": {
                          backgroundColor: i.selected ? "#45B08A" : "#E8E8E8",
                          color: i.selected ? "#fff" : "#191919",
                          // border: '2px solid #2575FC'
                        },
                      }}
                      key={i.id}
                    >
                      {i.name}
                    </Button1>
                  )
              )}
          </Stack>
        </Stack>
        {/* <TextField helperText="Enter Investment name" type="text" value={search} onChange={handleSearchChange} InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }} /> */}
      </Stack>
      {categoryList && categoryList.length > 0 && (
        <Stack
          sx={{
            display: "flex",
            height: "100%",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          <CampainsGrid
            isForGroup={false}
            filterValue={selectedCategoriesIndex}
            filterItem="category"
          />
        </Stack>
      )}

      <Stack width={"100%"}>
        <Typography
          sx={{
            fontStyle: "italic",
            fontFamily: "Ubuntu",
            fontSize: 10,
            fontWeight: 400,
            color: "#474747",
            p: "10px 18px",
          }}
        >
          <span style={{ fontWeight: "700", textDecoration: "underline" }}>
            Important Note:
          </span>
          &nbsp; Donations to CataCap donor accounts become part of the 501c3
          public charity Impactree Foundation’s (IF’s) CataCap donor advised
          fund (DAF) at ImpactAssets (IA, also a 501c3 public charity), and
          contributions do not involve a purchase or sale of securities and
          represent an irrevocable tax-deductible donation. Any information
          about investments within CataCap is not for attribution, publication
          or dissemination, and is not for personal use outside of CataCap donor
          accounts. CataCap and IF are unrelated to IA other than in their use
          of an IA DAF for holding donated assets for the purpose of impact
          investing and grantmaking, and such use should not be taken to
          indicate that IA is in any way involved with CataCap and IF other than
          as a DAF sponsoring organization.
        </Typography>
      </Stack>
    </StackRes1>
  );
};

export default FindInvestment;
