import { useState, useEffect } from 'react';
import {RootState} from "../../redux/store";
import {useSelector} from "react-redux";

const useTokenCheck = () => {
    const [isTokenPresent, setTokenPresent] = useState(false);
    const token = useSelector((state: RootState) => state.token);
    useEffect(() => {
        setTokenPresent(!!token.token);
    }, [token]);

    return isTokenPresent;
};

export default useTokenCheck;