import OnlyIfAuthenticated from "../../state/currentUser/guards/OnlyIfAuthenticated";
import OnlyIfNotAuthenticated from "../../state/currentUser/guards/OnlyIfNotAuthenticated";
import useRole from "../../utilities/backend/Auth/useRole";
import AdminApp from "./Admin/AdminApp";
import HomeLoggedIn from "./User/loggedIn/HomeLoggedIn";

const Home = () => {
  const role = useRole();

  return (
    <>
      <OnlyIfAuthenticated
        children={
          role === "Admin" ? <AdminApp /> : <HomeLoggedIn></HomeLoggedIn>
        }
      />
      <OnlyIfNotAuthenticated children={<></>} />
    </>
  );
};

export default Home;
