import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ApiException,
  Campaign,
  CampaignClient,
  GroupClient,
  TokenDto,
} from "../../../utilities/backend/client";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CampaignData from "../../../components/CampaignData";
import { useSelector } from "react-redux";
import { RootState } from "../../../utilities/redux/store";
import useHandleApiError from "../../../utilities/backend/Auth/useHandleApiError";

const Stack1 = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const GroupInvestments = () => {
  const { state } = useLocation();
  const groupClient = new GroupClient();
  const campaignClient = new CampaignClient();
  const [group, setGroup] = useState(state?.group);
  const token: TokenDto = {
    token: useSelector((state: RootState) => state.token.token) || "",
  };
  const [availableInvestments, setAvailableInvestments] =
    useState<Campaign[]>();
  const [selectedInvestments, setSelectedInvestments] = useState<Campaign[]>(
    group.campaigns
  );
  const [requestChanger, setRequestChanger] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiException | null>(null);
  useHandleApiError(apiError);

  const linkGroup = group.identifier
    ? `/group/${group.identifier}`
    : `/group/${group.id}`;

  useEffect(() => {
    const campaignIds =
      selectedInvestments.map((campaign) => campaign.id) || [];
    campaignClient
      .campaign_GetCampaigns()
      .then((response) => {
        setAvailableInvestments(
          response.filter(
            (item) =>
              !campaignIds.includes(item.id) && !item.groupForPrivateAccessDto
          )
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedInvestments]);

  useEffect(() => {
    groupClient
      .group_GetGroup(group.id, token)
      .then((response) => {
        setGroup(response);
      })
      .catch((error) => {
        setApiError(error);
      });
  }, [requestChanger]);

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  function addToSelectedItems(campaign: Campaign) {
    setSelectedInvestments([...selectedInvestments, campaign]);
    setLoading(true);
  }

  function removeFromSelectedItems(selectedCampaign: Campaign) {
    const updatedCampaigns = selectedInvestments.filter(
      (campaign) => campaign.id !== selectedCampaign.id
    );
    setSelectedInvestments(updatedCampaigns);
    setLoading(true);
  }

  const updateData = () => {
    let groupToUpdate = group;
    groupToUpdate.campaigns = selectedInvestments;
    groupToUpdate.token = token.token;
    groupClient
      .group_UpdateGroup(groupToUpdate.id, groupToUpdate)
      .then(() => {
        setRequestChanger(!requestChanger);
        setNotificationShow(true);
      })
      .catch((error) => {
        setApiError(error);
      });
  };

  return (
    <>
      <Stack1
        sx={{ justifyContent: "center", margin: "auto" }}
        width={800}
        spacing={4}
      >
        <Stack
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          width={"100%"}
        >
          <Link
            to={linkGroup}
            style={{ textDecoration: "none", alignContent: "flex-start" }}
          >
            <Stack direction="row">
              <ArrowBackIcon sx={{ color: "000000" }}></ArrowBackIcon>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
              >
                Back
              </Typography>
            </Stack>
          </Link>
        </Stack>
        <Stack spacing={0}>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 32,
              fontWeight: 700,
              color: "#060A25",
            }}
          >
            Selected Investments
          </Typography>
          <Divider></Divider>
        </Stack>
        <Stack>
          {selectedInvestments?.map((item) => (
            <CampaignData
              key={item.id}
              buttonFunc={() => removeFromSelectedItems(item)}
              buttonText="Remove"
              fullName={item.name!}
              picture={item.image!}
            />
          ))}
        </Stack>
        <Stack spacing={0}>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 32,
              fontWeight: 700,
              color: "#060A25",
            }}
          >
            Available Investments
          </Typography>
          <Divider></Divider>
        </Stack>
        {loading ? (
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ margin: "0 auto" }}>
              <CircularProgress />
            </Box>
          </Stack>
        ) : (
          <Stack>
            {availableInvestments?.map((item) => (
              <CampaignData
                key={item.id}
                buttonFunc={() => addToSelectedItems(item)}
                buttonText="Add"
                fullName={item.name!}
                picture={item.image!}
              />
            ))}
          </Stack>
        )}
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => updateData()}
          >
            Save
          </Button>
        </Stack>
      </Stack1>

      <Snackbar
        open={notificationShow}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Group Investments was successfully updated!
        </Alert>
      </Snackbar>
    </>
  );
};

export default GroupInvestments;
