import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Support from "./User/Support";
import RaiseMoney from "./User/RaiseMoney";
import Portfolio from "./User/Portfolio";
import InviteFriends from "./User/InviteFriends";
import InvestmentDetail from "./User/InvestmentDetail";
import HowItWorks from "./User/HowItWorks";
import AccountSettings from "./User/AccountSettings";
import Community from "./User/Community";
import FindInvestment from "./User/FindInvestment";
import Categories from "./User/Categories";
import { Box, Container, Stack } from "@mui/material";
import Footer from "./User/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../../utilities/redux/store";
import { addAuthInterceptor } from "../../utilities/interceptors";
import InvestAppBar from "../../components/InvestAppBar";
import OnlyIfNotAuthenticated from "../../state/currentUser/guards/OnlyIfNotAuthenticated";
import Register from "../auth/register";
import OnlyIfAuthenticated from "../../state/currentUser/guards/OnlyIfAuthenticated";
import Home from "./Home";
import Login from "../auth/login";
import CampaignAddEditAdmin from "../../components/admin/CampaignAddEditAdmin";
import AboutUs from "./AboutUs";
import ForgotPassword from "../auth/ForgotPassword";
import FundInvestment from "./User/FundInvestment";
import DAFOrFundInvest from "./User/DAFOrFoundInvest";
import GroupAddEdit from "./User/GroupAddEdit";
import GroupDetail from "./User/GroupDetail";
import GroupInvestments from "./User/GroupInvestments";
import UserDetail from "./User/UsersDetail";
import InvestForChange from "./InvestForChange";
import Home2 from "./Home2";
import RaiseForm from "./RaiseForm";

const App = () => {
  const token = useSelector((state: RootState) => state.token);
  addAuthInterceptor();
  useEffect(() => {
    addAuthInterceptor();
  }, [token]);

  const raiseMoneyLink = window.location.pathname == "/raisemoney";

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        p: "0 0 !important",
        maxWidth: "100% !important",
      }}
    >
      {!raiseMoneyLink && <InvestAppBar />}
      <Box sx={{ flex: "100% 1 1" }}>
        <Routes>
          {/* only for not auth */}
          <Route
            path="/login"
            element={<OnlyIfNotAuthenticated children={<Login />} />}
          />
          <Route
            path="/register"
            element={<OnlyIfNotAuthenticated children={<Register />} />}
          />
          <Route
            path="/forgotpassword"
            element={<OnlyIfNotAuthenticated children={<ForgotPassword />} />}
          />

          {/* only for auth */}
          <Route
            path="/support"
            element={<OnlyIfAuthenticated children={<Support />} />}
          />
          <Route
            path="/portfolio"
            element={<OnlyIfAuthenticated children={<Portfolio />} />}
          />
          {/* <Route path="/invite" element={<OnlyIfAuthenticated children={<InviteFriends />} />}/> */}
          <Route
            path="/settings"
            element={<OnlyIfAuthenticated children={<AccountSettings />} />}
          />
          <Route
            path="/community"
            element={<OnlyIfAuthenticated children={<Community />} />}
          />
          <Route
            path="/donorbox-invest"
            element={
              <OnlyIfAuthenticated
                children={
                  <Container>
                    <script src="https://donorbox.org/widget.js"></script>
                    <iframe
                      src="https://donorbox.org/embed/impact-investing"
                      name="donorbox"
                      scrolling="no"
                      height="900px"
                      width="100%"
                    ></iframe>
                  </Container>
                }
              />
            }
          />
          <Route
            path="/addEditGroup"
            element={<OnlyIfAuthenticated children={<GroupAddEdit />} />}
          />
          <Route path="/group/:identifier" element={<GroupDetail />} />
          <Route path="/user/:userName" element={<UserDetail />} />
          <Route
            path="/groupInvestments"
            element={<OnlyIfAuthenticated children={<GroupInvestments />} />}
          />
          {/* <Route path="/user" element={<OnlyIfAuthenticated children={<UserDetail />} />} /> */}

          {/* not depends on auth (always display static content or content by filter) */}
          <Route path="/how" element={<HowItWorks />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/invest-for-change" element={<InvestForChange />} />
          <Route path="/raisemoney" element={<RaiseForm />} />
          {/* <Route path="/categories" element={<Categories />}/> */}
          <Route path="/find" element={<FindInvestment />} />

          {/* depends on auth */}
          <Route path="/home" element={<Home />} />
          <Route path="/home2" element={<Home2 />} />
          {/* <Route path="/raise" element={<RaiseMoney />}/> */}
          <Route path="/invest/:identifier" element={<InvestmentDetail />} />
          <Route path="/fund-investment" element={<FundInvestment />} />
          <Route path="/daf-fund-invest" element={<DAFOrFundInvest />} />

          {/* admin */}
          <Route path="/a-investment" element={<CampaignAddEditAdmin />} />

          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Box>
      {!raiseMoneyLink && <Footer />}
    </Container>
  );
};

export default App;
