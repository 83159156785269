import React, { useEffect } from "react";
import { Box } from "@mui/system";
import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { CampaignClient, Campaign } from "../utilities/backend/client";
import CampaignCard from "./CampaignCard";
import { Link } from "react-router-dom";

interface CampaignsGridProps {
  filterItem: string;
  filterValue: string;
  isForGroup: boolean;
  setShow?: any;
}

const Box1 = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const Box2 = styled(Box)(({ theme }) => ({
  width: "91%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CampainsGrid = (props: CampaignsGridProps) => {
  const campaignClient = new CampaignClient();
  const [campaigns, setCampains] = React.useState<Campaign[]>([]);
  const [campaignsToDisplay, setCampaignsToDisplay] = React.useState<
    Campaign[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    if (props.filterItem === "network") {
      campaignClient
        .campaign_GetCampaignsNetwork()
        .then((res) => {
          if (res) {
            if (res.length > 0) {
              setCampains(res);
              setCampaignsToDisplay(res);
              setLoading(false);
            } else {
              props.setShow(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      campaignClient
        .campaign_GetCampaigns()
        .then((response) => {
          setCampains(response);
          let arrayToCheck = props.filterValue.split(",");
          if (props.filterItem === "category") {
            const items = response.filter((item) =>
              item.themes
                ?.split(",")
                .some((element) => arrayToCheck.includes(element))
            );

            props.isForGroup
              ? setCampaignsToDisplay(items)
              : setCampaignsToDisplay(
                  items.filter((item) => !item.groupForPrivateAccessDto)
                );
          } else if (props.filterItem === "trending") {
            const itemsInvest = response.filter((i) => i.numberOfInvestors);
            const items = itemsInvest.sort(function (a, b) {
              if (a.numberOfInvestors && b.numberOfInvestors) {
                if (a.numberOfInvestors < b.numberOfInvestors) return -1;
                if (a.numberOfInvestors > b.numberOfInvestors) return 1;
              }
              return 0;
            });

            const k = items.sort(function (a, b) {
              if (a.currentBalance && b.currentBalance) {
                if (a.currentBalance > b.currentBalance) return -1;
                if (a.currentBalance < b.currentBalance) return 1;
              }
              return 0;
            });
            const itemsTo = k.slice(0, 3);
            props.isForGroup
              ? setCampaignsToDisplay(itemsTo)
              : setCampaignsToDisplay(
                  itemsTo.filter((item) => !item.groupForPrivateAccessDto)
                );
          } else if (props.filterItem === "id") {
            const campaignIds =
              props.filterValue.split(",").map((id) => parseInt(id)) || [];
            const filteredItems = response.filter((item) =>
              campaignIds.includes(item.id!)
            );
            props.isForGroup
              ? setCampaignsToDisplay(filteredItems)
              : setCampaignsToDisplay(
                  filteredItems.filter((item) => !item.groupForPrivateAccessDto)
                );
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (props.filterItem === "category") {
      if (props.filterValue !== "") {
        let arrayToCheck = props.filterValue.split(",");
        const items = campaigns.filter((item) =>
          item.themes
            ?.split(",")
            .some((element) => arrayToCheck.includes(element))
        );
        props.isForGroup
          ? setCampaignsToDisplay(items)
          : setCampaignsToDisplay(
              items.filter((item) => !item.groupForPrivateAccessDto)
            );
      } else {
        setCampaignsToDisplay([]);
      }
    } else if (props.filterItem === "id") {
      const campaignIds =
        props.filterValue.split(",").map((id) => parseInt(id)) || [];
      const filteredItems = campaigns.filter((item) =>
        campaignIds.includes(item.id!)
      );
      props.isForGroup
        ? setCampaignsToDisplay(filteredItems)
        : setCampaignsToDisplay(
            filteredItems.filter((item) => !item.groupForPrivateAccessDto)
          );
    }
  }, [props.filterValue, campaigns]);

  return (
    <Stack sx={{ display: "flex", gap: "20px", height: "100%", width: "100%" }}>
      {loading ? (
        <Box sx={{ margin: "0 auto" }}>
          <CircularProgress />
        </Box>
      ) : campaigns && campaigns.length > 0 ? (
        props.filterItem === "network" ? (
          <Box2
            sx={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              maxHeight: "560px",
              overflowX: "auto",
              flexDirection: "column",
              overflowY: "hidden",
              width: "91%",
              alignContent: "baseline",
            }}
          >
            {campaignsToDisplay.slice(0, 6).map((campaign) => (
              <Link
                to={
                  campaign.property
                    ? `/invest/${campaign.property}`
                    : `/invest/${campaign.id}`
                }
                style={{ textDecoration: "none", maxWidth: "362px" }}
              >
                <CampaignCard
                  key={campaign.id}
                  campaign={campaign}
                  investedAmount=""
                  investedDate=""
                  status=""
                />
              </Link>
            ))}
          </Box2>
        ) : (
          <Box1 sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            {campaignsToDisplay.map((campaign) => (
              <Grid item>
                <Link
                  to={
                    campaign.property
                      ? `/invest/${campaign.property}`
                      : `/invest/${campaign.id}`
                  }
                  style={{ textDecoration: "none" }}
                >
                  <CampaignCard
                    key={campaign.id}
                    campaign={campaign}
                    investedAmount=""
                    investedDate=""
                    status=""
                  />
                </Link>
              </Grid>
            ))}
          </Box1>
        )
      ) : (
        <Stack width="100%">
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 18,
              fontWeight: 400,
              color: "#656565",
              lineHeigh: "20.68px",
            }}
          >
            No Investments yet
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default CampainsGrid;
