
const DAFIcon = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="64" height="64" rx="8" fill="#45B08A" fill-opacity="0.1"/>
                <g clip-path="url(#clip0_2218_48934)">
                    <path d="M30.4167 13H17.75C16.4902 13 15.282 13.5004 14.3912 14.3912C13.5004 15.282 13 16.4902 13 17.75V51H35.1667V17.75C35.1667 16.4902 34.6662 15.282 33.7754 14.3912C32.8846 13.5004 31.6764 13 30.4167 13ZM22.5 43.0833H17.75V39.9167H22.5V43.0833ZM22.5 36.75H17.75V33.5833H22.5V36.75ZM22.5 30.4167H17.75V27.25H22.5V30.4167ZM22.5 24.0833H17.75V20.9167H22.5V24.0833ZM30.4167 43.0833H25.6667V39.9167H30.4167V43.0833ZM30.4167 36.75H25.6667V33.5833H30.4167V36.75ZM30.4167 30.4167H25.6667V27.25H30.4167V30.4167ZM30.4167 24.0833H25.6667V20.9167H30.4167V24.0833ZM46.25 20.9167H38.3333V51H51V25.6667C51 24.4069 50.4996 23.1987 49.6088 22.3079C48.718 21.4171 47.5098 20.9167 46.25 20.9167ZM46.25 43.0833H43.0833V39.9167H46.25V43.0833ZM46.25 36.75H43.0833V33.5833H46.25V36.75ZM46.25 30.4167H43.0833V27.25H46.25V30.4167Z" fill="#45B08A"/>
                </g>
                <defs>
                    <clipPath id="clip0_2218_48934">
                        <rect width="38" height="38" fill="white" transform="translate(13 13)"/>
                    </clipPath>
                </defs>
        </svg>
    )
}

export default DAFIcon;