import { Avatar, Button, Grid, Stack, Typography, styled } from "@mui/material"

interface CampaignDataProps {
    buttonFunc: Function
    picture: string
    fullName: string
    buttonText: string
  }

  const Typography1 = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        fontSize: '15px'
    }
}));

const CampaignData = (props: CampaignDataProps) => {

    return (
        <>
        <Grid container width={"100%"}>
            <Grid item xs={9} sx={{ mt: '10px' }} height={"100%"}>
            <Stack alignItems="center" justifyContent="flex-start" height={"100%"} spacing={1} direction={"row"}>
                    <Avatar variant="rounded" src={props.picture || ''} sx={{ width: 60, height: 60 }}></Avatar>
                    <Typography1 sx={{width: "100%", fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 700, color: "#191919"}}>{props.fullName}</Typography1>
                </Stack>
            </Grid>
            <Grid item xs={3} sx={{ mt: '23px' }} alignItems={"center"} justifyContent={"center"}>
                <Button variant="contained" color="primary" onClick={() => props.buttonFunc()}>{props.buttonText}</Button>
            </Grid>
        </Grid>
    </>
    )
}

export default CampaignData;