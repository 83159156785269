import { useNavigate, useParams } from "react-router-dom";
import {
  AddFollowingRequestDto,
  ApiException,
  DeleteRequestDto,
  FollowingRequestClient,
  GroupClient,
  GroupDto,
  TokenDto,
} from "../../../utilities/backend/client";
import { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CampainsGrid from "../../../components/CampainsGrid";
import GroupsUsersAndRequests from "../../../components/GroupsUsersAndRequests";
import { useSelector } from "react-redux";
import { RootState } from "../../../utilities/redux/store";
import useHandleApiError from "../../../utilities/backend/Auth/useHandleApiError";
import { currency_format } from "../../../utilities/helpers/currency";
import {
  getUrlBlobContainerImage,
  defaultImage,
} from "../../../resources/imageConstants";

const Stack1 = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Stack2 = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const Box1 = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Box2 = styled(Box)(({ theme }) => ({
  textAlign: "end",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px !important",
    marginRight: "30px !important",
  },
}));

const Box3 = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const Button1 = styled(Button)(({ theme }) => ({
  maxHeight: "40px",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0px !important",
  },
}));

const Box4 = styled(Box)(({ theme }) => ({
  marginLeft: "30px",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const Ttt = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 16,
  fontWeight: 400,
  color: "#656565",
  marginTop: "38px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
  },
}));

const GroupDetail = () => {
  const { identifier } = useParams();
  const groupClient = new GroupClient();
  const token: TokenDto = {
    token: useSelector((state: RootState) => state.token.token) || "",
  };
  const [group, setGroup] = useState<GroupDto>();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const requestClient = new FollowingRequestClient();
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("success");
  const [notificationText, setNotificationText] = useState<string>("");

  const [apiError, setApiError] = useState<ApiException | null>(null);
  useHandleApiError(apiError);

  const loadGroup = () => {
    setLoading(true);
    groupClient
      .group_GetGroup(identifier!, token)
      .then((responce) => {
        setGroup(responce);
        setLoading(false);
      })
      .catch((error) => {
        setApiError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadGroup();
  }, []);

  function follow() {
    if (!token.token) {
      const followingRequestGroupId = group?.id;

      localStorage.setItem(
        "followingRequestGroupId",
        JSON.stringify(followingRequestGroupId)
      );

      navigate("/register");
      return;
    }

    setLoading(true);
    let data: AddFollowingRequestDto = {
      requestOwnerToken: token.token,
    };

    data.groupToFollowId = Number(group?.id);
    data.userToFollowId = null;

    requestClient
      .followingRequest_Create_Request(data)
      .then(() => {
        loadGroup();
        setNotificationShow(true);
        setAlertSeverity("success");
        setNotificationText("You start following " + group?.name);
      })
      .catch((error) => {
        setApiError(error);
      });
  }

  function unfollowItem() {
    setLoading(true);
    let data: DeleteRequestDto = {
      requestOwnerToken: token.token,
    };

    data.followedGroupId = Number(group?.id);
    data.followedUserId = null;

    requestClient
      .followingRequest_Delete_Request(data)
      .then(() => {
        loadGroup();
        setNotificationShow(true);
        setAlertSeverity("error");
        setNotificationText("You stop following " + group?.name);
      })
      .catch((error) => {
        setApiError(error);
      });
  }

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  return (
    <Stack1
      sx={{
        justifyContent: "center",
        margin: "auto",
        paddingBottom: "30px",
        paddingTop: "70px",
      }}
      spacing={4}
      width={1280}
    >
      {apiError?.status === 404 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            margin: 0,
          }}
        >
          <Stack spacing={0} sx={{ p: "0 18px", textAlign: "center" }}>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 32,
                fontWeight: 700,
                color: "#060A25",
              }}
            >
              Sorry, the profile of this group is hidden or not found
            </Typography>
          </Stack>
        </Box>
      ) : loading ? (
        <Box sx={{ margin: "0 auto" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack2 justifyContent="space-between" spacing={7} direction={"row"}>
            <Box1 sx={{ display: "flex" }}>
              <Avatar
                src={
                  group?.pictureFileName
                    ? getUrlBlobContainerImage(group?.pictureFileName)
                    : defaultImage
                }
                sx={{ width: 135, height: 135, borderRadius: "8px" }}
                variant="rounded"
              />
              <Box4 sx={{ textAlign: "start" }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 32,
                    fontWeight: 700,
                    color: "#060A25",
                    marginBottom: "28px",
                  }}
                >
                  {group?.name}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#656565",
                  }}
                >
                  <span style={{ color: "#191919", fontWeight: "700" }}>
                    Group description:&nbsp;
                  </span>
                  {group?.description}
                </Typography>
              </Box4>
            </Box1>
            <Box2>
              {group?.isOwner ? (
                <Button1
                  sx={{
                    padding: "16px 24px",
                    lineHeight: "120%",
                    background: "#2575FC",
                    textTransform: "capitalize",
                    fontWeight: 500,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    navigate("/addEditGroup", { state: { group } })
                  }
                >
                  Edit
                </Button1>
              ) : group?.isFollowing ? (
                <>
                  <Button1
                    sx={{
                      padding: "16px 24px",
                      lineHeight: "120%",
                      background: "#2575FC",
                      textTransform: "capitalize",
                      fontWeight: 500,
                    }}
                    variant="contained"
                    color="primary"
                    onClick={unfollowItem}
                  >
                    Unfollow
                  </Button1>
                  {group?.isFollowPending && (
                    <Ttt>
                      Your following request is waiting for the approve...
                    </Ttt>
                  )}
                </>
              ) : (
                <Button1
                  sx={{
                    padding: "16px 24px",
                    lineHeight: "120%",
                    background: "#2575FC",
                    textTransform: "capitalize",
                    fontWeight: 500,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={follow}
                >
                  Follow
                </Button1>
              )}
            </Box2>
          </Stack2>
          <Box>
            {group?.groupAccountBalance && (
              <>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#656565",
                  }}
                >
                  <span style={{ color: "#191919", fontWeight: "700" }}> </span>
                  Amount for {group?.name} Investments:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: 26,
                    fontWeight: 700,
                    color: "#191919",
                    lineHeigh: "29.87px",
                  }}
                >
                  {currency_format(Number(group?.groupAccountBalance?.balance))}
                </Typography>
              </>
            )}
          </Box>

          {group && group.isOwner && (
            <GroupsUsersAndRequests
              groupId={Number(group.id)}
              isOwner={group ? group.isOwner : false}
            />
          )}
          {group &&
            (group.isOwner ||
              (group?.campaigns && group.campaigns.length > 0)) && (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                spacing={2}
                sx={{ marginBottom: "150px !important" }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    marginBottom: "55px !important",
                    overflow: "hidden",
                  }}
                >
                  <Box3
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                      p: "0 18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 28,
                        fontWeight: 700,
                        color: "#060A25",
                      }}
                    >
                      Investments to show group
                    </Typography>
                    {group?.isOwner && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          padding: "16px 24px",
                          lineHeight: "120%",
                          background: "#2575FC",
                          textTransform: "capitalize",
                          fontWeight: 500,
                        }}
                        onClick={() =>
                          navigate("/groupInvestments", { state: { group } })
                        }
                      >
                        Edit
                      </Button>
                    )}
                  </Box3>
                  <Stack
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Divider
                      sx={{
                        backgroundColor: "#45B08A",
                        height: "2px",
                        width: "135px",
                      }}
                    ></Divider>
                    <Divider
                      sx={{
                        backgroundColor: "#E8E8E8",
                        height: "2px",
                        width: "100%",
                      }}
                    ></Divider>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    height: "100%",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <CampainsGrid
                    isForGroup={true}
                    filterItem="id"
                    filterValue={
                      group
                        ? group
                            .campaigns!.map((campaign) => campaign.id)
                            .join(", ")
                        : ""
                    }
                  />
                </Stack>
              </Stack>
            )}
          {group &&
            group.privateCampaigns &&
            group.privateCampaigns.length > 0 && (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                spacing={2}
                sx={{ marginBottom: "150px !important" }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    marginBottom: "55px !important",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                      p: "0 18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: 22,
                        fontWeight: 700,
                        color: "#060A25",
                      }}
                    >
                      Private Investments to show group
                    </Typography>
                  </Box>
                  <Stack
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Divider
                      sx={{
                        backgroundColor: "#45B08A",
                        height: "2px",
                        width: "135px",
                      }}
                    ></Divider>
                    <Divider
                      sx={{
                        backgroundColor: "#E8E8E8",
                        height: "2px",
                        width: "100%",
                      }}
                    ></Divider>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    height: "100%",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <CampainsGrid
                    isForGroup={true}
                    filterItem="id"
                    filterValue={group.privateCampaigns
                      .map((campaign) => campaign.id)
                      .join(", ")}
                  />
                </Stack>
              </Stack>
            )}
        </>
      )}
      <Snackbar
        open={notificationShow && notificationText?.length !== 0}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {notificationText}
        </Alert>
      </Snackbar>
    </Stack1>
  );
};

export default GroupDetail;
