import { Button, Divider, Stack, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import ItemsToFollowGrid from "../../../components/ItemsToFollowGrid";
import UsersRequests from "../../../components/UsersRequests";
import FollowingItemsGrid from "../../../components/FollowingItemsGrid";
import UsersGroups from "../../../components/UsersGroups";
import { useNavigate } from "react-router-dom";

const Container = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  margin: "auto",
  padding: "60px 0",
  width: 1115,
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 36px)",
    padding: "60px 18px",
  },
}));

const CommunityButton = styled(Button)(({ theme }) => ({
  padding: "16px 24px",
  borderColor: "#2575FC",
  fontWeight: 500,
  lineHeight: "120%",
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    whiteSpace: "nowrap",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Ubuntu",
  fontSize: 26,
  fontWeight: 700,
  color: "#060A25",
  marginBottom: "15px",
}));

const NavigationContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    overflow: "auto",
  },
}));

const Navigation = styled(Stack)(({ theme }) => ({
  paddingBottom: "24px",
  [theme.breakpoints.down("sm")]: {
    overflow: "scroll",
    width: "600px",
    paddingBottom: "20px",
  },
}));

const Community = () => {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState(1);

  function changeActiveButton(select: number) {
    setSelectedButton(select);
    navigate(`/community`);
  }

  function renderBody(variable: number) {
    switch (variable) {
      case 1:
        return <FollowingItemsGrid />;
      case 2:
        return <ItemsToFollowGrid />;
      case 3:
        return <UsersGroups />;
      case 4:
        return <UsersRequests />;
      default:
        return <ItemsToFollowGrid />;
    }
  }

  useEffect(() => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    const followParam = currentUrlParams.get("follow");

    if (
      followParam !== null &&
      window.location.search === `?follow=${followParam}`
    ) {
      changeActiveButton(2);
      navigate(`/community?follow=${followParam}`);
    }
  }, [selectedButton, navigate]);

  return (
    <Container spacing={4}>
      <Stack alignItems="flex-start" justifyContent="center" spacing={2}>
        <Stack sx={{ width: "100%" }}>
          <Title>Community</Title>
          <Stack
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
            }}
          >
            <Divider
              sx={{ backgroundColor: "#45B08A", height: "2px", width: "135px" }}
            ></Divider>
            <Divider
              sx={{ backgroundColor: "#E8E8E8", height: "2px", width: "100%" }}
            ></Divider>
          </Stack>
        </Stack>
      </Stack>
      <NavigationContainer>
        <Navigation spacing={2} alignItems="flex-start" direction="row">
          <CommunityButton
            variant={selectedButton === 1 ? "outlined" : "text"}
            sx={{ color: selectedButton === 1 ? "#2575FC" : "#474747" }}
            onClick={() => changeActiveButton(1)}
          >
            Following
          </CommunityButton>
          <CommunityButton
            variant={selectedButton === 2 ? "outlined" : "text"}
            sx={{ color: selectedButton === 2 ? "#2575FC" : "#474747" }}
            onClick={() => changeActiveButton(2)}
          >
            Follow
          </CommunityButton>
          <CommunityButton
            variant={selectedButton === 3 ? "outlined" : "text"}
            sx={{ color: selectedButton === 3 ? "#2575FC" : "#474747" }}
            onClick={() => changeActiveButton(3)}
          >
            My Investment Groups
          </CommunityButton>
          <CommunityButton
            variant={selectedButton === 4 ? "outlined" : "text"}
            sx={{ color: selectedButton === 4 ? "#2575FC" : "#474747" }}
            onClick={() => changeActiveButton(4)}
          >
            Request
          </CommunityButton>
        </Navigation>
      </NavigationContainer>
      {renderBody(selectedButton)}
    </Container>
  );
};

export default Community;
