import { Typography } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <>
      <Typography gutterBottom>
        By making a contribution to a CataCap donor account, you acknowledge and
        accept the following terms and conditions.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        You acknowledge and accept that donor accounts in CataCap are donations
        to Impactree Foundation (IF), a 501(c)(3) public charity. IF is the
        donor advisor of CataCap DAF at ImpactAssets (IA), also a 501(c)(3)
        public charity and a DAF sponsor. Contributions to CataCap accounts do
        not involve a purchase or sale of securities. Certain donor information
        may be transmitted between IA and IF.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        A contribution to fund a donor account within CataCap represents an
        irrevocable tax-deductible contribution to IF. Contributions are
        nonrefundable, and become subject to IF’s control and sole discretion.
        Final determinations on all grant and investment recommendations at
        CataCap DAF are subject to IA’s control and sole discretion. There are a
        number of factors to consider when assessing the tax implications of
        gifts to charity of cash, as well as the transfer of appreciated
        securities or other assets, and IF recommends that you consult with a
        tax specialist before making any donations.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        <span style={{ fontWeight: 700, textDecoration: "underline" }}>
          Expenses:
        </span>
        &nbsp; Costs related to CataCap donor accounts are as follows, and may
        be subject to change on a going forward basis:
      </Typography>
      <Typography
        gutterBottom
        sx={{
          m: "15px",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
        <div style={{ flex: "1" }}>
          The administration fee assessed from each donor contribution is 1.25%,
          but fees for the first four years of administration (in aggregate, 5%
          of the donated amount) are assessed on a forward basis at the time of
          donation. This fee covers all CataCap donor account and CataCap DAF
          expenses on the donated amount during the term.
        </div>
      </Typography>
      <Typography
        gutterBottom
        sx={{
          m: "15px",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
        <div style={{ flex: "1" }}>
          All credit card donations will be charged a transaction fee of ~3.5%
          by Donorbox.
        </div>
      </Typography>
      <Typography
        gutterBottom
        sx={{
          m: "15px",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
        <div style={{ flex: "1" }}>
          There are no fees for donations via ACH bank transfers or checks. Bank
          wire fees will be deducted at cost.
        </div>
      </Typography>
      <Typography
        gutterBottom
        sx={{
          m: "15px",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
        <div style={{ flex: "1" }}>
          If at any time an equity investment is written up in value, equity
          upside is distributed or interest is credited back to a donor account,
          that amount will have an administrative fee assessed as if it were a
          new contribution (but principal and capital recovery will not incur
          further fees). Such events do not create a new tax deduction.
        </div>
      </Typography>
      <Typography
        gutterBottom
        sx={{
          m: "15px",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
        <div style={{ flex: "1" }}>
          After four years from a given contribution’s date, an ongoing annual
          fee of 1.25% will be assessed on that contribution’s remaining balance
          in the donor account and debited from available Impact Liquidity
          Balances (as described below) in the manner in practice and disclosed
          at that date. If an Impact Liquidity Balance is not sufficient to
          cover the annual fee, it will create a negative balance until such
          time as liquidity is available to satisfy the balance (see below
          “Negative Impact Liquidity Balances” discussion).
        </div>
      </Typography>
      <Typography
        gutterBottom
        sx={{
          m: "15px",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: "0 0 auto", marginRight: "15px" }}>&#8226;</div>
        <div style={{ flex: "1" }}>
          Once deducted from a donor account, fees will not be refunded.
        </div>
      </Typography>
      <Typography
        gutterBottom
        sx={{
          fontStyle: "italic",
          fontFamily: "Ubuntu",
          fontWeight: 400,
          mt: "15px",
        }}
      >
        You will receive a tax deduction for the full value of your donation
        (including fees), but the value attributed to your donor account will be
        net of fees.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        <span style={{ fontWeight: 700, textDecoration: "underline" }}>
          Membership on the CataCap Impact Investing and Grantmaking Advisory
          Council:
        </span>
        &nbsp; By accepting these terms and conditions, you acknowledge and
        agree that you will be admitted as a member of the CataCap Impact
        Investing and Grantmaking Advisory Council (the Council) of CataCap and
        IF. The Council will have its advice aggregated by CataCap and that
        advice will act as input to IF to inform the recommendation of
        investments or grants to IF’s CataCap DAF at IA at IA’s sole discretion.
        As a member of the Council you will have certain information about
        investments accessible to you. You agree to hold such information in
        confidence, understanding that this information is not for attribution,
        publication or dissemination, and is not for personal use.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        To maintain your membership on the Council, you are required to
        acknowledge and accept the charter now in effect for the Council as
        described herein. Your term as a member of the Council will begin as of
        the date you accept these Terms and Conditions, and will continue so
        long as CataCap continues to share with you information about investment
        and grant opportunities and recommendations being considered by the
        Council, or you deliver to CataCap written notice of your desire to be
        removed from the Council, whichever is the earlier of such circumstances
        to occur. Upon the end of the term of your membership on the Council,
        you understand that you will no longer have access to information about
        investment or grant opportunities being considered by the Council and
        CataCap, nor will you have an opportunity to participate in decisions
        about such actions thereafter.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        <span style={{ fontWeight: 700, textDecoration: "underline" }}>
          Investments:
        </span>
        &nbsp; The Council may select investments, and you may recommend
        investments for consideration by the Council, for your or another donor
        account from the “Find Investment” section of the CataCap site. Donors
        may submit investment ideas for consideration by the Council for the
        donor’s CataCap donor account through the “Raise Money” page of the
        CataCap site. The Council shall submit its recommendations of
        investments to IF at the end of each calendar month, at scheduled closes
        for the respective investments, or otherwise when enough scale has been
        reached to be practical or meet required investment minimums. Although
        expected to be infrequent and unlikely, allocations that are recommended
        by the Council may not be executed due to a variety of factors (e.g.,
        missed or changed investment closing timing, failure to meet a minimum,
        legal issues, etc.), in which case any funds allocated to such
        investment will be returned to the Impact Liquidity Balances of the
        respective donor accounts from which such investment was recommended.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        Over time, investments made by a CataCap donor account may be credited
        recovered capital, interest or upside liquidity which will be allocated
        into the respective donor account’s Impact Liquidity Balance, but this
        allocation will be non-taxable and will not have any tax implications to
        the corresponding donor because all investments made by a CataCap donor
        account are the sole property of IF or the CataCap DAF. Such recovered
        funds will be available for new investment recommendations or transfer
        out of the respective donor account to another DAF or a grant to a 501c3
        public charity (see “Transfers” below). CataCap generally will post any
        recovered principal, interest earnings from private debt funds or
        promissory notes, or distributions realized on equity investments at the
        end of the month in which such amounts are received.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        In making any investment recommendation, the donor stipulates that they
        are not a&nbsp;
        <a
          href="https://www.irs.gov/charities-non-profits/irc-section-4946-definition-of-disqualified-person"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: 700, textDecoration: "underline" }}
        >
          disqualified person
        </a>
        &nbsp;, an employee, compensated advisor, shareholder or board member of
        the investment entity. Furthermore, in making any investment
        recommendation the donor stipulates that the holdings of a given donor’s
        CataCap donor account combined with any personal holdings in a business
        enterprise may not exceed 20% of the voting stock of an incorporated
        business, 20% of the profit interest of a partnership or joint venture
        or the beneficial interest of a trust or similar entity, or any interest
        in a sole proprietorship. In the case of Fund or Special Purpose Vehicle
        (SPV), those limits apply to the portfolio companies or target company,
        and not to the Fund or SPV itself.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        <span style={{ fontWeight: 700, textDecoration: "underline" }}>
          Grants:
        </span>
        &nbsp; Grant options to 501(c)(3) public charities may be added to
        CataCap if proposed by Council members and accepted by CataCap. Grant
        options will be clearly noted as grants rather than investments, and if
        recommended to by a Council member and such a recommendation accepted,
        will be granted out of the CataCap DAF and no longer be reflected in a
        given donor account balance. Grants are recommended for disbursal to IA
        at the end of each calendar quarter, unless otherwise scheduled by
        CataCap. Although infrequent and unlikely, a grant may be rejected by IA
        due to an issue with a charity, in which case funds will be returned to
        a donor account’s available balance.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        <span style={{ fontWeight: 700, textDecoration: "underline" }}>
          Impact Liquidity Balances:
        </span>
        &nbsp; Donor account balances that await allocation are held in cash,
        low interest, relatively low risk community development insured deposits
        and uninsured loan funds that IF selects from IA’s investment platform
        or recommends to IA. No earnings will accrue to the donor account due to
        the modest rates of interest, short term nature of Impact Liquidity
        Balances, and the associated complexity and relative high cost of
        tracking and allocating small amounts into an individual donor account.
        The donor account balances held at CataCap are intended to create
        positive impact by providing the temporary “capital in waiting”
        alignment with the mission of CataCap, and any earnings realized on
        those balances will support the nonprofit CataCap’s program. Donors
        wishing to have the balances in their CataCap donor accounts invested
        for intermediate terms in loan funds may have various options on the
        CataCap investment menus to do so. Any interest earned on such
        investments will be paid to the respective donor account balance as
        outlined above in the “Investments” section.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        <span style={{ fontWeight: 700, textDecoration: "underline" }}>
          Negative Impact Liquidity Balances:
        </span>
        &nbsp; CataCap may liquidate a portion of investments in any donor
        account that maintains a negative Impact Liquidity Balance for more than
        90 days after notification to the donor’s email address of record,
        unless that Impact Liquidity Balance is brought to a positive balance
        through a new donation or from an investment liquidity inflow. In the
        event that CataCap liquidates an investment to satisfy a negative Impact
        Liquidity Balance, CataCap will aim to limit such liquidation to the
        amount necessary to recover approximately the amount of such negative
        balance.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        <span style={{ fontWeight: 700, textDecoration: "underline" }}>
          Transfers:
        </span>
        &nbsp; Donors may request a transfer of Impact Liquidity Balances from
        CataCap donor accounts in part or parcel in amounts of $1,000 or more
        once per calendar year. Such distributions may be made as a grant to a
        DAF account at IA, a DAF account at another DAF sponsor, or any single
        501(c)(3) public charity that is approved. Such recommendations should
        be initiated via email to&nbsp;
        <a
          href="mailto:info@catacap.org"
          style={{ fontWeight: 700, textDecoration: "underline" }}
        >
          info@catacap.org
        </a>
        &nbsp;or other method displayed on the CataCap site 10 business days
        before a given calendar quarter end (or such recommendations may be
        rolled forward into the next quarter). As noted above, any fees
        previously deducted from a donor account will not be refunded.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        <span style={{ fontWeight: 700, textDecoration: "underline" }}>
          Closure Due to Inactivity:
        </span>
        &nbsp; If a donor account has no investment recommended or outstanding,
        or grant recommended, within a rolling 12 month period, IF will send
        notification of such inactivity to the email address of record for the
        respective donor with an indication of any requirements of action or
        response. If the prescribed action is not taken or CataCap does not
        receive a response to such notice within 90 days of the date the notice
        is given, the donor account may be dissolved with any assets becoming
        part of the CataCap DAF’s general fund.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        <span style={{ fontWeight: 700, textDecoration: "underline" }}>
          Successor or Beneficiary:
        </span>
        &nbsp; Any individual or entity may be named by the donor in a will or
        other testamentary document to succeed their donor account relationship
        with CataCap upon the presentation of such documentation to IF.
        Alternatively, a donor’s testamentary documentation may provide that a
        501(c)(3) public charity or third-party DAF shall be a named beneficiary
        of such donor’s CataCap account and CataCap shall deem such provision as
        a recommendation that the account be liquidated and the proceeds granted
        to the named beneficiary. The grant of proceeds to the named beneficiary
        will be undertaken upon the realization of the Impact Liquidity Balance
        reaching 80%+ of the initial balance of the respective donor account
        (provided that such Impact Liquidity Balance exceeds $1,000) and the
        donor account will subsequently be dissolved. Any remainder illiquid
        assets in such account will then become part of the CataCap’s general
        fund).
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        Notice of an appointment of a successor of a donor’s relationship or a
        testamentary plan to transfer the balance of a donor account to another
        501(c)(3) public charity should be sent to&nbsp;
        <a
          href="mailto:info@catacap.org"
          style={{ fontWeight: 700, textDecoration: "underline" }}
        >
          info@catacap.org
        </a>
        &nbsp; If CataCap determines that a donor has been deceased more than 90
        days, and no testamentary document has been delivered to CataCap, the
        donor account may be dissolved for the benefit of the CataCap’s general
        fund.
      </Typography>
      <Typography
        gutterBottom
        sx={{
          mt: "15px",
        }}
      >
        <span style={{ fontWeight: 700, textDecoration: "underline" }}>
          Relationship of CataCap and IF to IA:
        </span>
        &nbsp; CataCap and IF are unrelated to IA other than in their use of the
        IA Donor Advised Fund (DAF) for holding donated assets for the purpose
        of impact investing and grantmaking, and such use should not be taken to
        indicate that IA is in any way involved with CataCap and IF other than
        as a DAF sponsoring organization.
      </Typography>
      <Typography
        sx={{
          fontStyle: "italic",
          fontFamily: "Ubuntu",
          fontWeight: 400,
          mt: "15px",
        }}
      >
        Note: CataCap DAF is generally governed by the IA DAF Program Circular
        (available on the IA website). CataCap donor accounts adhere to the
        guidelines of the CataCap Terms & Conditions herein, and are not IA
        DAFs.
      </Typography>
    </>
  );
};

export default TermsAndConditions;
