import { Box, Grid, Stack, Typography } from "@mui/material";
import ContentTitle from "../../../components/ContentTitle";
import Avatar from "../../../resources/avatar";

const HowItWorks = () => {
    return (
        <Stack sx={{justifyContent: "center", margin: "auto", paddingBottom: "30px", paddingTop: "70px"}} width={800} spacing={4}>
            <ContentTitle 
                title="Why Use a DAF To Invest in Impact?" 
                description="If you are investing first motivated by impact, in things you are passionate about to change the world for the better and help people and/or planet, your philanthropic pocket can be very aligned with the value proposition to you (including financially). You can get a full tax deduction this year through utilizing a DAF. And in this way, you don’t need to be an Accredited Investor, much less Qualified Investor (as required for some funds). And it’s very streamlined with no K1s to you, no filing extensions triggered by late K1s and no further tax implications of any kind over the years. And, you can pool together with others to make investment minimums that otherwise would completely exclude many investors."/>
            <ContentTitle 
                title="Why Use This Impact Investing APP?" 
                description="Even if a DAF sponsor allows such impact investing (most do not) at $25,000 or more, the APP allows you to deploy into funds and deals at a “micro” level with amounts as little as $250 each and at a much more affordable level. Essentially, the APP allows you to diversify into a customized portfolio with a DAF that has a $250 minimum to establish (vs $25,000) and costs roughly the same expense ratio as a $1M DAF doing $100K deals and fund investments."/>
            <ContentTitle 
                title="How it works" 
                description="Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."/>
            <Grid container spacing={2} >
                <Grid item xs={8}>
                    <Stack>
                        <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 24, fontWeight: 700, color: "#060A25", lineHeigh: '38.4px' }}>1. Step</Typography>
                        <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 400, color: "#474747", lineHeigh: '20.68px', mt: '15px' }}>Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis, concte adipiscing elit. Nunc vulputate libero et.  dolor sit amet, cotetur adipiscing elit. Nunc vulputate </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={4} sx={{display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%"}}>
                    <Avatar />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4} sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%"}}>
                    <Avatar />
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 24, fontWeight: 700, color: "#060A25", lineHeigh: '38.4px' }}>2. Step</Typography>
                    <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 400, color: "#474747", lineHeigh: '20.68px', mt: '15px' }}>Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis, concte adipiscing elit. Nunc vulputate libero et.  dolor sit amet, cotetur adipiscing elit. Nunc vulputate </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 24, fontWeight: 700, color: "#060A25", lineHeigh: '38.4px' }}>3. Step</Typography>
                    <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 400, color: "#474747", lineHeigh: '20.68px', mt: '15px' }}>Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis, concte adipiscing elit. Nunc vulputate libero et.  dolor sit amet, cotetur adipiscing elit. Nunc vulputate </Typography>
                </Grid>
                <Grid item xs={4} sx={{display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%"}}>
                    <Avatar />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4} sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%"}}>
                    <Avatar />
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 24, fontWeight: 700, color: "#060A25", lineHeigh: '38.4px' }}>4. Step</Typography>
                    <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 18, fontWeight: 400, color: "#474747", lineHeigh: '20.68px', mt: '15px' }}>Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis, concte adipiscing elit. Nunc vulputate libero et.  dolor sit amet, cotetur adipiscing elit. Nunc vulputate </Typography>
                </Grid>
            </Grid>
        </Stack>
    );
};

export default HowItWorks;