const DonateIcon = () => {
    return (
        <svg width="74" height="64" viewBox="0 0 74 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="74" height="64" rx="8" fill="#45B08A" fill-opacity="0.1"/>
            <path d="M41.8 13H32.2C23.149 13 18.6236 13 15.8118 15.7825C13.7873 17.7859 13.2204 20.6685 13.0617 25.4688H60.9383C60.7796 20.6685 60.2127 17.7859 58.1882 15.7825C55.3764 13 50.851 13 41.8 13Z" fill="#45B08A"/>
            <path d="M32.2 51H41.8C50.851 51 55.3764 51 58.1882 48.2175C61 45.435 61 40.9567 61 32C61 30.9504 61 29.9623 60.9955 29.0312H13.0045C13 29.9623 13 30.9504 13 32C13 40.9567 13 45.435 15.8118 48.2175C18.6236 51 23.149 51 32.2 51Z" fill="#45B08A"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8 41.5C20.8 40.5162 21.6059 39.7188 22.6 39.7188H32.2C33.1941 39.7188 34 40.5162 34 41.5C34 42.4838 33.1941 43.2812 32.2 43.2812H22.6C21.6059 43.2812 20.8 42.4838 20.8 41.5Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M36.4 41.5C36.4 40.5162 37.2059 39.7188 38.2 39.7188H41.8C42.7941 39.7188 43.6 40.5162 43.6 41.5C43.6 42.4838 42.7941 43.2812 41.8 43.2812H38.2C37.2059 43.2812 36.4 42.4838 36.4 41.5Z" fill="white"/>
        </svg>
    )
}

export default DonateIcon;