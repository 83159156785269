import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../../redux/actions";
import { useEffect } from "react";
import { ApiException } from "../client";

const useHandleApiError = (error: ApiException | null) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        handleApiError(error);
    }, [error])

    const handleUnauthorizedError = () => {
        dispatch(removeToken());
        navigate('/login');
    }

    const handleApiError = (error: ApiException | null) => {
        if (error && error.status === 401) {
            handleUnauthorizedError();
        } else if(error)  {
            console.log(error?.response);
        }
    }

    return { handleApiError };
}

export default useHandleApiError;