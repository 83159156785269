import { useEffect, useState } from "react";
import {
  Campaign,
  CampaignClient,
  CampaignDto,
  InvestmentStage,
} from "../../utilities/backend/client";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { getUrlBlobContainerImage } from "../../resources/imageConstants";
import { SVG } from "../../icons";

const CopyButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: 2,
}));

const CopyLinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  margin: "0 auto 31px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "0 auto 48px",
  },
}));

interface CampaignsAdminProps {}

const CampaignsAdmin = (props: CampaignsAdminProps) => {
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>("");
  const campaignClient = new CampaignClient();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<Campaign[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const getCampaigns = () => {
    campaignClient
      .campaign_GetAdminCampaigns()
      .then(async (response) => {
        setCampaigns(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCampaigns();
  }, []);
  const editClick = (campaign: any) => {
    navigate("/a-investment", { state: { campaign } });
  };
  const deleteClick = (id: number) => {
    setLoading(true);
    campaignClient
      .campaign_DeleteCampaign(id)
      .then(async (response) => {
        setNotificationText("The Investment was successfully deleted!");
        setNotificationShow(true);
        getCampaigns();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  const updateCampaign = (row: CampaignDto, status: boolean) => {
    try {
      campaignClient
        .campaign_UpdateCampaignStatus(row.id!, status)
        .then(async () => {
          setNotificationText(
            "Status for the investment has been successfully updated!"
          );
          setNotificationShow(true);
          getCampaigns();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const copyHandler = async (identifier: string) => {
    const campainToUrl = `https:/app.catacap.org/invest/${identifier}`;

    try {
      await navigator.clipboard.writeText(campainToUrl);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: CampaignDto
  ) => {
    updateCampaign(row, event.target.checked);
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "15%" }} align="center">
                  Image
                </TableCell>
                <TableCell style={{ width: "35%" }} align="center">
                  Name
                </TableCell>
                <TableCell style={{ width: "10%" }} align="center">
                  Stage
                </TableCell>
                <TableCell style={{ width: "10%" }} align="center">
                  Copy
                </TableCell>
                <TableCell style={{ width: "10" }} align="center">
                  Edit
                </TableCell>
                <TableCell style={{ width: "10%" }} align="center">
                  Is Active
                </TableCell>
                <TableCell style={{ width: "10%" }} align="center">
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell style={{ width: "15%" }} align="center">
                    <Box
                      sx={{
                        maxWidth: "150px",
                        height: "auto",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={getUrlBlobContainerImage(row.imageFileName!)}
                        alt={row.name!}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "35%" }} align="center">
                    {row.name}
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    {InvestmentStage[row.stage]}
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    <CopyLinkContainer>
                      <CopyButton
                        onClick={() =>
                          copyHandler(row.property ?? row.id!.toString())
                        }
                      >
                        <SVG.Copy />
                      </CopyButton>
                    </CopyLinkContainer>
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    <IconButton onClick={() => editClick(row)}>
                      <ModeEditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    <Checkbox
                      checked={row.isActive!}
                      onChange={(e) => handleChange(e, row)}
                      disabled={loading}
                    />
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    <IconButton onClick={() => deleteClick(row.id!)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Snackbar
        open={notificationShow}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {notificationText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CampaignsAdmin;
