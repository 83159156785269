import { Copy } from './Copy'
import { Email } from './Email'
import { Facebook } from './Facebook'
import { Linkedin } from './Linkedin'
import { Messenger } from './Messenger'
import { Twitter } from './Twitter'

export const SVG = {
  Copy,
  Email,
  Facebook,
  Linkedin,
  Messenger,
  Twitter
}