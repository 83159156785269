import { Avatar, Grid, Stack, Typography } from "@mui/material";
import { GroupDto } from "../utilities/backend/client";
import { Link } from "react-router-dom";
import {
  getUrlBlobContainerImage,
  defaultImage,
} from "../resources/imageConstants";

interface GroupDataProps {
  group: GroupDto;
}

const GroupData = (props: GroupDataProps) => {
  const linkGroup = props.group.identifier
    ? `/group/${props.group.identifier}`
    : `/group/${props.group.id}`;

  return (
    <Grid container width={"100%"}>
      <Grid item xs={12} height={"100%"}>
        <Link to={linkGroup} style={{ textDecoration: "none" }}>
          <Stack
            alignItems="center"
            justifyContent="flex-start"
            height={"100%"}
            spacing={1}
            direction={"row"}
          >
            <Avatar
              variant="rounded"
              src={
                props.group.pictureFileName
                  ? getUrlBlobContainerImage(props.group.pictureFileName)
                  : defaultImage
              }
              sx={{ width: 60, height: 60 }}
            ></Avatar>
            <Typography
              sx={{
                width: "100%",
                fontFamily: "Ubuntu",
                fontSize: 18,
                fontWeight: 700,
                color: "#191919",
              }}
            >
              {props.group.name}
            </Typography>
          </Stack>
        </Link>
      </Grid>
    </Grid>
  );
};

export default GroupData;
