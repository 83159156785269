export const Messenger = () => (
<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="messenger 1">
<g id="Layer 2">
<g id="messenger">
<g id="icon">
<path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M16.666 24.52C22.1878 24.52 26.666 20.3745 26.666 15.26C26.666 10.1473 22.1878 6 16.666 6C11.1442 6 6.66602 10.1455 6.66602 15.26C6.66602 18.1818 8.12056 20.7727 10.3915 22.4691V26L13.7969 24.1327C14.7315 24.3904 15.6966 24.5206 16.666 24.52ZM17.6571 18.468L23.1244 12.668L18.2189 15.3825L15.6098 12.668L10.1426 18.4698L15.1117 15.7534L17.6571 18.468Z" fill="#45B08A"/>
</g>
</g>
</g>
</g>
</svg>
)